import { gql } from "@apollo/client";

export const GET_SURVEYS = gql`
  {
    surveys(orderBy: "createdOn:desc", pageSize: 100) {
      id
      name
      description
      dateLocked
      isLocked
      isLockingSoon
      millsUntilLock
      projectName
    }
  }
`;

export interface GetSurveysDTO {
  surveys: [
    {
      id: string;
      name: string;
      description: string;
      dateLocked: Date;
      isLocked: boolean;
      isLockingSoon: boolean;
      millsUntilLock: number;
      projectName: string;
    }
  ];
}
