import { gql } from "@apollo/client";

export const GET_CUSTOMER_PRIORITIZATION_DATA = gql`
query getSurveyCustomerPrioritization($surveyId:String!){
	overall:surveyRanking(surveyId:$surveyId) {
    customerDecisionCriteriaRanking{
      decisionCriteria{
        id
        name
      }
      blendedRankings{
        featureName
        rank
      }
    }
  }
  survey(id:$surveyId) {
    customers:groups(filterBy:"name:Customer"){
      surveyRankings {
        respondentRankings {
          respondent{
            id
            firstName
            lastName
          }
          featureRankings{
            featureName
            rank
          }
        }
      }
    }
  }
}
`;

export interface GetCustomerPrioritizationDataDTO {
  overall: {
    customerDecisionCriteriaRanking: {
      decisionCriteria: {
        id: string;
        name: string;
      },
      blendedRankings: {
        featureName: string;
        rank: number;
      }[]
    }
  }
  survey: {
    customers: {
      surveyRankings: {
        respondentRankings: {
          respondent: {
            id: string;
            firstName: string;
            lastName: string;
          }
          featureRankings: [{
            featureName: string;
            rank: number;
          }]
        }[]
      }
    }[]
  }
}
