import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
    mutation updateUser($user: UpdateUser!, $userId: String!) {
        updateUser(input: $user, id: $userId) {
            id
        }
    }
`

export interface UpdateUserDTO {
    updateUser: {
        id: string;
    }
}