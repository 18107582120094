import { gql } from "@apollo/client";

export const GET_SURVEY_FEATURES = gql`
    query surveyFeatures($surveyId:String!){
        survey(id:$surveyId){
            account {
                maxFeatures
            }
            features(orderBy:"createdOn"){
                id
                name
                description
                details
            }
        }
    }`;

export interface GetSurveyFeaturesDTO {
    survey: {
        account: {
            maxFeatures: number;
        };
        features: {
            id: string;
            name: string;
            description?: string;
            details?: string;
        }[];
    }
}