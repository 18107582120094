import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import theme from "src/Theme";
import { SurveyDTO } from "src/models";
import BasicInfo from "../core/BasicInfo";
import { FormValidationTooltip } from '../core';

export interface IEditSurveyModalProps {
  closeModal: () => void;
  saveSurvey: (editedSurvey: SurveyDTO) => Promise<void>;
  existingSurvey: SurveyDTO;
  isOpen: boolean;
}

function EditSurveyModal(props: IEditSurveyModalProps) {
  const { closeModal, existingSurvey, isOpen, saveSurvey } = props;
  const [localSurvey, setLocalSurvey] = useState(existingSurvey);
  const [isValid, setIsValid] = useState(false);

  const handleEditSurvey = () => {
    saveSurvey(localSurvey);
    closeModal();
  };

  const handleSurveyChange = (updatedSurvey: SurveyDTO) => {
    setIsValid(!!localSurvey.name);
    setLocalSurvey(updatedSurvey);
  };

  useEffect(() => {
    setLocalSurvey(existingSurvey);
  }, [isOpen, existingSurvey]);

  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <DialogTitle style={{ paddingBottom: "0", fontWeight: "bold" }}>
        Edit Poll
      </DialogTitle>
      <DialogContent>
        <BasicInfo survey={localSurvey} updateSurvey={handleSurveyChange} />
      </DialogContent>
      <DialogActions
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(1) }}
      >
        <Button color="primary" variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <FormValidationTooltip isVisible={!isValid} >
          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: theme.spacing(2) }}
            disabled={!isValid}
            onClick={handleEditSurvey}
          >
            Update
          </Button>
        </FormValidationTooltip>
      </DialogActions>
    </Dialog>
  );
}

export default EditSurveyModal;
