import { Card, CardContent, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { SurveyDTO } from "src/models";
import { SurveyRespondentDTO } from "src/models/SurveyRespondentDTO";
import BasicInfo from "../core/BasicInfo";
import ESStepper, { IESStepperStep } from "../core/ESStepper";
import {
  AddEditDecisionCriteriaContainer,
  AddEditFeaturesContainer,
  RespondentsCardContainer,
} from "src/containers";

export interface IAddNewSurveyStepperProps {
  survey: SurveyDTO;
  steps: IESStepperStep[];
  handleStepperComplete: () => void;
  updateSurvey: (updatedSurvey: SurveyDTO) => void;
  nextStepActions?: (stepNumber: number) => Promise<boolean>;
}

function AddNewSurveyStepper(props: IAddNewSurveyStepperProps) {
  const [activeStep, setActiveStep] = useState(0);
  const {
    handleStepperComplete,
    steps,
    survey,
    updateSurvey,
    nextStepActions,
  } = props;

  const moveToNextStep = async () => {
    const isOnLastStep = activeStep === steps.length - 1;
    if (isOnLastStep) {
      handleStepperComplete();
    } else {
      const result = await nextStepActions(activeStep);
      if (nextStepActions && !result) {
        return;
      }
      setActiveStep(activeStep + 1);
    }
  };

  const moveToPreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const updateSurveyRespondents = (
    updatedSurveyRespondents: Partial<SurveyRespondentDTO>[]
  ) => {
    const updatedSurvey: SurveyDTO = {
      ...survey,
      respondents: updatedSurveyRespondents,
    };
    updateSurvey(updatedSurvey);
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <BasicInfo survey={survey} updateSurvey={updateSurvey} />;
      case 1:
        return (
          <AddEditFeaturesContainer
            surveyId={survey.id}
          />
        );
      case 2:
        return (
          <AddEditDecisionCriteriaContainer
            surveyId={survey.id}
          />
        );
      case 3:
        return (
          <Card>
              <CardContent>
                <RespondentsCardContainer
                  surveyId={survey.id}
                  surveyRespondents={survey.respondents}
                  updateSurveyRespondents={updateSurveyRespondents}
                  titlePrefix="Add"
                />
              </CardContent>
            </Card>
        );
      default:
        return <Typography>This step has not been defined</Typography>;
    }
  };

  return (
    <ESStepper
      activeStep={activeStep}
      nextButtonAction={moveToNextStep}
      previousButtonAction={moveToPreviousStep}
      steps={steps}
    >
      {getStepContent()}
    </ESStepper>
  );
}

export default AddNewSurveyStepper;
