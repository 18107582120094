import React, { ComponentProps } from 'react';
import { Stepper, Step, StepLabel, Grid, Button, Box, Typography, StepIconProps } from '@material-ui/core';
import theme from 'src/Theme';
import { FormValidationTooltip } from '.';

export interface IESStepperStep {
    name: string;
    isValid: boolean;
}

export interface IESStepperProps extends ComponentProps<any> {
    steps: IESStepperStep[];
    activeStep: number;
    nextButtonAction: () => void;
    previousButtonAction: () => void;
}

function ESStepper(props: IESStepperProps) {
    const {
        activeStep,
        steps,
        nextButtonAction,
        previousButtonAction,
        children
    } = props;

    const customStepComponent = (stepIconProps: StepIconProps) => {
        return (
            <Grid
            container
            direction='column'
            justify='center'
            style={{
                backgroundColor: stepIconProps.active || stepIconProps.completed ? theme.palette.primary.main : '#9b9b9b',
                height: '32px',
                width: '32px',
                textAlign: 'center',
                color: theme.palette.primary.contrastText,
                borderRadius: '50%',
                position: 'relative',
                top: '-3px'
            }}>
                <Typography>{stepIconProps.icon}</Typography>
            </Grid>
        )
    }

    const stepper = () => {
        return (
            <Stepper
                alternativeLabel
                activeStep={activeStep}
                id="ESStepper"
            >
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepLabel StepIconComponent={customStepComponent} >{step.name}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        )
    }

    return (
        <Grid container direction="column">
            {stepper()}
            <div style={{marginTop: theme.spacing(5)}}>
                <Grid direction="row" container justify="center">
                    {children}
                </Grid>
                <Box m={2}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        style={{ marginTop: theme.spacing(8) }}
                    >
                            <Button
                                disabled={activeStep === 0}
                                variant="outlined"
                                color="primary"
                                onClick={previousButtonAction}
                                id="esStepperPreviousButton"
                            >
                                Back
                            </Button>
                            <div style={{width: theme.spacing(2)}}></div>
                            <FormValidationTooltip isVisible={!steps[activeStep].isValid} >
                                <Button
                                    disabled={activeStep === steps.length || !steps[activeStep].isValid}
                                    variant="contained"
                                    color="primary"
                                    onClick={nextButtonAction}
                                    id="esStepperNextButton"
                                >
                                    {activeStep === steps.length - 1 ? "Done" : "Next"}
                                </Button>
                            </FormValidationTooltip>

                    </Grid>
                </Box>
            </div>
        </Grid>
    )
}

export default ESStepper;