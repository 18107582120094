import { gql } from "@apollo/client";

export const GET_DEPARTMENT_PRIORITIZATION_DATA = gql`
query getSurveyDepartmentPrioritization($surveyId:String!){
  survey(id:$surveyId) {
    stakeholders:groups(filterBy:"name:Stakeholder"){
      surveyRankings {
        blendedRankings{
          rank
          featureName
        }
      }
    }
    departments:groups(filterBy:"isDepartment:true"){
      name
      surveyRankings {
        blendedRankings{
          rank
          featureName
        }
      }
    }
  }
}
`;

export interface GetDepartmentPrioritizationDataDTO {
  survey: {
    stakeholders: [{
      surveyRankings: {
        blendedRankings: [{
          rank: number;
          featureName: string;
        }]
      }
    }],
    departments: [{
      name: string;
      surveyRankings: {
        blendedRankings: [{
          rank: number;
          featureName: string;
        }]
      }
    }]
  }
}
