import imageExtensions from 'image-extensions';
import isUrl from 'is-url';
import { Editor, Transforms, Node as SlateNode } from 'slate';
import { ReactEditor } from 'slate-react';

export const insertImage = (editor: Editor & ReactEditor, url: string | ArrayBuffer) => {
    const text = { text: '' };
    const image = { type: 'image', url, children: [text] };
    Transforms.insertNodes(editor, image);
    Editor.insertNode(editor, {
        type: 'paragraph',
        children: [
            {text: '', marks: []}
        ]
    });
}

const isImageUrl = (url: string) => {
    if (!url) {
        return false;
    }

    if (!isUrl(url)) {
        return false;
    }
    const ext = new URL(url).pathname.split('.').pop();
    return imageExtensions.includes(ext);
}

export const withImages = (editor: Editor & ReactEditor) => {
    const { insertData, isVoid } = editor;

    editor.isVoid = element => {
        return element.type === 'image' ? true : isVoid(element);
    }

    editor.insertData = async data => {
        const text = data.getData('text/plain');
        const { files } = data;

        if (files && files.length > 0) {
            for (const file of files) {
                const reader = new FileReader();
                const [mime] = file.type.split('/');

                if (mime === 'image') {
                    reader.addEventListener('load', () => {
                        const url = reader.result;
                        insertImage(editor, url);
                    })

                    reader.readAsDataURL(file);
                }
            }
        } else if (isImageUrl(text)) {
            insertImage(editor, text);
        } else {
            insertData(data);
        }
    }

    return editor
}

export const getWYSIWYGPlainText = (input: string): string => {
    try {
        const data: SlateNode[] = JSON.parse(input);
        const plainText = data.map(n => SlateNode.string(n)).join('\n');
        return plainText
    } catch (error) {
        return input
    }
}