import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import _ from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { SurveyFeatureDTO } from 'src/models';
import theme from 'src/Theme';
import { DeleteButtonWithConfirmation, FormValidationTooltip } from '..';
import { WYSIWYGEditor } from '../core';
export interface IAddEditFeatureModalProps {
    createFeature: (newFeature: SurveyFeatureDTO) => Promise<void>;
    closeModal: () => void;
    updateFeature: (editedFeature: SurveyFeatureDTO) => Promise<void>;
    existingFeature?: SurveyFeatureDTO;
    isOpen: boolean;
    deleteFeature: (featureId: string) => Promise<void>;
    isEditDisabled?: boolean;
}

export function AddEditFeatureModal(props: IAddEditFeatureModalProps) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [details, setDetails] = useState('');
    const {
        createFeature,
        closeModal,
        updateFeature,
        existingFeature,
        isOpen,
        deleteFeature,
        isEditDisabled
    } = props;

    const handleNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setName(event.target.value)
    }

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDescription(event.target.value)
    }

    const handleDetailsChange = (value: string) => {
        setDetails(value)
    }

    const isFormValid = () => {
        return !!name;
    }

    const handleAddFeature = () => {
        const newFeature: SurveyFeatureDTO = {
            name: name,
            description: description,
            details: details,
        };
        createFeature(newFeature);
        closeModal();
    }

    const handleEditFeature = () => {
        const editedFeature: SurveyFeatureDTO = {
            ...existingFeature,
            name: name,
            description: description,
            details: details,
        }
        updateFeature(_.cloneDeep(editedFeature));
        closeModal();
    }

    const handleDeleteFeature = () => {
        deleteFeature(existingFeature.id);
        closeModal();
    }

    useEffect(() => {
        if (existingFeature) {
            setName(existingFeature.name);
            setDescription(existingFeature.description);
            setDetails(existingFeature.details);
        } else {
            setName('');
            setDescription('');
            setDetails('');
        }
    }, [isOpen, existingFeature]);

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth='lg' fullWidth>
            <DialogTitle style={{ paddingBottom: '0', fontWeight: 'bold' }}>
                {existingFeature ? 'Edit' : 'Add'} Feature
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' style={{ minWidth: '400px' }}>
                    <TextField
                        InputProps={{
                            readOnly: isEditDisabled,
                        }}
                        label='Name'
                        value={name}
                        style={{ marginBottom: theme.spacing(2) }}
                        onChange={handleNameChange}
                        required
                    />
                    <TextField
                        InputProps={{
                            readOnly: isEditDisabled,
                        }}
                        label='Description'
                        value={description}
                        style={{ marginBottom: theme.spacing(3) }}
                        onChange={handleDescriptionChange}
                    />
                    <WYSIWYGEditor
                        value={details}
                        onChange={handleDetailsChange}
                    />
                </Grid>
            </DialogContent>
            <DialogActions style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(1) }}>
                {
                    existingFeature && !isEditDisabled &&
                    <DeleteButtonWithConfirmation onDelete={handleDeleteFeature} itemName="feature" />
                }
                {
                    isEditDisabled ?
                        <Button color="primary" variant="outlined" onClick={closeModal}>
                            Close
                        </Button>
                        :
                        <>
                            <Button color="primary" variant="outlined" onClick={closeModal}>
                                Cancel
                            </Button>
                            <FormValidationTooltip isVisible={!isFormValid()} >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{ marginLeft: theme.spacing(2) }}
                                    disabled={!isFormValid()}
                                    onClick={existingFeature ? handleEditFeature : handleAddFeature}
                                >
                                    {existingFeature ? 'Update' : 'Add'}
                                </Button>
                            </FormValidationTooltip>
                        </>
                }
            </DialogActions>
        </Dialog>
    )
}