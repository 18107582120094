import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent } from 'react';
import { AccountPage } from 'src/Components';
import LoadingIndicator from 'src/Components/core/LoadingIndicator';
import { UserDTO } from 'src/models';
import { DeleteAccountDTO, DELETE_ACCOUNT, GetUserDTO, GET_USER, SendChangePasswordEmailDTO, SEND_CHANGE_PASSWORD_EMAIL, UpdateUserDTO, UPDATE_USER } from 'src/operations';

export const AccountPageContainer: FunctionComponent = () => {
    const { user, logout } = useAuth0();
    const { enqueueSnackbar } = useSnackbar();
    const {
        data: userData,
        loading: isUserDataLoading,
        error: userDataQueryError,
        refetch: reloadUserData,
    } = useQuery<GetUserDTO>(GET_USER, {
        variables: {
            userId: user.sub
        }
    });
    const [updateUser] = useMutation<UpdateUserDTO>(UPDATE_USER);
    const [deleteAccount] = useMutation<DeleteAccountDTO>(DELETE_ACCOUNT);
    const [sendChangePasswordEmail] = useMutation<SendChangePasswordEmailDTO>(SEND_CHANGE_PASSWORD_EMAIL);

    const reloadUser = async () => {
        await reloadUserData({
            userId: user.sub
        })
    }

    const handleUpdateUser = async (editedUser: UserDTO) => {
        await updateUser({
            variables: {
                user: editedUser,
                userId: user.sub
            }
        });
        await reloadUser();
    }

    const handleChangePassword = async () => {
        const isSuccessful = await sendChangePasswordEmail({
            variables: {
                userId: user.sub
            }
        });
        if (isSuccessful) {
            enqueueSnackbar(`Password Change email sent to ${user.email}`, { variant: 'success' })
        } else {
            enqueueSnackbar(`An error occurred while trying to send password change email`, { variant: 'error' })
        }
    }

    const handleCancelAccount = async () => {
        try {
            await deleteAccount({
                variables: {
                    accountId: userData?.user?.userMetaData?.accountId
                }
            });
            enqueueSnackbar(`Account successfully canceled. You will be logged out in five seconds. Goodbye!`, { variant: 'success' });
            setTimeout(() => {
                logout();
            }, 5000);
        } catch (error) {
            enqueueSnackbar(`An error occurred while attempting to delete your account`, { variant: 'error' });
        }
    }

    if (isUserDataLoading) {
    return <LoadingIndicator />;
    }

    if (userDataQueryError) {
    return <p>{JSON.stringify(userDataQueryError)}</p>;
    }

    return <AccountPage
        user={userData.user}
        onUpdateUser={handleUpdateUser}
        onChangePassword={handleChangePassword}
        onCancelAccount={handleCancelAccount}
    />;
}