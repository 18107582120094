import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { DecisionCriteriaRankingDTO } from 'src/operations';
import theme from 'src/Theme';

interface IStakeholderDecisionCriteriaPrioritizationProps {
    chartData: DecisionCriteriaRankingDTO;
}

export const StakeholderDecisionCriteriaPrioritization: FunctionComponent<IStakeholderDecisionCriteriaPrioritizationProps> = props => {
    const {
        chartData,
    } = props;
    const [featureNames, setFeatureNames] = useState([] as string[]);
    const [chartLines, setChartLines] = useState([] as Highcharts.SeriesOptionsType[]);

    useEffect(() => {
        const stakeholderRankings = chartData;
        if (!stakeholderRankings) {
            return;
        }

        const featureList = stakeholderRankings.blendedRankings.map(x => x.featureName)
        setFeatureNames(featureList)

        const defaultLineProperties: Highcharts.SeriesOptionsType = {
            type: 'line',
        }
        const lines: Highcharts.SeriesOptionsType[] = []

        const blendedRankingLine = {
            ...defaultLineProperties,
            name: 'Average',
            data: stakeholderRankings.blendedRankings.map(x => x.rank),
        }
        lines.push(blendedRankingLine);

        stakeholderRankings?.respondentRankings.forEach(rr => {
            const decisionCriteriaLine = {
                ...defaultLineProperties,
                name: `${rr.respondent.firstName} ${rr.respondent.lastName[0]}.`,
                data: rr.featureRankings.map(x => x.rank),
            }
            lines.push(decisionCriteriaLine);
        })

        setChartLines(lines)
    }, [chartData]);

    const options: Highcharts.Options = {
        title: {
            text: `${chartData.decisionCriteria.name} - Stakeholders`,
            align: 'left'
        },
        legend: {
            align: 'left',
            verticalAlign: 'top',
            margin: theme.spacing(5),
        },
        yAxis: {
            title: {
                text: null,
            }
        },
        xAxis: {
            categories: featureNames,
        },
        credits: {
            enabled: false,
        },
        series: chartLines,
        tooltip: {
            formatter: function () {
                return `${this.y.toFixed(2)}`
            }
        },
    }

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                {...props}
            />
        </>
    )
}