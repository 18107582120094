import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

export const SurveyCanceledMessage: FunctionComponent = () => {
    return (
        <Grid container direction='column' style={{ textAlign: 'center' }}>
            <Grid item>
                <Typography variant="h2">
                    Your Poll Has Been Canceled
                </Typography>
            </Grid>
            <Grid>
                <Typography>
                    If you change your mind feel free to re-visit this link and you will be able to start the poll from the beginning.
                </Typography>
            </Grid>
        </Grid>
    )
}