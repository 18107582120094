import { gql } from '@apollo/client';

export const SEND_CHANGE_PASSWORD_EMAIL = gql`
mutation sendChangePasswordEmail($userId:String!){
    changePassword(id:$userId)
}
`

export interface SendChangePasswordEmailDTO {
    deleteUser: boolean;
}