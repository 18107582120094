import { gql } from '@apollo/client';

export const UPDATE_SURVEY_RESPONDENT_PUBLIC = gql`
    mutation updateSurveyForResponse($surveyRespondent: SurveyRespondentInput!, $surveyRespondentId: String!) {
        updateSurveyForResponse(input: $surveyRespondent, id: $surveyRespondentId) {
            id
        }
    }
`

export interface UpdateSurveyRespondentDTO {
    updateSurveyForResponse: {
        id: string;
    }
}