import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { GetDecisionCriteriaStakeholderRankingDataDTO } from 'src/operations';
import theme from 'src/Theme';

interface IDecisionCriteriaStakeholderRankingProps {
    chartData: GetDecisionCriteriaStakeholderRankingDataDTO;
}

export const DecisionCriteriaStakeholderRanking: FunctionComponent<IDecisionCriteriaStakeholderRankingProps> = props => {
    const {
        chartData,
    } = props;
    const [featureNames, setFeatureNames] = useState([] as string[]);
    const [chartBars, setChartBars] = useState([] as Highcharts.SeriesOptionsType[]);

    const mapFeatureRankingsToChartData = (x: {rank: number}) => {
        return x.rank;
    }

    useEffect(() => {
        const averageRankings = chartData.overall.blendedRankings;
        const respondentRankings = chartData.overall.respondentRanking;
        const customerRankings = chartData?.overall?.customerDecisionCriteriaRanking ? chartData?.overall?.customerDecisionCriteriaRanking : null;
        const featureList = respondentRankings.featureRankings.map(x => x.featureName)
        setFeatureNames(featureList)

        const defaultBarProperties: Highcharts.SeriesOptionsType = {
            type: 'column',
        }
        const bars: Highcharts.SeriesOptionsType[] = [
            {
                ...defaultBarProperties,
                name: 'Average',
                data: averageRankings.map(mapFeatureRankingsToChartData)
            },
            {
                ...defaultBarProperties,
                name: `${respondentRankings.respondent.firstName} ${respondentRankings.respondent.lastName[0]}.`,
                data: respondentRankings.featureRankings.map(mapFeatureRankingsToChartData)
            },
        ]

        if (customerRankings?.blendedRankings.length > 0) {
            bars.push({
                ...defaultBarProperties,
                name: 'Customer',
                data: customerRankings?.blendedRankings.map(x => x.rank)
            })
        }

        setChartBars(bars)
    }, [chartData]);

    const options: Highcharts.Options = {
        chart: {
            type: 'bar'
        },
        title: {
            text: 'Your Results',
            align: 'left'
        },
        legend: {
            align: 'left',
            verticalAlign: 'top',
            margin: theme.spacing(5),
        },
        yAxis: {
            title: {
                text: null,
            }
        },
        xAxis: {
            categories: featureNames,
        },
        credits: {
            enabled: false,
        },
        series: chartBars,
        tooltip: {
            formatter: function () {
                return `${this.y.toFixed(2)}`
            }
        },
    }

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                {...props}
            />
        </>
    )
}