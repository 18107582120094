import React, { FunctionComponent } from 'react';
import { Element } from 'slate';
import { useFocused, useSelected } from 'slate-react';

interface IWYSIWYGEditorImageProps {
    attributes: {
        'data-slate-node': "element";
        'data-slate-inline'?: true;
        'data-slate-void'?: true;
        dir?: "rtl";
        ref: any;
    },
    children: any,
    element: Element,
}

export const WYSIWYGEditorImage: FunctionComponent<IWYSIWYGEditorImageProps> = props => {
    const {attributes, children, element} = props;
    const selected = useSelected()
    const focused = useFocused()
    return (
        <div {...attributes}>
        <div contentEditable={false}>
            <img
                src={element.url as string}
                style={{
                    display: 'block',
                    maxWidth: '100%',
                    boxShadow: `${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'}`,
                }}
                alt="Poll Related"
            />
        </div>
        {children}
        </div>
    )
}
