import { gql } from "@apollo/client";

export const GET_SURVEY_DECISION_CRITERIA = gql`
  query getSurveyDecisionCriteria($surveyId: String!) {
    survey(id: $surveyId) {
      account{
        maxDecisionCriteria
      }
      decisionCriteria(orderBy:"createdOn", filterBy:"isLocked:false") {
        id
        name
        description
        weight
        surveyId
        isLocked
      }
    }
  }
`;

export interface GetSurveyDecisionCriteriaDTO {
  survey: {
    account: {
      maxDecisionCriteria: number;
    }
    decisionCriteria: {
      id: string;
      name: string;
      description: string;
      weight: number;
      surveyId: string;
      isLocked: boolean;
    }[];
  };
}
