import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#2e5bff", contrastText: "#ffffff" },
    secondary: { main: "#94D33D" },
    background: {
      default: "#fafafa",
    },
    text: {
      primary: "#212121",
    },
  },
  overrides: {
    MuiToolbar: {
      root: {
        backgroundColor: "#ffffff",
        color: "#2e394c",
      },
    },
    MuiAppBar: {
      root: {
        borderBottom: "1px solid #bfc6d2",
      },
    },
    MuiButton: {
      root: {
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.75px',
        lineHeight: '16px',
        textTransform: 'none'
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '18px',
        fontWeight: 400,
        letterSpacing: '0.5px',
        lineHeight: '24px',
      },
      body2: {
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '0.25px',
        lineHeight: '20px',
      },
      h4: {
        fontSize: "34px",
        fontWeight: 400,
        letterSpacing: '0.25px',
        lineHeight: '51px',
        margin: '0px !important',
      },
      h5: {
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: '36px',
        margin: '0px !important',
      }
    }
  },
});

export default theme;
