import * as React from "react";
import { FunctionComponent } from 'react';
import { BasePage } from "src/Components/core/BasePage";
import { SurveyResultsContainer } from 'src/containers';

export const SurveyResultsView: FunctionComponent = () => {
  return (
    <BasePage>
      <SurveyResultsContainer />
    </BasePage>
  );
}