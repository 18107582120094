import { IconButton, SvgIconProps } from '@material-ui/core';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { useSlate } from 'slate-react';
import { LIST_TYPES } from './types';

interface IWYSIWYGEditorRibbonButtonProps {
    formatType: 'block' | 'mark';
    format: string;
    icon: ReactElement<SvgIconProps>;
}

export const WYSIWYGEditorRibbonButton: FunctionComponent<IWYSIWYGEditorRibbonButtonProps> = props => {
    const {format, formatType, icon} = props;
    const editor = useSlate()
    const [isActive, setIsActive] = useState(false);

    // eslint-disable-next-line
    useEffect(() => {
        let shouldBeActive = false;
        if (formatType === 'block') {
            const [match] = Editor.nodes(editor, {
            match: n =>
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
            })

            shouldBeActive = !!match
        } else {
            const marks = Editor.marks(editor)
            shouldBeActive = marks ? marks[format] === true : false
        }
        if (shouldBeActive !== isActive) {
            setIsActive(shouldBeActive)
        }
    })

    const toggle = () => {
        if (formatType === 'block') {
            const isList = LIST_TYPES.includes(format)

            Transforms.unwrapNodes(editor, {
                match: n =>
                    LIST_TYPES.includes(
                    !Editor.isEditor(n) && SlateElement.isElement(n) && n.type as string
                    ),
                split: true,
            });
            const newProperties: Partial<SlateElement> = {
                type: isActive ? 'paragraph' : isList ? 'list-item' : format,
            }
            Transforms.setNodes(editor, newProperties)

            if (!isActive && isList) {
                const block = { type: format, children: [] as any[] }
                Transforms.wrapNodes(editor, block)
            }
        } else {
            if (isActive) {
                Editor.removeMark(editor, format)
            } else {
                Editor.addMark(editor, format, true)
            }
        }
    }

    return (
        <IconButton
            size="small"
            color={isActive ? 'primary' : 'default'}
            onMouseDown={event => {
                event.preventDefault();
                toggle();
            }}
        >
            {icon}
        </IconButton>
    )
}