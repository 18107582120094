import { gql } from '@apollo/client';

export const DELETE_USER = gql`
mutation deleteUser($userId:String!){
    deleteUser(id:$userId)
}
`

export interface DeleteUserDTO {
    deleteUser: boolean;
}