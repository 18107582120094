import React from 'react';
import { RenderElementProps, RenderLeafProps } from 'slate-react';
import { WYSIWYGEditorImage } from './WYSIWYGEditorImage';

export const LIST_TYPES = ['numbered-list', 'bulleted-list']

export const Element = ({ attributes, children, element }: RenderElementProps) => {
    switch (element.type) {
    case 'block-quote':
        return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>
    case 'heading-one':
        return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
        return <h2 {...attributes}>{children}</h2>
    case 'list-item':
        return <li {...attributes}>{children}</li>
    case 'numbered-list':
        return <ol {...attributes}>{children}</ol>
    case 'image':
        return <WYSIWYGEditorImage attributes={attributes} children={children} element={element} />
    default:
        return <p {...attributes}>{children}</p>
    }
}

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
    if (leaf.bold) {
    children = <strong>{children}</strong>
    }

    if (leaf.italic) {
    children = <em>{children}</em>
    }

    if (leaf.underline) {
    children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}
