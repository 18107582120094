import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Input, InputLabel, ListItemText, MenuItem, Select, TextField } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { RoleDTO, UserDTO } from 'src/models';
import theme from 'src/Theme';
import { validateEmail } from 'src/Utils';
import { DeleteButtonWithConfirmation } from '../core';
import LoadingIndicator from '../core/LoadingIndicator';

export interface IAddEditUserModalProps {
    closeModal: () => void;
    createUser: (newUser: UserDTO) => Promise<void>;
    updateUser: (editedUser: UserDTO, UserId: string) => Promise<void>;
    deleteUser: (UserId: string) => Promise<void>;
    existingUser?: UserDTO;
    isOpen: boolean;
    isLoading?: boolean;
    allRoles: RoleDTO[];
}

export function AddEditUserModal(props: IAddEditUserModalProps) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [roles, setRoles] = useState([] as string[]);
    const {
        closeModal,
        createUser,
        updateUser,
        deleteUser,
        existingUser,
        isOpen,
        isLoading,
        allRoles
    } = props;

    const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFirstName(event.target.value)
    }

    const handleLastNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLastName(event.target.value)
    }

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmail(event.target.value)
    }

    const handleRolesChange = (event: ChangeEvent<{ value: unknown; }>) => {
        setRoles(event.target.value as string[])
    }

    const isFormValid = () => {
        const isBasicInfoValid = !!firstName && !!lastName && !!email && validateEmail(email);
        return isBasicInfoValid;
    }

    const handleAddUser = () => {
        const newUser: UserDTO = {
            userMetaData: {
                firstname: firstName,
                lastname: lastName,
            },
            email: email,
            roles: mapRolesToGraphQL(),
        };
        createUser(newUser)
        closeModal();
    }

    const mapRolesToGraphQL = () => {
        const mappedRoles = roles.map(role => {
            return {
                id: role
            }
        });
        return mappedRoles;
    }

    const handleEditUser = () => {
        const updatedUser: UserDTO = {
            ...existingUser,
            userMetaData: {
                ...existingUser.userMetaData,
                firstname: firstName,
                lastname: lastName
            },
            email: email,
            roles: mapRolesToGraphQL(),
        };

        delete updatedUser.id;
        delete updatedUser.__typename;
        delete updatedUser.userMetaData.__typename;

        updateUser(updatedUser, existingUser.id);
        closeModal();
    }

    const handleDeleteUser = () => {
        deleteUser(existingUser.id);
        closeModal();
    }

    useEffect(() => {
        if (existingUser) {
            setFirstName(existingUser.userMetaData?.firstname || '');
            setLastName(existingUser.userMetaData?.lastname || '');
            setEmail(existingUser.email);
            setRoles(existingUser.roles.map(r => r.id));
        } else {
            setFirstName('');
            setLastName('');
            setEmail('');
            setRoles([]);
        }
    }, [isOpen, existingUser]);

    const renderRolesText = () => {
        return allRoles.filter(role => roles.includes(role.id)).map(role => role.name).join(', ')
    }

    return (
        <>
            <Dialog open={isOpen} onClose={closeModal}>
                <DialogTitle style={{ paddingBottom: '0', fontWeight: 'bold' }}>
                    {existingUser ? 'Edit' : 'Add'} User
                </DialogTitle>
                <DialogContent>
                    {
                        isLoading ?
                            <Grid>
                                <LoadingIndicator />
                            Loading user data...
                        </Grid>
                            :
                            <Grid container direction='column' style={{ minWidth: '400px' }}>
                                <Grid item container direction="row">
                                    <Grid item xs={6}>
                                        <TextField
                                            label='First Name'
                                            value={firstName}
                                            style={{ marginBottom: theme.spacing(1) }}
                                            onChange={handleFirstNameChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label='Last Name'
                                            value={lastName}
                                            style={{ marginBottom: theme.spacing(1) }}
                                            onChange={handleLastNameChange}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <TextField
                                    label='Email Address'
                                    value={email}
                                    style={{ marginBottom: theme.spacing(1) }}
                                    onChange={handleEmailChange}
                                    required
                                    error={!!email && !validateEmail(email)}
                                    helperText={!!email && !validateEmail(email) ? 'Must be a valid email address' : ''}
                                />
                                <FormControl>
                                    <InputLabel id='roles-select'>Roles</InputLabel>
                                    <Select
                                        multiple
                                        labelId='roles-select'
                                        id='roles-select'
                                        value={roles}
                                        input={<Input />}
                                        renderValue={renderRolesText}
                                        style={{ marginBottom: theme.spacing(1) }}
                                        onChange={handleRolesChange}
                                    >
                                        {allRoles && allRoles.map(role => {
                                            return (
                                                <MenuItem key={role.id} value={role.id}>
                                                    <Checkbox checked={roles.includes(role.id)} />
                                                    <ListItemText primary={role.name} />
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                    }
                </DialogContent>
                <DialogActions style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(1) }}>
                    {
                        existingUser &&
                        <DeleteButtonWithConfirmation
                            onDelete={handleDeleteUser}
                            itemName="user"
                        />
                    }
                    <Button color="primary" variant="outlined" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: theme.spacing(2) }}
                        disabled={!isFormValid() || isLoading}
                        onClick={existingUser ? handleEditUser : handleAddUser}
                    >
                        {existingUser ? 'Update' : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}