import { Button, Grid, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useState } from 'react';
import { SurveyFeatureDTO } from 'src/models';
import { AddEditFeatureModal } from './AddEditFeatureModal';
import FeatureCard from './FeatureCard';

export interface ISurveyStepperAddFeaturesProps {
    numFeaturesRemaining: number;
    features: SurveyFeatureDTO[];
    createFeature: (feature: SurveyFeatureDTO) => Promise<void>;
    updateFeature: (feature: SurveyFeatureDTO) => Promise<void>;
    deleteFeature: (featureId: string) => Promise<void>;
}

function SurveyStepperAddFeatures(props: ISurveyStepperAddFeaturesProps) {
    const [isFeatureModalOpen, setIsFeatureModalOpen] = useState(false);
    const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null as number);
    const {
        numFeaturesRemaining,
        features,
        createFeature,
        updateFeature,
        deleteFeature
    } = props;

    const openFeatureModal = () => {
        setIsFeatureModalOpen(true);
    }

    const closeFeatureModal = () => {
        setIsFeatureModalOpen(false);
        if (selectedFeatureIndex !== null) {
            setSelectedFeatureIndex(null);
        }
    }

    const openModalInEditMode = (featureIndex: number) => {
        setSelectedFeatureIndex(featureIndex);
        openFeatureModal()
    }

    return (
        <>
            <Grid container direction='row' justify='center' spacing={2} id="surveyAddFeature" wrap='wrap'>
                {numFeaturesRemaining > 0 && (
                    <Grid item xs={6} sm={4} md={3} lg={2} id="add-feature-button-container">
                        <Button
                            color='primary'
                            variant='contained'
                            style={{ textTransform: 'none', height: '156px'}}
                            fullWidth
                            onClick={openFeatureModal}
                            id="add-feature-button"
                        >
                            <Grid
                                container
                                direction='column'
                                alignItems='center'
                            >
                                <Add />
                                <Typography>
                                    Add Feature
                                </Typography>
                                <Typography style={{color: '#cbcfdf'}}>
                                    { numFeaturesRemaining } Remaining
                                </Typography>
                            </Grid>
                        </Button>
                    </Grid>
                )}
                {features && features.map((feature, index) => {
                    return (
                        <FeatureCard key={`feature-${index}`} feature={feature} featureIndex={index} editFeature={openModalInEditMode} />
                    )
                })}
            </Grid>
            <AddEditFeatureModal
                isOpen={isFeatureModalOpen}
                closeModal={closeFeatureModal}
                createFeature={createFeature}
                updateFeature={updateFeature}
                deleteFeature={deleteFeature}
                existingFeature={selectedFeatureIndex !== null ? features[selectedFeatureIndex] : null}
            />
        </>
    )
}

export default SurveyStepperAddFeatures;