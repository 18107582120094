import { gql } from "@apollo/client";

export const GET_ALL_RESPONDENTS = gql`
query getRespondents($surveyId:String!, $filter: String!) {
    survey(id:$surveyId) {
        surveyRespondentCount
        possibleRespondents(filterBy: $filter, pageSize:1000) {
            id
            firstName
            lastName
            respondentType {
                description
            }
            department {
                name
            }
        }
    }
}`

export interface GetAllRespondentsDTO {
    survey: {
        surveyRespondentCount?: number;
        possibleRespondents: {
                id: string;
                firstName: string;
                lastName: string;
                department: {
                    name: string;
                }
                respondentType: {
                    description: string
                }
            }[]
    }
}