import React, { FunctionComponent, useState } from 'react';
import {
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import PageHeaderCard from "../core/PageHeaderCard";
import AddIcon from "@material-ui/icons/Add";
import { SurveyFeatureDTO } from "src/models";
import theme from 'src/Theme';
import { Ballot } from '@material-ui/icons';
import { AddEditFeatureModal } from '../Surveys/AddEditFeatureModal';

interface IFeaturesProps {
  numFeaturesRemaining: number;
  features: SurveyFeatureDTO[];
  createFeature: (feature: SurveyFeatureDTO) => Promise<void>;
  updateFeature: (feature: SurveyFeatureDTO) => Promise<void>;
  deleteFeature: (featureId: string) => Promise<void>;
  isEditDisabled?: boolean;
}

export const FeaturesCard: FunctionComponent<IFeaturesProps> = props => {
  const [isFeatureModalOpen, setIsFeatureModalOpen] = useState(false);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null as number);
  const {
      numFeaturesRemaining,
      features,
      createFeature,
      updateFeature,
      deleteFeature,
      isEditDisabled
  } = props;

  const openFeatureModal = () => {
      setIsFeatureModalOpen(true);
  }

  const closeFeatureModal = () => {
      setIsFeatureModalOpen(false);
      if (selectedFeatureIndex !== null) {
          setSelectedFeatureIndex(null);
      }
  }

  const openModalInEditMode = (featureIndex: number) => {
      setSelectedFeatureIndex(featureIndex);
      openFeatureModal()
  }

  const addFeaturesButton = (
    <Button
      startIcon={<AddIcon />}
      color="primary"
      variant="outlined"
      disabled={numFeaturesRemaining === 0 || isEditDisabled}
      onClick={openFeatureModal}
    >
      Add Feature
    </Button>
  );

  return (
    <>
      <Card>
        <CardContent>
          <PageHeaderCard
            icon={<Ballot style={{ color: "grey" }} />}
            title={"Features (" + features.length + ")"}
            titleComponent={addFeaturesButton}
            isTitleComponentPushedRight={true}
            titleVariant="h5"
          />
          <List style={{ marginTop: theme.spacing(1), maxHeight: 283, overflow: "auto" }}>
            {!!features &&
              features.map((feature, index) => (
                <div key={feature.id}>
                  <ListItem button={true} onClick={() => {openModalInEditMode(index)}}>
                    <ListItemText
                      primary={feature.name}
                      secondary={feature.description}
                    />
                  </ListItem>
                  <Divider />
                </div>
              ))}
          </List>
        </CardContent>
      </Card>
      <AddEditFeatureModal
        isOpen={isFeatureModalOpen}
        closeModal={closeFeatureModal}
        createFeature={createFeature}
        updateFeature={updateFeature}
        deleteFeature={deleteFeature}
        existingFeature={selectedFeatureIndex !== null ? features[selectedFeatureIndex] : null}
        isEditDisabled={isEditDisabled}
      />
    </>
  );
}