import { Grid, Card, CardContent } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { DecisionCriteriaStakeholderRankingContainer, BlendedAverageContainer, DecisionCriteriaCustomerRankingContainer, CustomerBlendedAverageContainer } from 'src/containers';
import theme from 'src/Theme';

interface ITakeSurveyResultsProps {
    surveyId: string;
    respondentId: string;
    respondentType: string;
}

export const TakeSurveyResults: FunctionComponent<ITakeSurveyResultsProps> = props => {
    const {surveyId, respondentId, respondentType} =  props;

    return (
        <Grid>
            <Grid item  xs={12} style={{padding: theme.spacing(1)}}>
                <Card style={{height: '100%'}}>
                    <CardContent>
                        {(respondentType && respondentType === 'Customer') ? (
                            <DecisionCriteriaCustomerRankingContainer surveyId={surveyId} respondentId={respondentId} />
                        ): (
                            <DecisionCriteriaStakeholderRankingContainer surveyId={surveyId} respondentId={respondentId} />
                        )}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item  xs={12} style={{padding: theme.spacing(1)}}>
                <Card style={{height: '100%'}}>
                    <CardContent>
                        {(respondentType && respondentType === 'Customer') ? (
                            <CustomerBlendedAverageContainer surveyId={surveyId} />
                        ): (
                            <BlendedAverageContainer surveyId={surveyId} />
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}