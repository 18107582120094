import { useQuery } from "@apollo/client";
import { Card, CardContent, Grid } from '@material-ui/core';
import React, { FunctionComponent } from "react";
import { useParams } from 'react-router-dom';
import { GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import { DepartmentDecisionCriteriaPrioritization } from 'src/Components/Graphs';
import { DecisionCriteriaRankingDTO, GetDepartmentDecisionCriteriaPrioritizationDataDTO, GET_DEPARTMENT_DECISION_CRITERIA_PRIORITIZATION_DATA } from "src/operations";
import theme from 'src/Theme';

interface IDepartmentDecisionCriteriaPrioritizationContainerProps {
    surveyId: string;
}

export interface DepartmentDecisionCriteriaStakeholderRankingDTO {
  featureName: string;
  rank: number;
}

export interface DepartmentDecisionCriteriaDepartmentRankingDTO {
  departmentName: string;
  rankings: [{
      featureName: string;
      rank: number;
  }];
}

export const DepartmentDecisionCriteriaPrioritizationContainer: FunctionComponent<IDepartmentDecisionCriteriaPrioritizationContainerProps> = () => {
  const { surveyId } = useParams<{ surveyId: string }>();

  const {data, loading: isLoading, error, refetch} = useQuery<GetDepartmentDecisionCriteriaPrioritizationDataDTO>(GET_DEPARTMENT_DECISION_CRITERIA_PRIORITIZATION_DATA, {
    variables: {
      surveyId: surveyId
    },
  });

  const reloadResults = async () => {
    await refetch({
      surveyId: surveyId,
    });
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || data === undefined) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='department desired outcomes graph' />;
  }

  let stakeholderRankings: Partial<DecisionCriteriaRankingDTO>[] = null;
  if (data?.survey?.stakeholders[0] && data?.survey?.stakeholders[0]?.surveyRankings?.decisionCriteriaRankings) {
    stakeholderRankings = data.survey.stakeholders[0].surveyRankings.decisionCriteriaRankings;
  }

  const departmentRankings = data.survey.departments;
  if (!stakeholderRankings) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='stakeholder rankings' />;
  }

  const decisionCriteria = stakeholderRankings.map(sr => sr.decisionCriteria);

  return (
    <>
      {decisionCriteria && departmentRankings && decisionCriteria.map((dc, index) => {

        const stakeholderBlendedRanking: DepartmentDecisionCriteriaStakeholderRankingDTO[] = stakeholderRankings.find(sr => sr.decisionCriteria.id === dc.id).blendedRankings;
        const departmentBlendedRankings: DepartmentDecisionCriteriaDepartmentRankingDTO[] = departmentRankings.map(dr => {
          return {
            departmentName: dr.name,
            rankings: dr.surveyRankings.decisionCriteriaRankings.find(dcr => dcr.decisionCriteria.id === dc.id).blendedRankings
          }
        });

        return (
          <Grid item xs={12} style={{padding: theme.spacing(1)}}>
            <Card key={index} style={{height: '100%'}}>
              <CardContent>
                <DepartmentDecisionCriteriaPrioritization
                  decisionCriteriaName={dc.name}
                  stakeholderRanking={stakeholderBlendedRanking}
                  departmentRankings={departmentBlendedRankings}
                />
              </CardContent>
            </Card>
          </Grid>
        )
      })}
    </>
  )

}
