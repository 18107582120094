import { useMutation } from '@apollo/client';
import _ from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import LoadingIndicator from 'src/Components/core/LoadingIndicator';
import { TakeSurveyDecisionCriteria } from 'src/Components/TakeSurvey/TakeSurveyDecisionCriteria';
import { DecisionCriteriaDTO, SurveyResponseDTO } from 'src/models';
import { CreateSurveyResponseDTO, CREATE_SURVEY_RESPONSE_PUBLIC, UpdateSurveyResponseDTO, UPDATE_SURVEY_RESPONSE_PUBLIC } from 'src/operations';

interface ITakeSurveyDecisionCriteriaContainerProps {
    surveyId: string;
    respondentId: string;
    decisionCriteria: Partial<DecisionCriteriaDTO>;
    existingResponse?: Partial<SurveyResponseDTO>;
    featureIds: string[];
    currentStep: number;
    surveyRespondentId: string;
}

export const TakeSurveyDecisionCriteriaContainer: FunctionComponent<ITakeSurveyDecisionCriteriaContainerProps> = props => {
    const {
        surveyId,
        respondentId,
        decisionCriteria,
        existingResponse,
        featureIds,
        currentStep,
        surveyRespondentId,
    } = props;
    const [createSurveyResponse] = useMutation<CreateSurveyResponseDTO>(CREATE_SURVEY_RESPONSE_PUBLIC)
    const [updateSurveyResponse] = useMutation<UpdateSurveyResponseDTO>(UPDATE_SURVEY_RESPONSE_PUBLIC)
    const [response, setResponse] = useState(existingResponse);
    const [isCreatingRecord, setIsCreatingRecord] = useState(false);

    useEffect(() => {
        setResponse(existingResponse);
    }, [currentStep, existingResponse])

    useEffect(() => {
        setIsCreatingRecord(false);
    }, [response])

    const handleCreateSurveyResponse = useCallback(async (surveyFeatureId: string, comparedToSurveyFeatureId: string, surveyDecisionCriteriaId: string) => {
        const newSurveyResponse: SurveyResponseDTO = {
            surveyId: surveyId,
            respondentId: respondentId,
            surveyRespondentId: surveyRespondentId,
            surveyFeatureId: surveyFeatureId,
            comparedToSurveyFeatureId: comparedToSurveyFeatureId,
            surveyDecisionCriteriaId: surveyDecisionCriteriaId,
            ranking: 1,
        }
        const result = await createSurveyResponse({
            variables: {
                surveyResponse: newSurveyResponse
            }
        })

        newSurveyResponse.id = result.data.createResponseForSurvey.id;
        setResponse(newSurveyResponse)
    }, [surveyId, respondentId, surveyRespondentId, createSurveyResponse]);

    useEffect(() => {
        if (existingResponse && !response) {
            setResponse(_.cloneDeep(existingResponse))
            return;
        }
        if (!existingResponse && !response) {
            setIsCreatingRecord(true)
            if (!isCreatingRecord) {
                handleCreateSurveyResponse(featureIds[0], featureIds[1], decisionCriteria.id)
            }
        }
    }, [
        existingResponse,
        handleCreateSurveyResponse,
        featureIds,
        decisionCriteria,
        isCreatingRecord,
        response
    ])

    const handleSurveyResponseUpdate = (updatedSurveyResponse: Partial<SurveyResponseDTO>) => {

        const formattedSurveyResponse: Partial<SurveyResponseDTO> = {
            surveyId: surveyId,
            respondentId: respondentId,
            surveyRespondentId: surveyRespondentId,
            surveyFeatureId: updatedSurveyResponse.surveyFeatureId,
            comparedToSurveyFeatureId: updatedSurveyResponse.comparedToSurveyFeatureId,
            surveyDecisionCriteriaId: updatedSurveyResponse.surveyDecisionCriteriaId,
            ranking: updatedSurveyResponse.ranking,
        }

        updateSurveyResponse({
            variables: {
                surveyResponse: formattedSurveyResponse,
                surveyResponseId: updatedSurveyResponse.id
            }
        })
    }

    if (!response) {
        return (
            <LoadingIndicator />
        )
    }

    return (
        <TakeSurveyDecisionCriteria
            response={response}
            updateSurveyResponse={handleSurveyResponseUpdate}
        />
    )
}