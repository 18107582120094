import { gql } from "@apollo/client";

export const GET_USERS = gql`
query getUsers($filter:String) {
    users(pageSize: 100, filterBy:$filter) {
        id
        email
        blocked
        roles {
            name
        }
        userMetaData {
            firstname
            lastname
        }
    }
}
`;

export interface GetUsersDTO {
  users: [
    {
        id: string;
        email: string;
        blocked: boolean;
        roles: [{
            name: string;
        }],
        userMetaData: {
            firstname: string;
            lastname: string;
        }
    }
  ];
}
