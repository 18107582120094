import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { GetResponseGaugeDataDTO } from 'src/operations';
import { SurveyRespondentStatusOptions } from 'src/models';

highchartsMore(Highcharts);
solidGauge(Highcharts);

interface IResponseGaugeProps {
    chartData: GetResponseGaugeDataDTO;
}

export const ResponseGauge: FunctionComponent<IResponseGaugeProps> = props => {
    const {
        chartData,
    } = props;
    const [customerGauge, setCustomerGauge] = useState([] as Highcharts.SeriesOptionsType[]);
    const [stakeholderGauge, setStakeholderGauge] = useState([] as Highcharts.SeriesOptionsType[]);

    useEffect(() => {
        const getPercentageByType = (respondentTypeName: string) => {
            const surveyRespondents = chartData.surveyRespondents;
            const filteredSurveyRespondent = surveyRespondents.filter(sr => sr.respondent.respondentType.description === respondentTypeName);
            const numResponses = filteredSurveyRespondent.length;
            const numCompleted = filteredSurveyRespondent.filter(sr => sr.status === SurveyRespondentStatusOptions.completed).length;
            if (numResponses === 0) {
                return 0
            }
            return Math.round((numCompleted / numResponses) * 100);
        }

        const defaultGaugeValues: Highcharts.SeriesOptionsType = {
            type: 'solidgauge',
            innerRadius: '80%'
        }

        const customerPercentage = getPercentageByType('Customer');
        setCustomerGauge([{
            ...defaultGaugeValues,
            name: 'Customers',
            data: [[customerPercentage]],
        }])
        const stakeholderPercentage = getPercentageByType('Stakeholder');
        setStakeholderGauge([{
            ...defaultGaugeValues,
            name: 'Stakeholders',
            data: [[stakeholderPercentage]],
        }])

    }, [chartData]);

    const options: Highcharts.Options = {
        pane: {
            background: [{
                outerRadius: '100%',
                innerRadius: '80%',
                borderWidth: 0,
                backgroundColor: 'rgba(0,0,0,.1)'
            }],
        },
        chart: {
            type: 'solidgauge',
            height: 200
        },
        yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: [],
            labels: {
                enabled: false
            },
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    borderWidth: 0,
                    enabled: true,
                    verticalAlign: 'middle',
                    formatter: function () {
                        return `${this.y.toFixed(2)}%`
                    },
                    style: {
                        textAlign: 'center',
                        fontSize: '24px'
                    },
                },
            }
        },
        tooltip: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    }

    const customerGaugeOptions: Highcharts.Options = {
        ...options,
        title: {
            text: 'Customers',
            verticalAlign: 'bottom'
        },
        series: customerGauge,
    }

    const stakeholdersGaugeOptions: Highcharts.Options = {
        ...options,
        title: {
            text: 'Stakeholders',
            verticalAlign: 'bottom'
        },
        series: stakeholderGauge,
    }

    return (
        <Grid>
            <Typography style={{ fontFamily: 'Lucida Grande', fontSize: '18px', color: '#333333' }}>
                Responses
            </Typography>
            <HighchartsReact
                highcharts={Highcharts}
                options={customerGaugeOptions}
                {...props}
            />
            <HighchartsReact
                highcharts={Highcharts}
                options={stakeholdersGaugeOptions}
                {...props}
            />
        </Grid>
    )
}
