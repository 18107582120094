import { useQuery } from "@apollo/client";
import React, { FunctionComponent } from "react";
import { GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import { BlendedAverage } from 'src/Components/Graphs/BlendedAverage';
import { GetBlendedAverageDataDTO, GET_BLENDED_AVERAGE_DATA } from "src/operations";

interface IBlendedAverageContainerProps {
  surveyId: string;
}

export const BlendedAverageContainer: FunctionComponent<IBlendedAverageContainerProps> = props => {
  const { surveyId } = props;

  const {data, loading: isLoading, error, refetch} = useQuery<GetBlendedAverageDataDTO>(GET_BLENDED_AVERAGE_DATA, {
    variables: {
      surveyId: surveyId,
    },
  });

  const reloadResults = async () => {
    await refetch({
      surveyId: surveyId,
    })
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || data === undefined) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='blended average graph' />;
  }

  return (
    <BlendedAverage
      chartData={data}
    />
  );
}
