import { CreateSurveyFeatureDTO, DeleteSurveyDTO, UpdateSurveyFeatureDTO } from 'src/operations';
import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { SurveyFeatureDTO } from 'src/models';
import _ from 'lodash';

export class AddEditFeatureContainerLogic {
    public async createSurveyFeatureInDB(newFeature: SurveyFeatureDTO, createSurveyFeature:(options?: MutationFunctionOptions) => Promise<FetchResult<CreateSurveyFeatureDTO>>) {
        const result: FetchResult<CreateSurveyFeatureDTO> = await createSurveyFeature({
            variables: {
                surveyFeature: newFeature,
            }
        });
        const surveyFeatureId = result.data.createSurveyFeature.id;
        return surveyFeatureId;
    }

    public async deleteSurveyFeatureInDB(featureId: string, deleteSurveyFeature: (options?: MutationFunctionOptions) => Promise<FetchResult<DeleteSurveyDTO>>) {
        await deleteSurveyFeature({
            variables: {
                surveyFeatureId: featureId
            }
        });
    }

    public async updateSurveyFeatureInDB(editedFeature: SurveyFeatureDTO, updateSurveyFeature: (options?: MutationFunctionOptions) => Promise<FetchResult<UpdateSurveyFeatureDTO>>) {
        const formattedEditedFeature = _.cloneDeep(editedFeature);
        delete formattedEditedFeature.id;
        delete formattedEditedFeature.__typename

        await updateSurveyFeature({
            variables: {
                surveyFeature: formattedEditedFeature,
                surveyFeatureId: editedFeature.id
            }
        });
    }
}