import React, { FunctionComponent } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

interface IConfirmationModalProps {
    onActionConfirmed: () => void;
    isOpen: boolean;
    onClose: () => void;
    message: string;
    alternateNoButtonMessage?: string;
    isSingleOption?: boolean;
}

export const ConfirmationModal: FunctionComponent<IConfirmationModalProps> = props => {
    const {
        onActionConfirmed,
        isOpen,
        onClose,
        message,
        alternateNoButtonMessage,
        isSingleOption
    } = props;

    const handleConfirmation = () => {
        onActionConfirmed();
        onClose();
    }

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            onExited={onClose}
            aria-labelledby="confirmation-dialog-title"
            open={isOpen}
        >
            <DialogTitle id="confirmation-dialog-title">{message}</DialogTitle>
            {isSingleOption ? (
                <DialogActions>
                    <Button onClick={handleConfirmation} color="primary" >
                        Okay
                    </Button>
                </DialogActions>
            ) : (
                <DialogActions>
                    <Button autoFocus onClick={onClose} color="primary">
                        {alternateNoButtonMessage ? alternateNoButtonMessage : 'No'}
                    </Button>
                    <Button onClick={handleConfirmation} color="primary" >
                        Yes
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    )
}