import { gql } from "@apollo/client";

export const GET_ROLES = gql`
{
    roles {
        id
        name
        description
    }
}
`;

export interface GetRolesDTO {
  roles: [
    {
        id: string;
        name: string;
        description: string;
    }
  ];
}
