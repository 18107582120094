import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const AuthorizedApolloProvider = (props: any) => {
  const { getAccessTokenSilently } = useAuth0();

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_BASE_URL,
  });

  const withToken = setContext(async () => {
    const token = await getAccessTokenSilently();
    return {
        headers: {
            Authorization: token
        }
    };
  });

  const apolloClient = new ApolloClient({
    link: withToken.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={apolloClient}>
      {props.children}
    </ApolloProvider>
  );
};

export default AuthorizedApolloProvider;