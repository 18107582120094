import { Toolbar } from '@material-ui/core';
import { FormatBold, FormatItalic, FormatListBulleted, FormatListNumbered, FormatQuote, FormatUnderlined, LooksOne, LooksTwo } from '@material-ui/icons';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { createEditor, Node as SlateNode, Transforms } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { Element, Leaf } from './types';
import { withImages } from './utils';
import { WYSIWYGAddImageButton } from './WYSIWYGAddImageButton';
import { WYSIWYGEditorRibbonButton } from './WYSIWYGEditorRibbonButton';

interface IWYSIWYGEditorProps {
    value: string;
    onChange?: (value: string) => void;
    isReadOnly?: boolean;
}

export const WYSIWYGEditor: FunctionComponent<IWYSIWYGEditorProps> = props => {
    const { onChange, value, isReadOnly } = props;
    const editor = useMemo(() => withImages(withHistory(withReact(createEditor()))), []);
    const renderElement = useCallback(input => <Element {...input} />, [])
    const renderLeaf = useCallback(input => <Leaf {...input} />, [])

    const createParagraphNode = (text?: string): SlateNode[] => {
        Transforms.deselect(editor);
        return [
            {
                type: 'paragraph',
                children: [
                    {text: text || '', marks: []}
                ]
            }
        ]
    }

    const mapStringToNodes = (input: string): SlateNode[] => {
        try {
            const parsedInput: SlateNode[] = JSON.parse(input);
            return parsedInput;
        } catch (error) {
            return createParagraphNode(input);
        }
    }

    const mapNodesToString = (input: SlateNode[]): string => {
        return JSON.stringify(input);
    }

    const handleChange = (updatedValue: SlateNode[]) => {
        onChange(mapNodesToString(updatedValue));
    }

    return (
        <Slate
            editor={editor}
            value={mapStringToNodes(value)}
            onChange={handleChange}
        >
            {
                (!isReadOnly && onChange) && (
                    <Toolbar>
                        <WYSIWYGEditorRibbonButton formatType="mark" format="bold" icon={<FormatBold />} />
                        <WYSIWYGEditorRibbonButton formatType="mark" format="italic" icon={<FormatItalic />} />
                        <WYSIWYGEditorRibbonButton formatType="mark" format="underline" icon={<FormatUnderlined />} />
                        <WYSIWYGEditorRibbonButton formatType="block" format="heading-one" icon={<LooksOne />} />
                        <WYSIWYGEditorRibbonButton formatType="block" format="heading-two" icon={<LooksTwo />} />
                        <WYSIWYGEditorRibbonButton formatType="block" format="block-quote" icon={<FormatQuote />} />
                        <WYSIWYGEditorRibbonButton formatType="block" format="numbered-list" icon={<FormatListNumbered />} />
                        <WYSIWYGEditorRibbonButton formatType="block" format="bulleted-list" icon={<FormatListBulleted />} />
                        <WYSIWYGAddImageButton />
                    </Toolbar>
                )
            }
            <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder="Details"
                spellCheck
                readOnly={isReadOnly || !onChange}
            />
        </Slate>
    )
}