import { gql } from "@apollo/client";

export const GET_RESPONDENT_TYPE_PUBLIC = gql`
  query respondent($respondentId: String!) {
    respondent:respondentForResponse(id: $respondentId) {
      id
      respondentType{
        description
      }
    }
  }
`;

export interface GetRespondentTypeDTO {
  respondent: {
    id: string;
    respondentType: {
      description: string;
    }
  };
}
