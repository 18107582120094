import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { AssignmentTurnedIn } from '@material-ui/icons';
import AddIcon from "@material-ui/icons/Add";
import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { DecisionCriteriaDTO } from "src/models/DecisionCriteriaDTO";
import theme from 'src/Theme';
import PageHeaderCard from "../core/PageHeaderCard";
import AddEditDecisionCriteriaModal from '../Surveys/AddEditDecisionCriteriaModal';

interface IDecisionCriteriaProps {
  numDecisionCriteriaRemaining: number;
  decisionCriteria: DecisionCriteriaDTO[];
  createDecisionCriteria: (decisionCriteria: DecisionCriteriaDTO, otherDecisionCriteria?: DecisionCriteriaDTO[]) => Promise<void>;
  updateDecisionCriteria: (decisionCriteria: DecisionCriteriaDTO, otherDecisionCriteria?: DecisionCriteriaDTO[]) => Promise<void>;
  deleteDecisionCriteria: (otherDecisionCriteria: DecisionCriteriaDTO[], decisionCriteriaId?: string) => Promise<void>;
  isEditDisabled?: boolean;
}

export const DecisionCriteriaCard: FunctionComponent<IDecisionCriteriaProps> = props => {
  const [isDecisionCriteriaModalOpen, setIsDecisionCriteriaModalOpen] = useState(false);
  const [selectedDecisionCriteriaIndex, setSelectedDecisionCriteriaIndex] = useState(null as number);
  const {
      numDecisionCriteriaRemaining,
      decisionCriteria,
      createDecisionCriteria,
      updateDecisionCriteria,
      deleteDecisionCriteria,
      isEditDisabled
  } = props;

  const openDecisionCriteriaModal = () => {
      setIsDecisionCriteriaModalOpen(true);
  }

  const closeDecisionCriteriaModal = () => {
      setIsDecisionCriteriaModalOpen(false);
      if (selectedDecisionCriteriaIndex !== null) {
          setSelectedDecisionCriteriaIndex(null);
      }
  }

  const openModalInEditMode = (decisionCriteriaIndex: number) => {
      setSelectedDecisionCriteriaIndex(decisionCriteriaIndex);
      openDecisionCriteriaModal()
  }

  const nonSelectedDecisionCriteria = selectedDecisionCriteriaIndex !== null ? decisionCriteria.filter((dc, i) => i !== selectedDecisionCriteriaIndex) : decisionCriteria

  const addCriteriaButton = (
    <Button
      startIcon={<AddIcon />}
      color="primary"
      variant="outlined"
      disabled={numDecisionCriteriaRemaining === 0 || isEditDisabled}
      onClick={openDecisionCriteriaModal}
    >
      Add Outcome
    </Button>
  );

  return (
    <>
      <Card>
        <CardContent>
          <PageHeaderCard
            icon={<AssignmentTurnedIn style={{ color: "grey" }} />}
            title={"Desired Outcomes (" + decisionCriteria.length + ")"}
            titleComponent={addCriteriaButton}
            isTitleComponentPushedRight={true}
            titleVariant="h5"
          />
          <List style={{ maxHeight: 270, overflow: "auto" }}>
            {!!decisionCriteria &&
              decisionCriteria.map((criteria, index) => (
                <div key={criteria.id}>
                  <ListItem button={true} onClick={() => {openModalInEditMode(index)}}>
                    <ListItemText
                      primary={criteria.name}
                      secondary={criteria.description}
                    />
                    <Chip
                      label={`${criteria.weight}%`}
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{
                        backgroundColor: "#dfe5fd",
                        borderRadius: theme.spacing(1),
                        position: 'absolute',
                        right: theme.spacing(1),
                        bottom: theme.spacing(1)
                      }} />
                  </ListItem>
                  <Divider />
                </div>
              ))}
          </List>
        </CardContent>
      </Card>
      <AddEditDecisionCriteriaModal
          isOpen={isDecisionCriteriaModalOpen}
          closeModal={closeDecisionCriteriaModal}
          createDecisionCriteria={createDecisionCriteria}
          updateDecisionCriteria={updateDecisionCriteria}
          deleteDecisionCriteria={deleteDecisionCriteria}
          existingDecisionCriteria={selectedDecisionCriteriaIndex !== null ? decisionCriteria[selectedDecisionCriteriaIndex] : null}
          otherDecisionCriteria={_.cloneDeep(nonSelectedDecisionCriteria)}
          isEditDisabled={isEditDisabled}
      />
    </>
  );
}