import React from 'react'
import { DecisionCriteriaDTO } from 'src/models'
import { Chip, Grid, Paper, Typography } from '@material-ui/core';
import theme from 'src/Theme';
import { Edit } from '@material-ui/icons';

export interface IDecisionCriteriaCardProps {
    decisionCriteria: DecisionCriteriaDTO;
    decisionCriteriaIndex: number;
    editDecisionCriteria: (decisionCriteriaIndex: number) => void;
}

export default function DecisionCriteriaCard(props: IDecisionCriteriaCardProps) {
    const { editDecisionCriteria, decisionCriteria, decisionCriteriaIndex } = props;

    const handleEditClicked = () => {
        editDecisionCriteria(decisionCriteriaIndex)
    }

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            className="decision-criteria-card-container"
        >
            <Paper
                style={{
                    minHeight: '156px',
                    padding: theme.spacing(1),
                    borderRadius: '4px',
                    width: '100%'
                }}
                elevation={1}
                className="decision-criteria-card"
            >
                <Grid container direction="column" style={{height: '100%'}}>
                    <Grid container item direction="row" justify="space-between">
                        <Grid item xs={10}>
                            <Typography variant='h6'>
                                {decisionCriteria.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Edit onClick={handleEditClicked} style={{cursor: 'pointer'}}/>
                        </Grid>
                    </Grid>
                    <Grid container item direction="row">
                        <Grid item>
                            <Typography variant='body2'>
                                {decisionCriteria.description}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item direction="row-reverse" style={{marginTop: 'auto'}}>
                        <Grid item>
                            <Chip
                                label={`${decisionCriteria.weight}%`}
                                variant="outlined"
                                color="primary"
                                size="small"
                                style={{ backgroundColor: "#dfe5fd", borderRadius: theme.spacing(1)}}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}