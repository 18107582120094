import { useQuery } from "@apollo/client";
import { Card, CardContent, Grid } from '@material-ui/core';
import React, { FunctionComponent } from "react";
import { useParams } from 'react-router-dom';
import { GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import { StakeholderDecisionCriteriaPrioritization } from 'src/Components/Graphs';
import { DecisionCriteriaRankingDTO, GetStakeholderDecisionCriteriaPrioritizationDataDTO, GET_STAKEHOLDER_DECISION_CRITERIA_PRIORITIZATION_DATA } from "src/operations";
import theme from 'src/Theme';

interface IStakeholderDecisionCriteriaPrioritizationContainerProps {
    surveyId: string;
}

export const StakeholderDecisionCriteriaPrioritizationContainer: FunctionComponent<IStakeholderDecisionCriteriaPrioritizationContainerProps> = () => {
  const { surveyId } = useParams<{ surveyId: string }>();

  const {data, loading: isLoading, error, refetch} = useQuery<GetStakeholderDecisionCriteriaPrioritizationDataDTO>(GET_STAKEHOLDER_DECISION_CRITERIA_PRIORITIZATION_DATA, {
    variables: {
      surveyId: surveyId
    },
  });

  const reloadResults = async () => {
    await refetch({
      surveyId: surveyId
    });
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || data === undefined) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='stakeholder desired outcomes blended average graph' />;
  }

  let decisionCriteriaRankings: DecisionCriteriaRankingDTO[] = null;
  if (data?.survey?.stakeholders[0] && data?.survey?.stakeholders[0]?.surveyRankings?.decisionCriteriaRankings) {
    decisionCriteriaRankings = data.survey.stakeholders[0].surveyRankings.decisionCriteriaRankings;
  }

  return (
    <>
      {decisionCriteriaRankings && decisionCriteriaRankings.map((dcr, index) => {
        return (
          <Grid item xs={12} style={{padding: theme.spacing(1)}}>
            <Card key={index} style={{height: '100%'}}>
              <CardContent>
                <StakeholderDecisionCriteriaPrioritization
                  chartData={dcr}
                />
              </CardContent>
            </Card>
          </Grid>
        )
      })}
    </>
  )

}
