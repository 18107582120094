import { useQuery } from "@apollo/client";
import React, { FunctionComponent } from "react";
import { GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import { CustomerBlendedAverage } from 'src/Components/Graphs/CustomerBlendedAverage';
import { GetCustomerBlendedAverageDTO, GET_CUSTOMER_BLENDED_AVERAGE } from "src/operations";

interface ICustomerBlendedAverageContainerProps {
  surveyId: string;
}

export const CustomerBlendedAverageContainer: FunctionComponent<ICustomerBlendedAverageContainerProps> = props => {
  const { surveyId } = props;

  const {data, loading: isLoading, error, refetch} = useQuery<GetCustomerBlendedAverageDTO>(GET_CUSTOMER_BLENDED_AVERAGE, {
    variables: {
      surveyId: surveyId,
    },
  });

  const reloadResults = async () => {
    await refetch({
      surveyId: surveyId,
    })
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || data === undefined) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='blended average graph' />;
  }

  return (
    <CustomerBlendedAverage
      chartData={data}
    />
  );
}
