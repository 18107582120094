import { GetSurveysDTO } from "src/operations/queries/getSurveys";
import { Grid } from "@material-ui/core";
import SurveyCard from "./SurveyCard";
import React from "react";
import { Link } from "react-router-dom";

interface ISurveyCardListProps {
  queryData: GetSurveysDTO;
}

class SurveyCardList extends React.Component<ISurveyCardListProps> {
  public render() {
    return (
      <Grid container>
        {!!this.props.queryData &&
          this.props.queryData.surveys.map((survey) => (
            <Grid item xs={12} md={6} lg={3} xl={2} key={survey.id}>
              <Link
                to={"/SurveyDetails/" + survey.id}
                style={{ textDecoration: "none" }}
              >
                <SurveyCard
                  title={survey.name}
                  description={survey.description}
                  projectName={survey.projectName}
                  dateLocked={survey.dateLocked}
                  isLocked={survey.isLocked}
                  isLockingSoon={survey.isLockingSoon}
                  millsUntilLocked={survey.millsUntilLock}
                />
              </Link>
            </Grid>
          ))}
      </Grid>
    );
  }
}

export default SurveyCardList;
