import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { AddEditUserModal } from 'src/Components/Users';
import { UserDTO } from 'src/models'
import { CREATE_USER, DELETE_USER, GetRolesDTO, GetUserDTO, GET_ROLES, GET_USER, UPDATE_USER } from 'src/operations';

export interface IAddEditUserModalContainerProps {
    isOpen: boolean;
    userId?: string;
    closeModal: () => void;
    reloadUsers: () => void;
}

export function AddEditUserModalContainer(props: IAddEditUserModalContainerProps) {
    const {
        closeModal,
        isOpen,
        userId,
        reloadUsers,
    } = props;
    const [createUser] = useMutation(CREATE_USER);
    const [updateUser] = useMutation(UPDATE_USER);
    const [deleteUser] = useMutation(DELETE_USER);
    const [getUser, { data: userData, loading: isUserDataLoading }] = useLazyQuery(GET_USER, { fetchPolicy: 'network-only' });
    const {data: rolesData, loading: isRolesLoading} = useQuery<GetRolesDTO>(GET_ROLES);
    const [selectedUser, setSelectedUser] = useState(null as UserDTO);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (isOpen && userId) {
            getUser({
                variables: {
                    userId: userId,
                },
            })
        }
    }, [isOpen, userId, getUser])

    useEffect(() => {
        const data: GetUserDTO = userData
        setSelectedUser(data?.user)
    }, [userData])

    const handleModalClose = () => {
        setSelectedUser(null)
        closeModal()
    }

    const handleCreateUser = async (newUser: UserDTO) => {
        try {
            await createUser({
                variables: {
                    user: newUser,
                }
            });
            reloadUsers();
        } catch (error) {
            enqueueSnackbar('An error occurred while attempting to create a new user.', { variant: 'error' })
        }
    }

    const handleUpdateUser = async (editedUser: UserDTO, UserId: string) => {
        try {
            await updateUser({
                variables: {
                    user: editedUser,
                    userId: UserId
                }
            });
            reloadUsers();
        } catch (error) {
            enqueueSnackbar('An error occurred while attempting to update an existing user.', { variant: 'error' })
        }
    }

    const handleDeleteUser = async (UserId: string) => {
        try {
            await deleteUser({
                variables: {
                    userId: UserId,
                }
            });
            reloadUsers();
        } catch (error) {
            enqueueSnackbar('An error occurred while attempting to delete a user.', { variant: 'error' })
        }
    }

    return (
        <AddEditUserModal
            closeModal={handleModalClose}
            createUser={handleCreateUser}
            updateUser={handleUpdateUser}
            deleteUser={handleDeleteUser}
            existingUser={selectedUser}
            allRoles={rolesData?.roles || []}
            isOpen={isOpen}
            isLoading={isUserDataLoading || isRolesLoading}
        />
    )
}