import { gql } from '@apollo/client';

export const CREATE_SURVEY_RESPONSE_PUBLIC = gql`
mutation createResponseForSurvey($surveyResponse: SurveyResponseInput!) {
    createResponseForSurvey(input: $surveyResponse){
        id
    }
}
`

export interface CreateSurveyResponseDTO {
    createResponseForSurvey: {
        id: string;
    }
}