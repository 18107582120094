import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { BasePage } from 'src/Components';
import { AccountPageContainer } from 'src/containers';

export const AccountView: FunctionComponent = () => {
    return (
        <Grid container direction="column" alignItems="stretch">
            <BasePage>
                <AccountPageContainer />
            </BasePage>
        </Grid>
    );
}