import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { SurveyFeatureDTO } from 'src/models';
import LoadingIndicator from '../core/LoadingIndicator';

interface ITakeSurveyTitleProps {
    featurePair: Partial<SurveyFeatureDTO>[];
}

export const TakeSurveyTitle: FunctionComponent<ITakeSurveyTitleProps> = props => {
    const {
        featurePair,
    } = props;

    if (!featurePair || featurePair.length < 2) {
        return (
            <LoadingIndicator />
        )
    }

    return (
        <Grid container item direction="row" style={{ padding: '0 8px' }}>
            <Grid item xs={6}>
                <Typography variant='h6'>
                    {featurePair[0]?.name}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant='h6' style={{ textAlign: 'end' }}>
                    {featurePair[1]?.name}
                </Typography>
            </Grid>
        </Grid>
    )
}