import { UpdateSurveyDTO } from "src/operations";
import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import { SurveyDTO } from "src/models";
import _ from "lodash";

export class SurveyDetailsLogic {
  public async updateSurveyInDB(
    updateSurvey: (
      options?: MutationFunctionOptions
    ) => Promise<FetchResult<UpdateSurveyDTO>>,
    formattedSurvey: SurveyDTO,
    surveyId: string
  ) {
    return await updateSurvey({
      variables: {
        survey: _.cloneDeep(formattedSurvey),
        surveyId: surveyId,
      },
    });
  }
}
