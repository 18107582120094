import { gql } from '@apollo/client';

export const UPDATE_SURVEY_FEATURE = gql`
    mutation updateSurveyFeature($surveyFeature: SurveyFeatureInput!, $surveyFeatureId: String!) {
        updateSurveyFeature(input: $surveyFeature, id: $surveyFeatureId) {
            id
        }
    }
`

export interface UpdateSurveyFeatureDTO {
    updateSurveyFeature: {
        id: string;
    }
}