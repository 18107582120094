import { IRoute } from "./routing/types";
import {
  TakeSurveyView,
  AddNewSurveyView,
  SurveyDetailsView,
  SurveyListView,
  SurveyResultsView,
  UsersView,
  SendFeedbackView,
  SupportRequestView,
  AccountView
} from './Views';


const routes: IRoute[] = [
  {
    component: SurveyListView,
    exact: true,
    path: "/",
  },
  {
    component: SurveyListView,
    exact: true,
    path: "/Surveys",
  },
  {
    component: SurveyDetailsView,
    exact: true,
    path: "/SurveyDetails/:surveyId",
  },
  {
    component: SurveyResultsView,
    exact: true,
    path: "/SurveyResults/:surveyId",
  },
  {
    component: AddNewSurveyView,
    exact: true,
    path: "/AddNewSurvey",
  },
  {
    component: SendFeedbackView,
    exact: true,
    path: "/SendFeedback",
  },
  {
    component: TakeSurveyView,
    exact: true,
    path: "/TakeSurvey/:surveyRespondentId",
  },
  {
    component: UsersView,
    exact: true,
    path: "/Users",
  },
  {
    component: SupportRequestView,
    exact: true,
    path: "/Support",
  },
  {
    component: AccountView,
    exact: true,
    path: "/Account",
  },
];

const allRoutes = routes;
export default allRoutes;
