import { gql } from "@apollo/client";

export const GET_DECISION_CRITERIA_DATA = gql`
query getSurvey($surveyId: String!) {
  survey(id: $surveyId) {
    decisionCriteria(filterBy:"isLocked:false") {
      name
      weight
    }
  }
}
`;

export interface GetDecisionCriteriaDataDTO {
  survey: {
    decisionCriteria: {
      name: string;
      weight: number;
    }[];
  };
}
