import { gql } from "@apollo/client";

export const GET_SURVEY_RESPONDENT_UNAUTHENTICATED = gql`
  query getSurveyRespondentForResponse($surveyRespondentId: String!) {
    getSurveyRespondentForResponse(id: $surveyRespondentId){
      respondent{
        id
        firstName
        lastName
        respondentType{
          description
        }
      }
    }
  }
`;

export interface GetSurveyRespondentUnauthenticatedDTO {
  getSurveyRespondentForResponse: {
    respondent: {
      id: string
      firstName: string;
      lastName: string;
      respondentType: {
        description: string;
      }
    }
  };
}
