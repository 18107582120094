import { useQuery } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { GraphQLErrorIndicator, SurveyResults } from 'src/Components';
import LoadingIndicator from 'src/Components/core/LoadingIndicator';
import { GetSurveyNameDTO, GET_SURVEY_NAME } from 'src/operations';

export const SurveyResultsContainer: FunctionComponent = () => {
  const { surveyId } = useParams<{ surveyId: string }>();

  const {data, loading: isLoading, error, refetch} = useQuery<GetSurveyNameDTO>(GET_SURVEY_NAME, {
    variables: {
      surveyId: surveyId,
    },
  });

  const reloadResults = async () => {
    await refetch({
      surveyId: surveyId
    });
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || data === undefined) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='poll results' />;
  }

  return (
    <SurveyResults
      surveyId={surveyId}
      surveyName={data.survey.name}
    />
  );
}