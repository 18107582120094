import React, { useState, ChangeEvent, FunctionComponent } from 'react';
import { Grid, Button, Box, TextField, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import PageHeaderCard from "src/Components/core/PageHeaderCard";
import HelpIcon from '@material-ui/icons/Help';
import { useAuth0 } from '@auth0/auth0-react';
import { FormValidationTooltip } from '../core';
import { validateEmail } from 'src/Utils';
import { useSnackbar } from 'notistack';
import { SEND_SUPPORT_EMAIL } from 'src/operations';
import { useMutation } from '@apollo/client';

interface ISupportRequestPageProps {
    userFullName?: string;
}

export const SupportRequestPage: FunctionComponent<ISupportRequestPageProps> = props => {
    const { userFullName } = props;
    const {user} = useAuth0();
    const {email, phone_number} = user;
    const [supportMessage, setSupportMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [shouldHideTextArea, setShouldHideTextArea] = useState(false);
    const [contactPref, setContactPref] = useState('email');
    const [contactEmail, setContactEmail] = useState(email || '');
    const [contactPhone, setContactPhone] = useState(phone_number || '');
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');
    const supportText = `${userFullName ? `Hey ${userFullName}, we’re` : 'We’re'} sorry something isn’t working as you expected.`
    const [sendFeedbackEmail] = useMutation(SEND_SUPPORT_EMAIL);
    const { enqueueSnackbar } = useSnackbar();

    const handleContactEmailChange = (event: ChangeEvent<HTMLInputElement >) => {
        setContactEmail(event.target.value);
    }
    function setRedStyle() {
        if (validateEmail(contactEmail)===true) {
            setHelperText('');
            setError(false);
        } else if (validateEmail(contactEmail)===false) {
            setHelperText('Please Enter a Valid Email');
            setError(true);
        }
    }

    const handleContactPhoneChange = (event: ChangeEvent<HTMLInputElement >) => {
        setContactPhone(event.target.value);
    }

    const handleContactChange = (event: ChangeEvent<HTMLInputElement >) => {
        setContactPref(event.target.value);
    }

    const handleFeedbackChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSupportMessage(event.target.value)
    }

    const handleClick = async () => {
        setIsLoading(true);
        const supportRequestInput = {
            prefContactMethod: contactPref,
            email: contactEmail,
            phone: contactPhone,
            message: supportMessage
        }
        const isSuccessful = await sendFeedbackEmail({
            variables: {
                supportRequest: supportRequestInput,
            }
        })
        if (isSuccessful) {
            setShouldHideTextArea(true)
        } else {
            enqueueSnackbar('An error occurred while attempting to send support request', { variant: 'error' });
        }
        setIsLoading(false);
    }

    const isFormValid = () => {
        return (!!(supportMessage && contactPref && (contactPref ==='email' ? contactEmail : contactPhone))) && error===false;
    }

    return (
        <Box m={2} border={1} borderRadius={7} bgcolor={'#ffffff'}>
            <Grid container direction="row" style={{ marginLeft: 15, marginTop: 15 }}>
                <PageHeaderCard title="Support" icon={<HelpIcon />} iconColor={"blue"} iconTitleUniform={true}/>
            </Grid>

            {
                shouldHideTextArea ? (
                    <Box m={3} pl={2} fontSize= {20} width="95%">
                        We've got it! We'll be following up soon!
                    </Box>
                ) : (
                    <Box m={1} width="100%">
                        <Grid container direction="column" alignItems="center" justify="center">
                            <Box width="95%" marginRight={2}>
                                <Box fontSize={20}>
                                    {supportText}
                                </Box>
                                <br/>
                                <Box fontSize= {20}>
                                    How can we help?
                                </Box>
                                <TextField
                                    fullWidth
                                    multiline
                                    autoFocus
                                    required
                                    value={supportMessage}
                                    onChange={handleFeedbackChange}
                                    rows={4}
                                    margin="normal"
                                    variant="outlined">
                                </TextField>
                                <br/>
                                <br/>
                                <Box fontSize= {20}>
                                    Preferred contact method:
                                </Box>
                                <FormControl component="fieldset">
                                <RadioGroup aria-label="PerferredContactMethod" name="PerferredContactMethodChoice" value={contactPref} onChange={handleContactChange}>
                                    <FormControlLabel value="email" control={<Radio />} label={
                                        <TextField
                                            required={contactPref==='email'}
                                            value={contactEmail}
                                            error = {error && contactPref==='email'}
                                            helperText = {helperText}
                                            label='Email'
                                            onKeyUp={setRedStyle}
                                            onChange={handleContactEmailChange}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                        } />
                                    <FormControlLabel value="telephone" control={<Radio />} label={
                                        <TextField
                                            required={contactPref==='telephone'}
                                            value={contactPhone}
                                            label='Telephone'
                                            onChange={handleContactPhoneChange}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                        } />
                                </RadioGroup>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Box m={5}>
                            <Grid container direction="row" justify="center">
                                <FormValidationTooltip isVisible={!isFormValid()} >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!isFormValid() || isLoading}
                                    onClick={handleClick}
                                    size="large"
                                >
                                        Submit
                                    </Button>
                                </FormValidationTooltip>

                            </Grid>
                        </Box>
                    </Box>
                )
            }
        </Box>
    );

}

export default SupportRequestPage;