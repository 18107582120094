import { Button, Grid, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import _ from 'lodash';
import React, { useState } from 'react';
import { DecisionCriteriaDTO } from 'src/models';
import AddEditDecisionCriteriaModal from './AddEditDecisionCriteriaModal';
import DecisionCriteriaCard from './DecisionCriteriaCard';

export interface ISurveyStepperAddDecisionCriteriaProps {
    numDecisionCriteriaRemaining: number;
    decisionCriteria: DecisionCriteriaDTO[];
    createDecisionCriteria: (decisionCriteria: DecisionCriteriaDTO, otherDecisionCriteria?: DecisionCriteriaDTO[]) => Promise<void>;
    updateDecisionCriteria: (decisionCriteria: DecisionCriteriaDTO, otherDecisionCriteria?: DecisionCriteriaDTO[]) => Promise<void>;
    deleteDecisionCriteria: (otherDecisionCriteria: DecisionCriteriaDTO[], decisionCriteriaId?: string) => Promise<void>;
}

function SurveyStepperAddDecisionCriteria(props: ISurveyStepperAddDecisionCriteriaProps) {
    const [isDecisionCriteriaModalOpen, setIsDecisionCriteriaModalOpen] = useState(false);
    const [selectedDecisionCriteriaIndex, setSelectedDecisionCriteriaIndex] = useState(null as number);
    const {
        numDecisionCriteriaRemaining,
        decisionCriteria,
        createDecisionCriteria,
        updateDecisionCriteria,
        deleteDecisionCriteria
    } = props;

    const openDecisionCriteriaModal = () => {
        setIsDecisionCriteriaModalOpen(true);
    }

    const closeDecisionCriteriaModal = () => {
        setIsDecisionCriteriaModalOpen(false);
        if (selectedDecisionCriteriaIndex !== null) {
            setSelectedDecisionCriteriaIndex(null);
        }
    }

    const openModalInEditMode = (decisionCriteriaIndex: number) => {
        setSelectedDecisionCriteriaIndex(decisionCriteriaIndex);
        openDecisionCriteriaModal()
    }

    const nonSelectedDecisionCriteria = selectedDecisionCriteriaIndex !== null ? decisionCriteria.filter((dc, i) => i !== selectedDecisionCriteriaIndex) : decisionCriteria

    return (
        <>
            <Grid container direction='row' justify='center' spacing={2} id="surveyAddDecisionCriteria" wrap='wrap'>
                {numDecisionCriteriaRemaining > 0 && (
                    <Grid item xs={6} sm={4} md={3} lg={2} id="add-decision-criteria-button-container">
                        <Button
                            color='primary'
                            variant='contained'
                            style={{ textTransform: 'none', height: '156px'}}
                            fullWidth
                            onClick={openDecisionCriteriaModal}
                            id="add-decision-criteria-button"
                        >
                            <Grid
                                container
                                direction='column'
                                alignItems='center'
                            >
                                <Add />
                                <Typography>
                                    Add Desired Outcome
                                </Typography>
                                <Typography style={{color: '#cbcfdf'}}>
                                    { numDecisionCriteriaRemaining } Remaining
                                </Typography>
                            </Grid>
                        </Button>
                    </Grid>
                )}
                {decisionCriteria && decisionCriteria.map((dc, index) => {
                    return (
                        <DecisionCriteriaCard key={`decisionCriteria-${index}`} decisionCriteria={dc} decisionCriteriaIndex={index} editDecisionCriteria={openModalInEditMode} />
                    )
                })}
            </Grid>
            <AddEditDecisionCriteriaModal
                isOpen={isDecisionCriteriaModalOpen}
                closeModal={closeDecisionCriteriaModal}
                createDecisionCriteria={createDecisionCriteria}
                updateDecisionCriteria={updateDecisionCriteria}
                deleteDecisionCriteria={deleteDecisionCriteria}
                existingDecisionCriteria={selectedDecisionCriteriaIndex !== null ? decisionCriteria[selectedDecisionCriteriaIndex] : null}
                otherDecisionCriteria={_.cloneDeep(nonSelectedDecisionCriteria)}
            />
        </>
    )
}

export default SurveyStepperAddDecisionCriteria;