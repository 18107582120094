import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid, Button, Card, CardContent, createStyles, makeStyles, Theme, TextField } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { GetAccountTrialExpirationDateDTO, GetUserDTO, GET_ACCOUNT_TRIAL_EXPIRATION_DATE, GET_USER } from 'src/operations';
import LoadingIndicator from '../LoadingIndicator';
import InputMask from 'react-input-mask';
import { AddPaymentMethodDto, ADD_PAYMENT_METHOD } from 'src/operations/mutations/account/addPaymentMethod';

interface ITrialAccountNotificationProps {
    userId: string;
}
export const TrialAccountNotification: FunctionComponent<ITrialAccountNotificationProps> = props => {
    let { logout, isLoading } = useAuth0();
    const { data, loading: isLoadingUser } = useQuery<GetUserDTO>(GET_USER, {
        variables: {
            userId: props.userId,
            skip: isLoading
        }
    });
    const [getAccountTrialExpirationDate, { data: accountTrialExpiration, loading: isLoadingAccountInfo }] = useLazyQuery<GetAccountTrialExpirationDateDTO>(GET_ACCOUNT_TRIAL_EXPIRATION_DATE, {});
    const [isInitialLoadComplete, setIsInitialLoadComplete] = useState<boolean>(false);
    const [redirectToStripe, setRedirectToStripe] = useState<boolean>(true);
    const [paymentIntent, setPaymentIntent] = useState<any>(undefined);

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            leftCard: {
                height: '400px',
                borderRadius: '4px 0px 0px 4px'
            },
            rightCard: {
                height: '400px',
                borderRadius: '0px 4px 4px 0px'
            },
            cardContent: {
                height: '100%'
            },
            image: {
                height: '164px',
                width: '100%',
                maxWidth: '360px',
            },
            perMonth: {
                width: '20px'
            },
            error: {
                color: 'red'
            }
        })
    );

    useEffect(() => {
        if (isInitialLoadComplete) {
            if ((accountTrialExpiration && !accountTrialExpiration?.account?.trialExpirationDate) || (accountTrialExpiration?.account?.trialExpirationDate && new Date() < new Date(accountTrialExpiration.account.trialExpirationDate))) {
                setRedirectToStripe(false);
            }
        }

        if (!data) {
            return;
        }

        if (!accountTrialExpiration) {
            getAccountTrialExpirationDate({ variables: { accountId: data.user.userMetaData.accountId } });
        }
    }, [getAccountTrialExpirationDate, data, accountTrialExpiration, isInitialLoadComplete, logout, redirectToStripe, paymentIntent, setPaymentIntent]);

    const calculateRemainingTrialDays = () => {
        let currentDate = new Date();
        let expirationDate = new Date(accountTrialExpiration.account.trialExpirationDate);

        let difference = expirationDate.getTime() - currentDate.getTime();
        return (difference / (1000 * 3600 * 24));
    };

    const remainingDays = () => {
        if (accountTrialExpiration?.account?.trialExpirationDate) {
            const difference = Math.floor(calculateRemainingTrialDays());

            if (difference === 0) {
                return '< 1';
            }
            return difference;
        }
        return '???';
    }



    const CheckoutForm = () => {
        const classes = useStyles();
        const [name, setName] = useState('');
        const [number, setNumber] = useState('');
        const [expiry, setExpiry] = useState('');
        const [cvc, setCVC] = useState('');
        const [saveError, setSaveError] = useState(false);
        const [errors, setError] = useState(
            {
                nameError: false,
                numberError: false,
                expError: false,
                cvcError: false
            }
        );
        const [addPaymentMethod] = useMutation<AddPaymentMethodDto>(ADD_PAYMENT_METHOD);


        const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
        }

        const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
            setNumber(event.target.value);
        }

        const handleExpiryDateChange = (event: ChangeEvent<HTMLInputElement>) => {
            setExpiry(event.target.value);
        }

        const handleCVCChange = (event: ChangeEvent<HTMLInputElement>) => {
            setCVC(event.target.value);
        }

        const handleSubmit = async (event: any) => {
            event.preventDefault();

            const exp = expiry.split(' / ');

            setError({
                nameError: name === '',
                numberError: number === '',
                expError: exp.length !== 2,
                cvcError: cvc === ''
            });


            if (errors.nameError || errors.numberError || errors.expError || errors.cvcError) {
                return;
            } else {
                await addPaymentMethod({
                    variables: {
                        paymentMethod: {
                            accountId: data.user.userMetaData.accountId,
                            name,
                            number,
                            expMonth: exp[0],
                            expYear: exp[1],
                            cvc
                        }
                    }
                }).then((result) => {
                    if (result.data.addPaymentMethod === true) {
                        window.location.reload();
                    } else {
                        setSaveError(true);
                    }
                }).catch((error) => {
                    setSaveError(true);
                });
            }
        };

        return (
            <form onSubmit={handleSubmit}>
                <Grid container direction='row' justify='center' style={{ marginTop: '50px' }} wrap='wrap'>
                    <Grid item />
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Card className={classes.leftCard} style={{ backgroundColor: '#eeeeee' }} variant='outlined'>
                            <CardContent className={classes.cardContent}>
                                <Grid container direction='column' justify='center' style={{ padding: '40px' }}>
                                    <Grid item>
                                        <h3 style={{ marginBottom: '-18px' }}>Subscribe to ProductLight</h3>
                                    </Grid>
                                    <Grid item container direction='row' spacing={2}>
                                        <Grid item>
                                            <h1>$49.00</h1>
                                        </Grid>
                                        <Grid item>
                                            <h3 className={classes.perMonth}>per month</h3>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <img src='ProductLight.png' alt='logo' className={classes.image} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Card className={classes.rightCard} variant='outlined'>
                            <CardContent className={classes.cardContent}>
                                <Grid container direction='column' spacing={3} justify='space-between' className={classes.cardContent}>
                                    <Grid item container direction='column' spacing={3}>
                                        <Grid item>
                                            <TextField
                                                label='Name on card'
                                                variant='outlined'
                                                value={name}
                                                fullWidth
                                                required
                                                InputLabelProps={{ shrink: true }}
                                                onChange={handleNameChange}
                                                error={errors.nameError}
                                                helperText={errors.nameError ? 'Name is required' : undefined}
                                            />

                                        </Grid>
                                        <Grid item>
                                            <InputMask mask='9999 9999 9999 9999'
                                                value={number}
                                                onChange={handleNumberChange}
                                                maskPlaceholder={null}>
                                                <TextField
                                                    label='Card Number'
                                                    name='ccnumber'
                                                    variant='outlined'
                                                    required
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder='1234 1234 1234 1234'
                                                    error={errors.numberError}
                                                    helperText={errors.numberError ? 'Card number is required' : undefined}
                                                />
                                            </InputMask>
                                        </Grid>
                                        <Grid item container direction='row' spacing={2}>
                                            <Grid item xs={6}>
                                                <InputMask mask='99 / 99'
                                                    value={expiry}
                                                    onChange={handleExpiryDateChange}
                                                    maskPlaceholder={null}>
                                                    <TextField
                                                        label='Expiry Date'
                                                        name='expiryDate'
                                                        variant='outlined'
                                                        required
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        placeholder='MM / YY'
                                                        error={errors.expError}
                                                        helperText={errors.expError ? 'Expiry date is required' : undefined}
                                                    />
                                                </InputMask>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <InputMask mask='9999'
                                                    value={cvc}
                                                    onChange={handleCVCChange}
                                                    maskPlaceholder={null}>
                                                    <TextField
                                                        label='CVC'
                                                        name='cvcnumber'
                                                        variant='outlined'
                                                        required
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        placeholder='CVC'
                                                        error={errors.cvcError}
                                                        helperText={errors.cvcError ? 'CVC is required' : undefined}
                                                    />
                                                </InputMask>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Button type="submit" variant='contained' color='primary' fullWidth>
                                            Subscribe
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        {saveError ? <span className={classes.error}>An error occurred processing this credit card transaction.</span> : <></>}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item />
                </Grid>
            </form>
        );
    };

    if (!isLoadingUser && !isLoadingAccountInfo && !isInitialLoadComplete && accountTrialExpiration) {
        setIsInitialLoadComplete(true);
        return;
    } else if (!isInitialLoadComplete) {
        return <LoadingIndicator />;
    } else {
        return (
            <>
                {isInitialLoadComplete && !redirectToStripe ?
                    <>
                        {accountTrialExpiration?.account?.trialExpirationDate ? <div style={{ zIndex: 100, position: 'sticky', top: 0, backgroundColor: '#F0E2D1' }}>
                            <Grid container direction='row' spacing={1} justify='center' alignItems='center' alignContent='center' style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                <Grid item >
                                    <span>Your free trial expires in {remainingDays()} day(s)</span>
                                </Grid>
                            </Grid>
                        </div>
                            : <></>}
                        {props.children}
                    </>
                    :
                    <div>
                        <CheckoutForm />
                    </div>
                }</>
        );
    }
}