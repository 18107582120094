import { gql } from "@apollo/client";

export const GET_RESPONSE_GAUGE_DATA = gql`
query getResponseGaugeData($filter: String!) {
  surveyRespondents(filterBy: $filter, pageSize:1000) {
    status
    respondent{
      respondentType {
        description
      }
    }
  }
}
`;

export interface GetResponseGaugeDataDTO {
  surveyRespondents: [
    {
      status: string;
      respondent: {
        respondentType: {
          description: string;
        }
      }
    }
  ];
}
