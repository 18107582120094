import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import _ from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { DecisionCriteriaDTO } from 'src/models';
import theme from 'src/Theme';
import { FormValidationTooltip, SliderWithLabel } from '../core';

export interface IAddEditDecisionCriteriaModalProps {
    otherDecisionCriteria: DecisionCriteriaDTO[];
    createDecisionCriteria: (newDecisionCriteria: DecisionCriteriaDTO, otherDecisionCriteria?: DecisionCriteriaDTO[]) => Promise<void>;
    updateDecisionCriteria: (editedDecisionCriteria: DecisionCriteriaDTO, otherDecisionCriteria?: DecisionCriteriaDTO[]) => Promise<void>;
    deleteDecisionCriteria: (otherDecisionCriteria: DecisionCriteriaDTO[], decisionCriteriaId?: string) => Promise<void>;
    closeModal: () => void;
    existingDecisionCriteria?: DecisionCriteriaDTO;
    isOpen: boolean;
    isEditDisabled?: boolean;
}

function AddEditDecisionCriteriaModal(props: IAddEditDecisionCriteriaModalProps) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [scoringWeight, setScoringWeight] = useState(0);
    const [scoringWeightTotal, setScoringWeightTotal] = useState(0);
    const [isDecisionCriteriaLocked, setIsDecisionCriteriaLocked] = useState(false);
    const [isInDeleteMode, setIsInDeleteMode] = useState(false);
    const {
        otherDecisionCriteria,
        createDecisionCriteria,
        updateDecisionCriteria,
        deleteDecisionCriteria,
        closeModal,
        existingDecisionCriteria,
        isOpen,
        isEditDisabled
    } = props;
    const currentDecisionCriteriaIndex = -1;

    const [localODC, setLocalODC] = useState(otherDecisionCriteria);

    useEffect(() => {
        setLocalODC(otherDecisionCriteria)
    }, [otherDecisionCriteria])

    const handleNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setName(event.target.value)
    }

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDescription(event.target.value)
    }

    const handleSliderChange = (identifier: number, newValue: number) => {
        if (identifier === currentDecisionCriteriaIndex) {
            setScoringWeight(newValue)
        } else {
            localODC[identifier].weight = newValue;
            setLocalODC(_.cloneDeep(localODC))
        }
    };

    const handleSliderDelete = (identifier: number) => {
        if (identifier === currentDecisionCriteriaIndex) {
            toggleDeleteMode()
        } else {
            localODC.splice(identifier, 1);
            setLocalODC(_.cloneDeep(localODC))
            if (isInDeleteMode && localODC.length === 0) {
                handleDeleteDecisionCriteria();
            }
        }
    };

    const isFormValid = () => {
        const isCurrentDCScoringWeightValid = scoringWeight > 0 && scoringWeight <= 100
        const areAllOtherScoringWeightsValid = localODC.every((dc) => {
            return (dc.weight > 0 && dc.weight < 100) || dc.isLocked
        })

        const areAllScoringWeightsValid = areAllOtherScoringWeightsValid && isCurrentDCScoringWeightValid;

        if (isInDeleteMode) {
            return scoringWeightTotal === 100 && areAllScoringWeightsValid;
        } else {
            return !!name && scoringWeightTotal === 100 && areAllScoringWeightsValid;
        }
    }

    const handleAddDecisionCriteria = () => {
        const newDecisionCriteria: DecisionCriteriaDTO = {
            name: name,
            description: description,
            weight: scoringWeight,
        };
        createDecisionCriteria(newDecisionCriteria, localODC);
        closeModal();
    }

    const handleEditDecisionCriteria = () => {
        const editedDecisionCriteria: DecisionCriteriaDTO = {
            ...existingDecisionCriteria,
            name: name,
            description: description,
            weight: scoringWeight,
        }
        updateDecisionCriteria(editedDecisionCriteria, localODC);
        closeModal();
    }

    const handleDeleteDecisionCriteria = () => {
        deleteDecisionCriteria(localODC, existingDecisionCriteria?.id);
        closeModal();
    }

    const toggleDeleteMode = () => {
        if (localODC.length === 0) {
            handleDeleteDecisionCriteria()
        }
        setIsInDeleteMode(!isInDeleteMode)
    }

    useEffect(() => {
        if (existingDecisionCriteria) {
            setName(existingDecisionCriteria.name);
            setDescription(existingDecisionCriteria.description);
            setScoringWeight(existingDecisionCriteria.weight);
            setIsDecisionCriteriaLocked(!!existingDecisionCriteria.isLocked);
        } else {
            setName('');
            setDescription('');
            setScoringWeight(otherDecisionCriteria.length === 0 ? 100 : 1);
            setIsDecisionCriteriaLocked(false);
        }
    }, [isOpen, existingDecisionCriteria, otherDecisionCriteria.length]);

    useEffect(() => {
        if (isOpen) {
            setIsInDeleteMode(false);
        }
    }, [isOpen, existingDecisionCriteria]);

    useEffect(() => {
        let updatedScoringWeightTotal = localODC.map(dc => dc.weight).reduce((total, current) => total + current, 0);
        if (!isInDeleteMode) {
            updatedScoringWeightTotal += scoringWeight;
        }
        setScoringWeightTotal(updatedScoringWeightTotal);
    }, [localODC, scoringWeight, isInDeleteMode])

    const handleSubmit = () => {
        if (isInDeleteMode) {
            handleDeleteDecisionCriteria();
        } else if (existingDecisionCriteria) {
            handleEditDecisionCriteria();
        } else {
            handleAddDecisionCriteria();
        }
    }

    return (
        <Dialog open={isOpen} onClose={closeModal} maxWidth="md" fullWidth>
            <DialogTitle style={{ paddingBottom: '0', fontWeight: 'bold' }}>
                {existingDecisionCriteria ? 'Edit' : 'Add'} Desired Outcome
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' style={{ minWidth: '400px' }}>
                    {
                        !isInDeleteMode && (
                            <>
                                <TextField
                                    InputProps={{
                                        readOnly: isEditDisabled || isDecisionCriteriaLocked,
                                    }}
                                    label='Name'
                                    value={name}
                                    style={{ marginBottom: theme.spacing(2) }}
                                    onChange={handleNameChange}
                                    required
                                />
                                <TextField
                                    InputProps={{
                                        readOnly: isEditDisabled || isDecisionCriteriaLocked,
                                    }}
                                    label='Description'
                                    value={description}
                                    style={{ marginBottom: theme.spacing(3) }}
                                    onChange={handleDescriptionChange}
                                />
                                <SliderWithLabel
                                    label={name || 'Name'}
                                    value={scoringWeight}
                                    identifier={currentDecisionCriteriaIndex}
                                    onChange={handleSliderChange}
                                    onDelete={handleSliderDelete}
                                    isEditDisabled={isEditDisabled || isDecisionCriteriaLocked}
                                />
                            </>
                        )
                    }
                    {localODC && localODC.map((odc, index) => {
                        return (
                            <SliderWithLabel
                                key={`Weight-Slider-${index}`}
                                label={odc.name}
                                value={odc.weight}
                                identifier={index}
                                onChange={handleSliderChange}
                                onDelete={handleSliderDelete}
                                isEditDisabled={isEditDisabled || odc.isLocked}
                            />
                        )
                    })}
                </Grid>
            </DialogContent>
            <Grid style={{ marginLeft: 'auto', marginRight: theme.spacing(3), color: scoringWeightTotal === 100 ? theme.palette.text.primary : '#B91313' }}>
                <Typography>Scoring Weight: {scoringWeightTotal}%</Typography>
            </Grid>
            <DialogActions style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(1) }}>

                {
                    isEditDisabled || isDecisionCriteriaLocked ?
                        <Button color="primary" variant="outlined" onClick={closeModal}>
                            Close
                        </Button>
                        :
                        <>
                            <Button color="primary" variant="outlined" onClick={closeModal}>
                                Cancel
                            </Button>
                            <FormValidationTooltip
                                isVisible={!isFormValid()}
                                alternateText="Desired outcome weights must total to 100% before continuing"
                                shouldShowAlternateText={scoringWeightTotal !== 100}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{ marginLeft: theme.spacing(2) }}
                                    disabled={!isFormValid()}
                                    onClick={handleSubmit}
                                >
                                    {existingDecisionCriteria ? 'Update' : 'Add'}
                                </Button>
                            </FormValidationTooltip>
                        </>
                }
            </DialogActions>
        </Dialog>

    )
}

export default AddEditDecisionCriteriaModal