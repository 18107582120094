import { gql } from '@apollo/client';

export const UPDATE_RESPONDENT = gql`
    mutation updateRespondent($respondent: RespondentInput!, $respondentId: String!) {
        updateRespondent(input: $respondent, id: $respondentId) {
            id
        }
    }
`

export interface UpdateRespondentDTO {
    updateRespondent: {
        id: string;
    }
}