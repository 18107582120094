import { gql } from "@apollo/client";

export const GET_OVERALL_PRIORITIZATION_DATA = gql`
query getSurveyOverallPrioritization($surveyId:String!){
  overall:surveyRanking(surveyId:$surveyId) {
    blendedRankings{
      rank
      featureName
    }
    decisionCriteriaRankings{
      decisionCriteria{
        id
        name
        survey{
          id
        }
      }
      blendedRankings{
        featureName
        rank
      }
      respondentRankings{
        respondent{
          id
          respondentTypeId
          respondentType {
            description
          }
          departmentId
          department {
            name
          }
        }
        featureRankings{
          featureName
          rank
        }
      }
    }
  }
}
`;

export interface GetOverallPrioritizationDataDTO {
  overall: {
    blendedRankings: [{
      rank: number;
      featureName: string;
    }]
    decisionCriteriaRankings: [{
      decisionCriteria: {
        id: string;
        name: string;
      }
      blendedRankings: [{
        featureName: string;
        rank: number;
      }]
      respondentRankings: [{
        respondent: {
          id: string;
          respondentTypeId: string;
          respondentType: {
            description: string;
          }
          departmentId?: string;
          department?: {
            name: string;
          }
        }
        featureRankings: [{
          featureName: string;
          rank: number;
        }]
      }]
    }]
  }
}
