import { Chip, Grid, Slider, withStyles } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { SurveyResponseDTO } from 'src/models';
import theme from 'src/Theme';
import { SliderDots } from './SliderDots';

interface ITakeSurveyDecisionCriteriaProps {
    response?: Partial<SurveyResponseDTO>;
    updateSurveyResponse: (updatedSurveyResponse: Partial<SurveyResponseDTO>) => void
}

const StyledSlider = withStyles({
    rail: {
        height: 4,
        backgroundColor: '#d0d0d0'
    },
    track: {
        height: 4,
        zIndex: 3,
    },
    thumb: {
        width: 56,
        height: 56,
        marginTop: -26,
        marginLeft: -28,
        backgroundColor: 'white',
        border: `2px solid ${theme.palette.primary.main}`,
        zIndex: 3,
    }
})(Slider);

export const TakeSurveyDecisionCriteria: FunctionComponent<ITakeSurveyDecisionCriteriaProps> = props => {
    const {
        response,
        updateSurveyResponse
    } = props;
    const [sliderValue, setSliderValue] = useState<number>(0)

    const handleSliderChange = (event: any, newValue: number | number[]) => {
        setSliderValue(typeof newValue === 'number' ? newValue : 0);
    };

    const handleSliderChangeCommitted = (event: any, newValue: number | number[]) => {
        let ranking = newValue as number + 1;

        if (ranking < 1) {
            ranking = Math.abs(Math.pow(ranking - 2, -1));
        } else if (ranking === 0) {
            ranking = 0.5
        }

        const updatedResponse: Partial<SurveyResponseDTO> = {
            ...response,
            ranking: Math.pow(ranking, -1),
        }
        updateSurveyResponse(updatedResponse)
    }

    useEffect(() => {
        if (!response) {
            setSliderValue(0);
            return;
        }
        let convertedSliderValue = 0;
        const ranking = response.ranking;
        if (ranking < 1) {
            convertedSliderValue = (Math.pow(ranking, -1) - 2) * -1
        } else {
            convertedSliderValue = ranking;
        }
        const result = convertedSliderValue - 1
        setSliderValue(result * -1);
    }, [response]);

    return (
        <Grid container item direction="column" alignItems='stretch'>
            <Grid item container direction='row' justify='space-between'>
                <Grid item container direction='row' justify='center' xs={5}>
                    { sliderValue < 0 && (
                        <Chip
                            label={`+${Math.abs(sliderValue)} More Important`}
                            size='small'
                        />
                    )}
                </Grid>
                <Grid item container direction='row' justify='center' xs={2}>
                    { sliderValue === 0 && (
                        <Chip
                            label="They're Equal"
                            size='small'
                        />
                    )}
                </Grid>
                <Grid item container direction='row' justify='center' xs={5}>
                    { sliderValue > 0 && (
                        <Chip
                            label={`+${sliderValue} More Important`}
                            size='small'
                        />
                    )}
                </Grid>
            </Grid>
            <Grid item style={{ margin: '16px 16px' }}>
                <StyledSlider
                    min={-8}
                    max={8}
                    track={false}
                    value={typeof sliderValue === 'number' ? sliderValue : 0}
                    onChange={handleSliderChange}
                    onChangeCommitted={handleSliderChangeCommitted}
                />
                <SliderDots />
            </Grid>
        </Grid>
    )
}