import React, { useState, ChangeEvent } from 'react';
import { Grid, Button, Box, TextField } from '@material-ui/core';
import PageHeaderCard from "src/Components/core/PageHeaderCard";
import { FeedbackRounded } from '@material-ui/icons';
import { FormValidationTooltip } from '../core';
import { useMutation } from '@apollo/client';
import { SEND_FEEDBACK_EMAIL } from 'src/operations';
import { useSnackbar } from 'notistack';

export function Feedback() {
    const [isLoading, setIsLoading] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [shouldHideTextArea, setShouldHideTextArea] = useState(false);
    const [sendFeedbackEmail] = useMutation(SEND_FEEDBACK_EMAIL);
    const { enqueueSnackbar } = useSnackbar();

    const handleFeedbackChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFeedback(event.target.value)
    }

    const handleClick = async () => {
        setIsLoading(true)
        const feedbackInput = {
            message: feedback
        }
        const isSuccessful = await sendFeedbackEmail({
            variables: {
                feedback: feedbackInput
            }
        })
        if (isSuccessful) {
            setShouldHideTextArea(true)
        } else {
            enqueueSnackbar('An error occurred while attempting to send feedback', { variant: 'error' });
        }
        setIsLoading(false)
    }

    const isFormValid = () => {
        return !!feedback
    }

    return (
        <Box m={2} border={1} borderRadius={7} bgcolor={'#ffffff'}>
            <Grid container direction="row" style={{ marginLeft: 15, marginTop: 15 }}>
                <PageHeaderCard title="Send Feedback" icon={<FeedbackRounded />} />
            </Grid>

            {
                shouldHideTextArea ? (
                    <Box m={2} width="95%" pl={2} fontSize= {20} visibility={shouldHideTextArea ? "visible" : "hidden"}>
                        We've got it! Thanks for sharing your feedback with us!
                    </Box>
                ) : (
                    <Box m={1} width="100%" visibility={shouldHideTextArea ? "hidden" : "visible"}>
                        <Grid container direction="column" alignItems="center" justify="center">
                            <Box width="95%" marginRight={2} mb={2}>
                                <Box fontSize= {20}>
                                Thanks for sharing your feedback with us. No detail is too small!
                                </Box>
                                <TextField
                                    fullWidth
                                    multiline
                                    autoFocus
                                    required
                                    value={feedback}
                                    onChange={handleFeedbackChange}
                                    rows={4}
                                    margin="normal"
                                    variant="outlined">
                                </TextField>
                            </Box>

                            <FormValidationTooltip isVisible={!isFormValid() || isLoading} >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!isFormValid()}
                                    onClick={handleClick}
                                    size="large"
                                >
                                    SEND FEEDBACK
                                </Button>
                            </FormValidationTooltip>
                        </Grid>
                    </Box>
                )
            }
        </Box>
    );

}

export default Feedback;