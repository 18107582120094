import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { GetCustomerBlendedAverageDTO } from 'src/operations';

interface ICustomerBlendedAverageProps {
    chartData: GetCustomerBlendedAverageDTO;
}

export const CustomerBlendedAverage: FunctionComponent<ICustomerBlendedAverageProps> = props => {
    const {
        chartData,
    } = props;
    const [featureNames, setFeatureNames] = useState([] as string[]);
    const [chartBars, setChartBars] = useState([] as Highcharts.SeriesOptionsType[]);

    useEffect(() => {
        const orderedRankings = _.cloneDeep(chartData.surveyRanking?.decisionCriteriaRankings[0]?.blendedRankings);
        orderedRankings.sort((x, y) => y.rank - x.rank);

        const featureList = orderedRankings.map(x => x.featureName)
        setFeatureNames(featureList);

        const bars: Highcharts.SeriesBarOptions[] = [];
        const newBar: Highcharts.SeriesBarOptions = {
            type: 'bar',
            data: orderedRankings.map(br => [br.featureName, br.rank])
        }
        bars.push(newBar);

        setChartBars(bars);
    }, [chartData]);

    const options: Highcharts.Options = {
        plotOptions: {
            bar: {
                groupPadding: 0,
            }
        },
        chart: {
            type: 'bar'
        },
        title: {
            text: 'Blended Average',
            align: 'left'
        },
        legend: {
            enabled: false
        },
        yAxis: {
            title: {
                text: null,
            },
            labels: {
                enabled: false,
            },
        },
        xAxis: {
            categories: featureNames,
        },
        series: chartBars,
        tooltip: {
            formatter: function () {
                return `${this.y.toFixed(2)}`
            }
        },
        credits: {
            enabled: false,
        },
    }

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                {...props}
            />
        </>
    )
}