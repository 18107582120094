import * as React from "react";
import { FunctionComponent } from 'react';
import { Users } from 'src/Components';
import { BasePage } from "src/Components/core/BasePage";

export const UsersView: FunctionComponent = () => {
  return (
    <BasePage>
      <Users />
    </BasePage>
  );
}