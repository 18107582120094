import React, { FunctionComponent, useState } from 'react';
import PageHeaderCard from "src/Components/core/PageHeaderCard";
import { AccountBox } from '@material-ui/icons';
import { AccountForm } from './AccountForm';
import { UserDTO } from 'src/models';
import { ConfirmationModal } from '../core';

interface IAccountPageProps {
    user: UserDTO;
    onUpdateUser: (updatedUser: UserDTO) => Promise<void>;
    onChangePassword: () => Promise<void>;
    onCancelAccount: () => Promise<void>;
}

export const AccountPage: FunctionComponent<IAccountPageProps> = props => {
    const { user, onUpdateUser, onChangePassword, onCancelAccount } = props;
    const [isChangePasswordConfirmModalOpen, setIsChangePasswordConfirmModalOpen] = useState(false);
    const [isCancelAccountConfirmModalOpen, setIsCancelAccountConfirmModalOpen] = useState(false);

    const handleChangePasswordClicked = () => {
        setIsChangePasswordConfirmModalOpen(true);
    }

    const handleCloseChangePasswordConfirmationModal = () => {
        setIsChangePasswordConfirmModalOpen(false);
    }

    const handleCancelAccountClicked = () => {
        setIsCancelAccountConfirmModalOpen(true);
    }

    const handleCloseCancelAccountConfirmationModal = () => {
        setIsCancelAccountConfirmModalOpen(false);
    }

    return (
        <>
            <PageHeaderCard title="Account" iconTitleUniform icon={<AccountBox color='primary' />} />
            <AccountForm
                existingUser={user}
                onUpdateUser={onUpdateUser}
                onChangePassword={handleChangePasswordClicked}
                onCancelAccount={handleCancelAccountClicked}
            />
            <ConfirmationModal
                onActionConfirmed={onChangePassword}
                isOpen={isChangePasswordConfirmModalOpen}
                onClose={handleCloseChangePasswordConfirmationModal}
                message={`Are you sure you would like to send a change password request to ${user.email}?`}
            />
            <ConfirmationModal
                onActionConfirmed={onCancelAccount}
                isOpen={isCancelAccountConfirmModalOpen}
                onClose={handleCloseCancelAccountConfirmationModal}
                message={`Are you sure you would like to cancel this account? This will block your account along with any other user's account who is linked to this account.`}
            />
        </>
    );

}

export default AccountPage;