import { Button, Divider, Tab, Tabs } from "@material-ui/core";
import { ArrowBackIos } from '@material-ui/icons';
import React, { FunctionComponent, useState } from "react";
import PageHeaderCard from "src/Components/core/PageHeaderCard";
import { SurveyResultsDetails } from './SurveyResultsDetails';
import { SurveyResultsSummary } from './SurveyResultsSummary';

interface ISurveyResultsProps {
    surveyId: string;
    surveyName: string;
}

export const SurveyResults: FunctionComponent<ISurveyResultsProps> = props => {
    const {surveyId, surveyName} = props;
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
        setCurrentTabIndex(newTabIndex);
    }
    return (
        <>
            <Button
            startIcon={<ArrowBackIos style={{ fontSize: "14px" }} />}
            href={`/SurveyDetails/${surveyId}`}
            color="primary"
            >
            {surveyName}
            </Button>
            <PageHeaderCard
                title="Results"
            />
            <Tabs
                value={currentTabIndex}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
            >
                <Tab label="Summary" style={{textTransform: 'none'}} />
                <Tab label='Details' style={{textTransform: 'none'}} />
            </Tabs>
            <Divider variant="middle" />
            {currentTabIndex === 0 && (
                <SurveyResultsSummary
                    surveyId={surveyId}

                />
            )}
            {currentTabIndex === 1 && (
                <SurveyResultsDetails
                    surveyId={surveyId}

                />
            )}
        </>
    );
}