import { gql } from '@apollo/client';

export const CREATE_SURVEY_RESPONDENT = gql`
    mutation createSurveyRespondent($surveyRespondent: SurveyRespondentInput!) {
        createSurveyRespondent(input: $surveyRespondent) {
            id
        }
    }
`

export interface CreateSurveyRespondentDTO {
    createSurveyRespondent: {
        id: string;
    }
}