import React, { useState, ChangeEvent, useEffect, FunctionComponent } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, TextField, DialogActions, Button, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import theme from 'src/Theme';
import { RespondentDTO } from 'src/models';
import { DeleteButtonWithConfirmation, FormValidationTooltip } from 'src/Components';
import { validateEmail } from 'src/Utils';

export interface IAddEditRespondentCustomerModalProps {
    closeModal: () => void;
    createRespondentCustomer: (newRespondentCustomer: RespondentDTO) => Promise<void>;
    updateRespondentCustomer: (editedRespondentCustomer: RespondentDTO, respondentCustomerId: string) => Promise<void>;
    deleteRespondentCustomer: (respondentCustomerId: string) => Promise<void>;
    existingRespondentCustomer?: RespondentDTO;
    isOpen: boolean;
    isEditDisabled?: boolean;
    isSMSEnabled?: boolean;
}

export const AddEditRespondentCustomerModal: FunctionComponent<IAddEditRespondentCustomerModalProps> = props => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [communicationPreference, setCommunicationPreference] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {
        closeModal,
        createRespondentCustomer,
        updateRespondentCustomer,
        deleteRespondentCustomer,
        existingRespondentCustomer,
        isOpen,
        isEditDisabled,
        isSMSEnabled,
    } = props;

    const handleNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFirstName(event.target.value)
    }

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLastName(event.target.value)
    }

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmail(event.target.value)
    }

    const handlePhoneChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPhone(event.target.value)
    }

    const handleCommunicationPreferenceChange = (event: ChangeEvent<{value: unknown;}>) => {
        setCommunicationPreference(event.target.value as string)
    }

    const isFormValid = () => {
        const isBasicInfoValid = !!firstName && !!lastName && !!communicationPreference;
        let isConditionalInfoValid = false;
        switch (communicationPreference) {
            case 'Email':
                isConditionalInfoValid = !!email && validateEmail(email);
                break;
            case 'Email and SMS':
            case 'SMS':
                isConditionalInfoValid = !!email && validateEmail(email) && !!phone;
                break;
        }

        return isBasicInfoValid && isConditionalInfoValid;
    }

    const handleAddRespondentCustomer = () => {
        const customerRespondentTypeId = '5252a895-4d90-443f-8912-71be51c60910';

        const newRespondentCustomer: RespondentDTO = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            mobilePhone: phone,
            communicationPreference: communicationPreference,
            respondentTypeId: customerRespondentTypeId,
        };

        setIsLoading(true);
        createRespondentCustomer(newRespondentCustomer);
        closeModal();
    }

    const handleEditRespondentCustomer = () => {
        const editedRespondentCustomer: RespondentDTO = {
            ...existingRespondentCustomer,
            firstName: firstName,
            lastName: lastName,
            email: email,
            mobilePhone: phone,
            communicationPreference: communicationPreference,
        }
        delete editedRespondentCustomer.id;
        delete editedRespondentCustomer.__typename;

        setIsLoading(true);
        updateRespondentCustomer(editedRespondentCustomer, existingRespondentCustomer.id);
        closeModal();
    }

    const handleDeleteRespondentCustomer = () => {
        setIsLoading(true);
        deleteRespondentCustomer(existingRespondentCustomer.id);
        closeModal();
    }

    useEffect(() => {
        if (existingRespondentCustomer) {
            setFirstName(existingRespondentCustomer.firstName);
            setLastName(existingRespondentCustomer.lastName);
            setEmail(existingRespondentCustomer.email);
            setPhone(existingRespondentCustomer.mobilePhone);
            setCommunicationPreference(existingRespondentCustomer.communicationPreference || 'Email');
        } else {
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setCommunicationPreference('Email');
        }
    }, [isOpen, existingRespondentCustomer]);

    return (
        <Dialog open={isOpen} onClose={closeModal}>
            <DialogTitle style={{ paddingBottom: '0', fontWeight: 'bold' }}>
                {existingRespondentCustomer ? 'Edit' : 'Add'} Customer
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' style={{ minWidth: '400px' }}>
                    <Grid item container direction="row">
                        <Grid item xs={6}>
                            <TextField
                                InputProps={{
                                    readOnly: isEditDisabled,
                                }}
                                label='First Name'
                                value={firstName}
                                style={{ marginBottom: theme.spacing(1) }}
                                onChange={handleNameChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                InputProps={{
                                    readOnly: isEditDisabled,
                                }}
                                label='Last Name'
                                value={lastName}
                                style={{ marginBottom: theme.spacing(1) }}
                                onChange={handleDescriptionChange}
                                required
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        InputProps={{
                            readOnly: isEditDisabled,
                        }}
                        label='Email Address'
                        value={email}
                        style={{ marginBottom: theme.spacing(1) }}
                        onChange={handleEmailChange}
                        required
                        error={!!email && !validateEmail(email)}
                        helperText={!!email && !validateEmail(email) ? 'Must be a valid email address' : ''}
                    />
                    <TextField
                        InputProps={{
                            readOnly: isEditDisabled,
                        }}
                        label='Mobile Phone'
                        value={phone}
                        style={{ marginBottom: theme.spacing(3) }}
                        onChange={handlePhoneChange}
                        required={['SMS', 'Email and SMS'].includes(communicationPreference)}
                    />
                    <FormControl>
                        <InputLabel id='communication-preference-select'>Communication Preference</InputLabel>
                        <Select
                            readOnly={isEditDisabled}
                            labelId='communication-preference-select'
                            id='communication-preference-select'
                            value={communicationPreference}
                            style={{ marginBottom: theme.spacing(1) }}
                            onChange={handleCommunicationPreferenceChange}
                        >
                            <MenuItem value={'SMS'} disabled={!isSMSEnabled}>SMS</MenuItem>
                            <MenuItem value={'Email'}>Email</MenuItem>
                            <MenuItem value={'Email and SMS'} disabled={!isSMSEnabled}>Email and SMS</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(1) }}>
                {
                    existingRespondentCustomer && !isEditDisabled &&
                    <DeleteButtonWithConfirmation onDelete={handleDeleteRespondentCustomer} itemName="customer" />
                }
                {
                    isEditDisabled ?
                        <Button color="primary" variant="outlined" onClick={closeModal}>
                            Close
                        </Button>
                    :
                        <>
                            <Button color="primary" variant="outlined" onClick={closeModal}>
                                Cancel
                            </Button>
                            <FormValidationTooltip isVisible={!isFormValid() && !isLoading} >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{ marginLeft: theme.spacing(2) }}
                                    disabled={!isFormValid()}
                                    onClick={existingRespondentCustomer ? handleEditRespondentCustomer : handleAddRespondentCustomer}
                                >
                                    {existingRespondentCustomer ? 'Update' : 'Add'}
                                </Button>
                            </FormValidationTooltip>
                        </>
                }
            </DialogActions>
        </Dialog>
    )
}