import { ApolloQueryResult } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { CellParams, CellValue, ColDef, ValueFormatterParams, ValueGetterParams } from '@material-ui/data-grid';
import { Person } from '@material-ui/icons';
import React, { FunctionComponent, useState } from "react";
import { AddEditUserModalContainer } from 'src/containers';
import { GetUsersDTO } from 'src/operations';
import theme from 'src/Theme';
import { ESDataTable, ESDataTableHeader } from '../core';

interface ISurveyResultsProps {
    userQueryData: GetUsersDTO;
    reloadData: () => Promise<ApolloQueryResult<GetUsersDTO>>
}

const pagePaddingOffset = theme.spacing(11);
const menuColumnOffset = theme.spacing(6) + 264;
const totalOffset = pagePaddingOffset + menuColumnOffset;

export const UserDataTable: FunctionComponent<ISurveyResultsProps> = props => {
    const {
        userQueryData,
        reloadData
    } = props;
    const [pageWidth, setPageWidth] = useState(window.innerWidth - totalOffset);
    const [isLoading, setIsLoading] = useState(false);
    const [isAddEditUserModalOpen, setIsAddEditUserModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState('');

    const handleResize = () => {
        setPageWidth(window.innerWidth - totalOffset);
    }

    window.addEventListener('resize', handleResize)

    const columnHeader: ColDef[] = [
        {
            headerName: 'First Name',
            field: 'userMetaData.firstname',
            width: pageWidth * .15,
            valueGetter: (params: ValueGetterParams) =>
                `${params.row.userMetaData?.firstname || ''}`,
            sortComparator: (v1: CellValue, v2: CellValue, cellParams1: CellParams, cellParams2: CellParams) => {
                const name1 = cellParams1.row?.userMetaData?.firstname as string || '';
                const name2 = cellParams2.row?.userMetaData?.firstname as string || '';

                return name1.toUpperCase() > name2.toUpperCase() ? 1 : -1
            }
        },
        {
            headerName: 'Last Name',
            field: 'userMetaData.lastname',
            width: pageWidth * .15,
            valueGetter: (params: ValueGetterParams) =>
                `${params.row.userMetaData?.lastname || ''}`,
            sortComparator: (v1: CellValue, v2: CellValue, cellParams1: CellParams, cellParams2: CellParams) => {
                const name1 = cellParams1.row?.userMetaData?.lastname as string || '';
                const name2 = cellParams2.row?.userMetaData?.lastname as string || '';

                return name1.toUpperCase() > name2.toUpperCase() ? 1 : -1
            }
        },
        {
            headerName: 'Email',
            field: 'email',
            width: pageWidth * .3
        },
        {
            headerName: 'Roles',
            field: 'roles',
            width: pageWidth * .3,
            valueFormatter: (params: ValueFormatterParams) => {
                const roles = params.value as any[];
                return roles.map((role, index) => index > 0 ? ` ${role.name}` : role.name)
            },
            sortable: false
        },
        {
            headerName: 'Status',
            field: 'blocked',
            width: pageWidth * .1,
            valueFormatter: (params: ValueFormatterParams) => {
                return !!params.value ? 'Blocked' : ' ';
            }
        },
    ]

    const closeAddEditUserModal = () => {
        setIsAddEditUserModalOpen(false);
        setSelectedUserId('');
    }

    const handleEdit = async (userId: string) => {
        setIsLoading(true);
        setSelectedUserId(userId);
        setIsAddEditUserModalOpen(true);
        setIsLoading(false);
    }

    const handleAddClicked = async () => {
        setIsAddEditUserModalOpen(true);
    }

    const handleReloadData = async () => {
        setIsLoading(true);
        await reloadData();
        setIsLoading(false);
    }

    return (
        <Grid container direction="column" style={{ paddingBottom: 40 }}>
            <ESDataTableHeader
                icon={<Person />}
                title="Users"
                onAddClicked={handleAddClicked}
            />
            <ESDataTable
                data={userQueryData.users}
                columnHeaders={columnHeader}
                onEdit={handleEdit}
                isLoading={isLoading}
            />
            <AddEditUserModalContainer
                isOpen={isAddEditUserModalOpen}
                userId={selectedUserId}
                closeModal={closeAddEditUserModal}
                reloadUsers={handleReloadData}
            />
        </Grid>
    );
}