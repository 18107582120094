import { Button, Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { TakeSurveyResultsContainer } from 'src/containers';
import { DecisionCriteriaDTO, SurveyFeatureDTO, SurveyRespondentStatusOptions } from 'src/models';
import { GetTakeSurveyDataSurveyDTO } from 'src/operations';
import theme from 'src/Theme';
import { isBlank } from 'src/Utils';
import { WYSIWYGEditor } from '../core';
import { BasicCardList, ExpandableCardList, IExpandableCardProps } from '../core/Cards';
import { IBasicCardProps } from '../core/Cards/BasicCard';

interface ITakeSurveyDetailsProps {
    onTakeSurveyClicked: () => void;
    surveyDetails: GetTakeSurveyDataSurveyDTO;
    surveyRespondentStatus: SurveyRespondentStatusOptions;
    respondentId: string;
    respondentType: string;
}

export const TakeSurveyDetails: FunctionComponent<ITakeSurveyDetailsProps> = props => {
    const {
        onTakeSurveyClicked,
        surveyDetails,
        surveyRespondentStatus,
        respondentId,
        respondentType
    } = props
    const [isTakePollEnabled, setIsTakePollEnabled] = useState(false);

    useEffect(() => {
        const threshold = 100;
        if (isTakePollEnabled) {
            return;
        }
        if (window.innerHeight >= (document.body.offsetHeight - threshold)) {
            setIsTakePollEnabled(true);
        }
        let isRequestingFrame = false;

        const handleScroll = () => {
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - threshold)) {
                setIsTakePollEnabled(true);
            }
            isRequestingFrame = false;
        };

        const onScroll = () => {
            if (!isRequestingFrame) {
                window.requestAnimationFrame(handleScroll);
                isRequestingFrame = true;
            }
    };

    window.addEventListener("scroll", onScroll);
    window.addEventListener("touchmove", onScroll);
    return () => {
        window.removeEventListener("scroll", onScroll)
        window.addEventListener("touchmove", onScroll);
    };
    }, [isTakePollEnabled]);

    const mapFeatureToExpandableCard = (item: Partial<SurveyFeatureDTO>) => {
        const cardData: IExpandableCardProps = {
            title: item.name,
            subtitle: item.description,
            expandedContent: item.details && !isBlank(item.details) ? (
                <WYSIWYGEditor
                    value={item.details}
                    isReadOnly={true}
                />
            ) : null
        }
        return cardData;
    }

    const mapDecisionCriteriaToExpandableCard = (item: Partial<DecisionCriteriaDTO>) => {
        const cardData: IBasicCardProps = {
            title: item.name,
            bodyContent: item.description,
        }
        return cardData;
    }

    const filterOutCustomerDecisionCriteria = (dc: Partial<DecisionCriteriaDTO>) => { return !dc.isLocked };

    return (
        <Grid container direction='column' style={{ marginTop: theme.spacing(2) }}>
            <Grid>
                <Typography variant="body2" style={{ color: theme.palette.primary.main, padding: `${theme.spacing(2)}px 0px`, display: 'none' }} >
                    { surveyDetails.projectName }
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h4">
                    { surveyDetails.name }
                </Typography>
            </Grid>
            {surveyDetails.description ? <Grid item style={{ marginBottom: theme.spacing(2) }}>
                <Typography variant="body1">
                    {surveyDetails.description}
                </Typography>
            </Grid> : <></>}
            <ExpandableCardList title="Features" cardDetails={surveyDetails.features.map(mapFeatureToExpandableCard)}/>
            {respondentType === 'Stakeholder' && (
                <>
                    <BasicCardList title="Desired Outcomes" cardDetails={surveyDetails.decisionCriteria.filter(filterOutCustomerDecisionCriteria).map(mapDecisionCriteriaToExpandableCard)}/>
                </>
            )}
            <div style={{ height: 80 }}></div>
            { surveyRespondentStatus === SurveyRespondentStatusOptions.completed ? (
                <TakeSurveyResultsContainer surveyId={surveyDetails.id} respondentId={respondentId} />
            ) : (
                <Grid container direction='row' style={{ position: 'fixed', bottom: 24, marginLeft: -16 }}>
                    <Button
                        variant='contained'
                        color='primary'
                        size='large'
                        style={{ padding: '24px 0', margin: '0 16px'}}
                        fullWidth
                        onClick={onTakeSurveyClicked}
                        disabled={surveyDetails.isLocked || !isTakePollEnabled}
                    >
                        Take Poll
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}