import { gql } from '@apollo/client';

export const ADD_PAYMENT_METHOD = gql`
    mutation addPaymentMethod($paymentMethod: PaymentMethodInput!) {
        addPaymentMethod(paymentMethod: $paymentMethod)
    }
`

export interface AddPaymentMethodDto {
    addPaymentMethod: boolean
}