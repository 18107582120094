import { useQuery } from "@apollo/client";
import React, { FunctionComponent } from "react";
import { useParams } from 'react-router-dom';
import { GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import { DepartmentPrioritization } from 'src/Components/Graphs';
import { GetDepartmentPrioritizationDataDTO, GET_DEPARTMENT_PRIORITIZATION_DATA } from "src/operations";

interface IDepartmentPrioritizationContainerProps {
    surveyId: string;
}

export const DepartmentPrioritizationContainer: FunctionComponent<IDepartmentPrioritizationContainerProps> = () => {
  const { surveyId } = useParams<{ surveyId: string }>();

  const {data, loading: isLoading, error, refetch} = useQuery<GetDepartmentPrioritizationDataDTO>(GET_DEPARTMENT_PRIORITIZATION_DATA, {
    variables: {
      surveyId: surveyId
    },
  });

  const reloadResults = async () => {
    await refetch({
      surveyId: surveyId,
    });
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || data === undefined) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='department graph' />;
  }

  return (
    <DepartmentPrioritization
      chartData={data}
    />
  );
}
