import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
} from "@material-ui/core";
import * as React from "react";
import { DateTime, Duration } from "luxon";
import theme from 'src/Theme';
import { FunctionComponent } from 'react';

interface ISurveyCardProps {
  title: string;
  description: string;
  dateLocked: Date;
  isLocked: boolean;
  isLockingSoon: boolean;
  millsUntilLocked: number;
  projectName: string;
}

const SurveyCard: FunctionComponent<ISurveyCardProps> = ({
  title,
  description,
  dateLocked,
  isLocked,
  isLockingSoon,
  millsUntilLocked,
  projectName,
}) => {
  const dateLockedObject = new Date(dateLocked);
  const lockedDate = DateTime.fromJSDate(dateLockedObject);
  const dateString = lockedDate.toLocaleString(DateTime.DATE_FULL);
  let lockDateString = undefined;

  if (!dateLocked) {
    lockDateString = <Typography />
  } else if (isLocked) {
    lockDateString = (
      <Typography variant="body2" style={{ color: "grey" }}>
        Results locked on {dateString}{" "}
      </Typography>
    );
  } else {
    const hours = Duration.fromMillis(millsUntilLocked).as("hours");
    const days = Duration.fromMillis(millsUntilLocked).as("days");
    const weeks = Duration.fromMillis(millsUntilLocked).as("weeks");
    const months = Duration.fromMillis(millsUntilLocked).as("months");

    let lockingInString = "";
    if (hours <= 24) {
      lockingInString = "" + Math.floor(hours);
      if (hours < 2) {
        lockingInString += " hour";
      } else {
        lockingInString += " hours";
      }
    } else if (days <= 7) {
      lockingInString = "" + Math.floor(days);
      if (days < 2) {
        lockingInString += " day";
      } else {
        lockingInString += " days";
      }
    } else if (weeks <= 4) {
      lockingInString = "" + Math.floor(weeks);
      if (weeks < 2) {
        lockingInString += " week";
      } else {
        lockingInString += " weeks";
      }
    } else {
      lockingInString = "" + Math.floor(months);
      if (months < 2) {
        lockingInString += " month";
      } else {
        lockingInString += " months";
      }
    }

    lockDateString = (
      <>
        <Typography
          variant="body2"
          display="inline"
          style={{ color: "grey" }}
        >
          Results lock in{" "}
        </Typography>
        <Typography
          variant="body2"
          display="inline"
          style={
            !isLockingSoon
              ? { fontWeight: "bold" }
              : { fontWeight: "bold", color: "red" }
          }
        >
          {lockingInString}{" "}
        </Typography>
        <Typography variant="body2" display="inline" style={{ color: "grey" }}>
          (on {dateString})
        </Typography>
      </>
    );
  }

  return (
    <Box m={1}>
      <Card>
        <CardHeader style={{ backgroundColor: "#355FF6" }} />
        <CardContent>
          <Typography variant="h5" >{title}</Typography>
          <Typography variant="body2" style={{ color: theme.palette.primary.main, marginBottom: theme.spacing(1), display: 'none' }} >{projectName}</Typography>
          <Typography variant="body1" >{description}</Typography>
          <div style={{ marginTop: theme.spacing(1) }}>{lockDateString}</div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SurveyCard;
