import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { GetCustomerPrioritizationDataDTO } from 'src/operations';
import theme from 'src/Theme';

interface ICustomerPrioritizationProps {
    chartData: GetCustomerPrioritizationDataDTO;
}

export const CustomerPrioritization: FunctionComponent<ICustomerPrioritizationProps> = props => {
    const {
        chartData,
    } = props;
    const [featureNames, setFeatureNames] = useState([] as string[]);
    const [chartLines, setChartLines] = useState([] as Highcharts.SeriesOptionsType[]);

    useEffect(() => {
        const customerRankings = chartData.survey.customers && chartData.survey.customers.length > 0 ? chartData.survey.customers[0].surveyRankings : null;
        const overallCustomerAverage = chartData.overall.customerDecisionCriteriaRanking
        if (!customerRankings) {
            return;
        }

        const featureList = overallCustomerAverage.blendedRankings.map(x => x.featureName)
        setFeatureNames(featureList)

        const defaultLineProperties: Highcharts.SeriesOptionsType = {
            type: 'line',
        }
        const lines: Highcharts.SeriesOptionsType[] = []

        const blendedRankingLine = {
            ...defaultLineProperties,
            name: 'Average',
            data: overallCustomerAverage.blendedRankings.map(x => x.rank),
        }
        lines.push(blendedRankingLine);

        customerRankings?.respondentRankings.forEach(rr => {
            const decisionCriteriaLine = {
                ...defaultLineProperties,
                name: `${rr.respondent.firstName} ${rr.respondent.lastName[0]}.`,
                data: rr.featureRankings.map(x => x.rank),
            }
            lines.push(decisionCriteriaLine);
        })

        setChartLines(lines)
    }, [chartData]);

    const options: Highcharts.Options = {
        title: {
            text: 'Overall Average - Customers',
            align: 'left'
        },
        legend: {
            align: 'left',
            verticalAlign: 'top',
            margin: theme.spacing(5),
        },
        yAxis: {
            title: {
                text: null,
            }
        },
        xAxis: {
            categories: featureNames,
        },
        credits: {
            enabled: false,
        },
        series: chartLines,
        tooltip: {
            formatter: function () {
                return `${this.y.toFixed(2)}`
            }
        },
    }

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                {...props}
            />
        </>
    )
}