import { gql } from '@apollo/client';

export const DELETE_SURVEY_DECISION_CRITERIA = gql`
    mutation deleteSurveyDecisionCriteria($surveyDecisionCriteriaId: String!) {
        deleteSurveyDecisionCriteria(id: $surveyDecisionCriteriaId) {
            id
        }
    }
`

export interface DeleteSurveyDecisionCriteriaDTO {
    deleteSurveyDecisionCriteria: {
        id: string;
    }
}