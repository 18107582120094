import { Grid, Card, CardContent } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { BlendedAverageContainer, DecisionCriteriaContainer, OverallPrioritizationContainer, ResponseGaugeContainer, CompletedRespondentsCardContainer } from 'src/containers';
import { SurveyRespondentDTO } from 'src/models';
import theme from 'src/Theme';

interface ISurveyResultsSurveySummaryProps {
    surveyId: string
    surveyRespondents?: Partial<SurveyRespondentDTO>[];
}

export const SurveyResultsSummary: FunctionComponent<ISurveyResultsSurveySummaryProps> = props => {
    const {
        surveyId
    } = props;

    return (
        <Grid container direction="row" alignItems='stretch' style={{ paddingTop: theme.spacing(1) }}>
            <Grid item  xs={4} style={{padding: theme.spacing(1)}}>
                <Card style={{height: '100%'}}>
                    <CardContent>
                        <BlendedAverageContainer surveyId={surveyId} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={8} style={{padding: theme.spacing(1)}}>
                <Card style={{height: '100%'}}>
                    <CardContent>
                        <OverallPrioritizationContainer surveyId={surveyId} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={4} style={{padding: theme.spacing(1)}}>
                <Card style={{height: '100%'}}>
                    <CardContent>
                        <DecisionCriteriaContainer surveyId={surveyId} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={2} style={{padding: theme.spacing(1)}}>
                <Card style={{height: '100%'}}>
                    <CardContent>
                        <ResponseGaugeContainer surveyId={surveyId} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} style={{padding: theme.spacing(1)}}>
                <Card>
                    <CardContent>
                        <CompletedRespondentsCardContainer
                            surveyId={surveyId}
                            isRespondentQuantityVisible={true}
                            isIconVisible={true}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}