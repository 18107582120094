import { gql } from "@apollo/client";

export const GET_ACCOUNT_MAX_FEATURES = gql`
  query accountMaxFeatures($accountId: String!) {
    account(id: $accountId) {
      maxFeatures
    }
  }
`;

export interface GetAccountMaxFeaturesDTO {
  account: {
    maxFeatures: number;
  };
}
