import { gql } from "@apollo/client";

export const GET_BLENDED_AVERAGE_DATA = gql`
query getSurveyBlendedAverage($surveyId:String!){
  surveyRanking(surveyId:$surveyId) {
    blendedRankings{
      rank
      featureName
    }
  }
}
`;

export interface GetBlendedAverageDataDTO {
  surveyRanking: {
    blendedRankings: [{
      rank: number;
      featureName: string;
    }]
  };
}
