import React from 'react';
import LoadingIndicator from 'src/Components/core/LoadingIndicator';
import NavigationDrawer, { NavigationDrawerRouteDTO } from 'src/Components/core/NavigationDrawer';
import { useAuth0 } from '@auth0/auth0-react';
import { AccountBox, Feedback, Folder, Help, Notifications, PeopleAlt, Person, PersonAdd, Poll } from '@material-ui/icons';

const pageRoutes: NavigationDrawerRouteDTO[] = [
  {
    icon: <Poll/>,
    name: 'Polls',
    route: '/Surveys'
  },
  {
    icon: <Folder/>,
    name: 'Projects',
    route: '/Projects',
    isHidden: true,
  },
  {
    icon: <PeopleAlt/>,
    name: 'Customers',
    route: '/Customers',
    isHidden: true,
  }
];

const settingsRoutes: NavigationDrawerRouteDTO[] = [
  {
    icon: <AccountBox/>,
    name: 'Account',
    route: '/Account'
  },
  {
    icon: <Person/>,
    name: 'Users',
    route: '/Users',
    isHidden: true
  },
  {
    icon: <Notifications/>,
    name: 'Notifications',
    isHidden: true
  },
  {
    icon: <Help/>,
    name: 'Support',
    route: '/Support'
  },
  {
    icon: <Feedback/>,
    name: 'Send Feedback',
    route: '/SendFeedback'
  },
  {
    icon: <PersonAdd/>,
    name: 'Invite',
    isHidden: true,
  }
];

export default function Main (props: any) {
  let {
      isLoading,
      error,
      user,
      logout,
    } = useAuth0();

  if (isLoading) {
    return (
      <LoadingIndicator />
    )
  }

  if (error) {
    return (
      <p>{error.message}</p>
    )
  }

  const handleLogout = () => {
    logout({ returnTo: window.location.origin })
  }

  return (
    <NavigationDrawer
      user={user}
      isOpen={props.isOpen}
      routes={pageRoutes}
      settingsRoutes={settingsRoutes}
      handleLogout={handleLogout}
    />
  );
}