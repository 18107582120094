import { gql } from '@apollo/client';

export const CREATE_USER = gql`
    mutation createUser($user: CreateUser!) {
        createUser(input: $user) {
            id
        }
    }
`

export interface CreateUserDTO {
    createUser: {
        id: string;
    }
}