import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { SurveyRespondentListItem } from 'src/Components';
import { RespondentDTO, SurveyRespondentDTO, SurveyRespondentStatusOptions } from 'src/models';
import { SendSurveyRespondentInvitationDTO, SEND_SURVEY_RESPONDENT_INVITATION } from 'src/operations';

export interface ISurveyRespondentListItemContainerProps {
    respondent: Partial<RespondentDTO>;
    surveyRespondents: Partial<SurveyRespondentDTO>[];
    addRespondent: (respondent: Partial<RespondentDTO>) => Promise<void>;
    removeRespondent: (respondentId: string) => Promise<void>;
    onEdit: (respondentId: string, respondentTypeName?: string) => void,
    showClipboard?: boolean;
    isEditDisabled?: boolean;
    isSendInvitationsDisabled?: boolean;
    isSendInvitationsVisible?: boolean;
    isLoading?: boolean;
}

export function SurveyRespondentListItemContainer(props: ISurveyRespondentListItemContainerProps): JSX.Element {
    const [sendSurveyRespondentInvitation] = useMutation<SendSurveyRespondentInvitationDTO>(SEND_SURVEY_RESPONDENT_INVITATION);
    const [isSelected, setIsSelected] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [surveyRespondentId, setSurveyRespondentId] = useState('');
    const {
        respondent,
        surveyRespondents,
        addRespondent,
        removeRespondent,
        onEdit,
        showClipboard,
        isEditDisabled,
        isSendInvitationsDisabled,
        isSendInvitationsVisible,
        isLoading,
    } = props;

    const handleSendSurveyRespondentInvitation = async () => {
        const result = await sendSurveyRespondentInvitation({
            variables: {
                surveyRespondentId: surveyRespondentId
            }
        })
        return result.data
    }

    useEffect(() => {
        const surveyRespondentRecord = surveyRespondents.find(sr => sr.respondentId === respondent.id || sr.respondent?.id === respondent.id)
        const isRespondentSelected = !!surveyRespondentRecord;
        setIsSelected(isRespondentSelected)
        const isRespondentCompleted =  surveyRespondentRecord?.status === SurveyRespondentStatusOptions.completed;
        setIsCompleted(isRespondentCompleted)
        const srId = surveyRespondentRecord?.id
        setSurveyRespondentId(srId)

    }, [surveyRespondents, setIsSelected, setIsCompleted, setSurveyRespondentId, respondent.id]);

    return (
        <SurveyRespondentListItem
            key={respondent.id}
            respondent={respondent}
            isSelected={isSelected}
            isCompleted={isCompleted}
            addRespondent={addRespondent}
            removeRespondent={removeRespondent}
            onEdit={onEdit}
            sendSurveyRespondentInvitation={handleSendSurveyRespondentInvitation}
            showClipboard={showClipboard}
            isEditDisabled={isEditDisabled}
            isSendInvitationsDisabled={isSendInvitationsDisabled}
            isSendInvitationsVisible={isSendInvitationsVisible}
            isLoading={isLoading}
        />
    )
}