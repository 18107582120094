import {
  createStyles,
  Grid,
  Theme,
  Typography,
  TypographyVariant,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { GridDirection, GridItemsAlignment } from "@material-ui/core/Grid";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

const styles = (theme: Theme) =>
  createStyles({
    tableHeader: {
      height: 60,
    },
  });

interface IPageHeaderCardProps extends WithStyles {
  title: string;
  titleComponent?: JSX.Element;
  isTitleComponentPushedRight?: boolean;
  icon?: React.ReactElement<SvgIconProps>;
  iconColor?: string;
  iconTitleUniform?: boolean;
  formGridDirection?: GridDirection;
  formGridAlignItems?: GridItemsAlignment;
  headerStyle?: React.CSSProperties;
  titleVariant?: TypographyVariant;
}

const PageHeaderCard: React.SFC<IPageHeaderCardProps> = ({
  children,
  classes,
  formGridAlignItems = "center",
  formGridDirection = "row",
  icon,
  iconColor,
  iconTitleUniform = false,
  isTitleComponentPushedRight = false,
  title,
  titleComponent,
  headerStyle = {},
  titleVariant
}) => {
  const componentStyles = () => {
    if (isTitleComponentPushedRight) {
      return {
        marginLeft: "auto",
      } as React.CSSProperties;
    } else {
      return undefined;
    }
  };

  const cardHeader = (
    <Grid
      container
      item
      direction="row"
      alignItems="center"
      className={classes.tableHeader}
      xs={12}
      style={{ marginLeft: 0, ...headerStyle }}
    >
      {icon && (
        <SvgIcon color="inherit" style={{ fontSize: iconTitleUniform ? 34 : 25, marginRight: 8, color: iconColor ? iconColor : null}}>
          {icon}
        </SvgIcon>
      )}
      <Grid item>
        <Typography variant={titleVariant ? titleVariant : 'h4'}>
          {title}
        </Typography>
      </Grid>
      {titleComponent && (
        <Grid item style={componentStyles()}>
          {titleComponent}
        </Grid>
      )}
    </Grid>
  );

  return (
    <Grid container direction="column">
      {cardHeader}
      <Grid
        container
        item
        direction={formGridDirection}
        xs={12}
        alignItems={formGridAlignItems}
        style={{ marginLeft: 0 }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PageHeaderCard);
