import React, { FunctionComponent } from "react";
import { Grid } from '@material-ui/core';
import { BasePage } from "src/Components/core/BasePage";
import { SupportRequestContainer } from 'src/containers/SupportRequestContainer';

export const SupportRequestView: FunctionComponent = () => {
    return (
        <Grid container direction="column" alignItems="stretch">
            <BasePage>
              <SupportRequestContainer/>
            </BasePage>
        </Grid>
    );
}

