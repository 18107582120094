import { Card, CardContent, Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { StakeholderDecisionCriteriaPrioritizationContainer } from 'src/containers';
import { CustomerPrioritizationContainer } from 'src/containers/Graphs/CustomerPrioritizationContainer';
import { StakeholderPrioritizationContainer } from 'src/containers/Graphs/StakeholderPrioritizationContainer';
import theme from 'src/Theme';

interface ISurveyResultsDetailsIndividualProps {
    surveyId: string;
}

export const SurveyResultsDetailsIndividual: FunctionComponent<ISurveyResultsDetailsIndividualProps> = props => {
    const { surveyId } = props;

    return (
        <Grid container direction="row">

            <CustomerPrioritizationContainer surveyId={surveyId} />

            <Grid item xs={12} style={{ padding: theme.spacing(1) }}>
                <Card style={{ height: '100%' }}>
                    <CardContent>
                        <StakeholderPrioritizationContainer surveyId={surveyId} />
                    </CardContent>
                </Card>
            </Grid>
            <StakeholderDecisionCriteriaPrioritizationContainer surveyId={surveyId} />
        </Grid>
    )
}