import { gql } from "@apollo/client";

export const GET_RESPONDENT = gql`
  query respondent($respondentId: String!) {
    respondent(id: $respondentId) {
      id
      firstName
      lastName
      email
    	mobilePhone
      communicationPreference
      accountId
      respondentTypeId
      departmentId
    }
  }
`;

export interface GetRespondentDTO {
  respondent: {
    id: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    mobilePhone?: string;
    communicationPreference?: string;
    accountId: string;
    respondentTypeId: string;
    departmentId: string;
  };
}
