import { gql } from "@apollo/client";

export const GET_USER = gql`
  query user($userId: String!) {
    user(id: $userId) {
      id
      email
      userMetaData {
        firstname
        lastname
        accountId
        phone
      }
      roles{
        id
      }
    }
  }
`;

export interface GetUserDTO {
    user: {
      id: string;
      email: string;
      userMetaData?: {
        firstname?: string;
        lastname?: string;
        accountId?: string;
        phone?: string;
      }
      roles: [{
        id: string;
      }]
    };
}