import { gql } from '@apollo/client';

export const DELETE_SURVEY = gql`
    mutation deleteSurvey($surveyId: String!) {
        deleteSurvey(id: $surveyId) {
            id
        }
    }
`

export interface DeleteSurveyDTO {
    deleteSurvey: {
        id: string;
    }
}