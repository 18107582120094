import { gql } from '@apollo/client';

export const SEND_SUPPORT_EMAIL = gql`
    mutation sendSupportEmail($supportRequest:SupportRequestInput!) {
        sendSupportRequest(input:$supportRequest)
    }
`

export interface SendSupportEmailDTO {
    sendSupportRequest: boolean;
}