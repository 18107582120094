import React, { FunctionComponent } from "react";
import { BasePage } from "src/Components/core/BasePage";
import { Box } from "@material-ui/core";
import { AddNewSurveyStepperContainer } from "src/containers";

export const AddNewSurveyView: FunctionComponent = () => {
  return (
    <BasePage>
      <Box m={2}>
        <AddNewSurveyStepperContainer />
      </Box>
    </BasePage>
  );
}