import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TakeSurvey } from 'src/Components';
import LoadingIndicator from 'src/Components/core/LoadingIndicator';
import { SurveyFeatureDTO, SurveyRespondentStatusOptions } from 'src/models';
import { GetTakeSurveyDataDTO, GET_TAKE_SURVEY_DATA_PUBLIC, UpdateSurveyRespondentDTO, UPDATE_SURVEY_RESPONDENT_PUBLIC } from 'src/operations';
import { TakeSurveyDetailsContainer } from '..';
import { SurveyCanceledMessage } from '../../Components/TakeSurvey/SurveyCanceledMessage';
import { TakeSurveyContainerLogic } from './TakeSurveyContainerLogic';

export interface ITakeSurveyContainerProps {
    updateNumberOfSteps: (numSteps: number) => void;
    goToNextStep: () => void;
    currentStep: number;
    numSteps: number;
}

export const TakeSurveyContainer: FunctionComponent<ITakeSurveyContainerProps> = props => {
    const [logic] = useState(new TakeSurveyContainerLogic());
    const { surveyRespondentId } = useParams<{ surveyRespondentId: string }>();
    const [updateSurveyRespondent] = useMutation<UpdateSurveyRespondentDTO>(UPDATE_SURVEY_RESPONDENT_PUBLIC)
    const {
        updateNumberOfSteps,
        goToNextStep,
        currentStep,
        numSteps,
    } = props;
    const { data: surveyResponses, loading, error } = useQuery<GetTakeSurveyDataDTO>(GET_TAKE_SURVEY_DATA_PUBLIC, {
        variables: {
            surveyRespondentId: surveyRespondentId,
        }, nextFetchPolicy: 'network-only'
    })
    const { enqueueSnackbar } = useSnackbar();
    const [featurePairs, setFeaturePairs] = useState([] as Partial<SurveyFeatureDTO>[][]);
    const [surveyStatus, setSurveyStatus] = useState(null as SurveyRespondentStatusOptions);
    const [isSurveyDetailsVisible, setIsSurveyDetailsVisible] = useState(true);
    const [isSurveyCanceled, setIsSurveyCanceled] = useState(false);

    useEffect(() => {
        const featureList = surveyResponses?.surveyForResponse?.survey?.features;
        if (featureList) {
            const numFeatures = featureList.length;
            const numPairs = (numFeatures * (numFeatures - 1)) / 2;
            updateNumberOfSteps(numPairs)
        }
    }, [surveyResponses, updateNumberOfSteps])

    useEffect(() => {
        const featureList = surveyResponses?.surveyForResponse?.survey?.features;
        const existingResponses = surveyResponses?.surveyForResponse?.responses;
        if (featureList && featurePairs.length === 0) {
            setFeaturePairs(logic.getFeaturePairs(featureList, existingResponses));
        }
    }, [surveyResponses, logic, setFeaturePairs, featurePairs.length])

    useEffect(() => {
        const status = surveyResponses?.surveyForResponse?.status;
        if (status) {
            setSurveyStatus(status);
        }
    }, [surveyResponses, setSurveyStatus])

    useEffect(() => {
        if (error) {
            enqueueSnackbar('There was an error while attempting to retrieve poll data.', { variant: 'error' });
            console.error(error);
        }
    }, [error, enqueueSnackbar]);

    const handleGoToNextStep = () => {
        if (currentStep === numSteps) {
            logic.setSurveyRespondentStatus(surveyResponses, updateSurveyRespondent, SurveyRespondentStatusOptions.completed);
            setSurveyStatus(SurveyRespondentStatusOptions.completed);
            setIsSurveyDetailsVisible(true);
        }
        goToNextStep();
    }

    const cancelSurvey = () => {
        logic.setSurveyRespondentStatus(surveyResponses, updateSurveyRespondent, SurveyRespondentStatusOptions.canceled);
        setSurveyStatus(SurveyRespondentStatusOptions.canceled);
        setIsSurveyCanceled(true)
    }

    const startSurvey = () => {
        setIsSurveyDetailsVisible(false);
        if (surveyResponses.surveyForResponse.status !== SurveyRespondentStatusOptions.inProgress) {
            logic.setSurveyRespondentStatus(surveyResponses, updateSurveyRespondent, SurveyRespondentStatusOptions.inProgress);
            setSurveyStatus(SurveyRespondentStatusOptions.inProgress);
        }
    }

    if (loading || !surveyResponses?.surveyForResponse?.survey || !featurePairs) {
        return <LoadingIndicator />
    }

    if (isSurveyCanceled) {
        return <SurveyCanceledMessage />;
    }

    if (isSurveyDetailsVisible) {
        return <TakeSurveyDetailsContainer
            onTakeSurveyClicked={startSurvey}
            surveyDetails={surveyResponses?.surveyForResponse?.survey}
            surveyRespondentStatus={surveyStatus}
            respondentId={surveyResponses?.surveyForResponse?.respondentId}
        />
    }

    return (
        <TakeSurvey
            goToNextStep={handleGoToNextStep}
            cancelSurvey={cancelSurvey}
            currentStep={currentStep}
            numSteps={numSteps}
            featurePairs={featurePairs}
            decisionCriteria={surveyResponses?.surveyForResponse?.survey?.decisionCriteria}
            responses={surveyResponses?.surveyForResponse?.responses}
            surveyId={surveyResponses?.surveyForResponse?.surveyId}
            respondentId={surveyResponses?.surveyForResponse?.respondentId}
            respondentType={surveyResponses?.surveyForResponse?.respondent?.respondentType?.description}
            surveyRespondentId={surveyRespondentId}
        />
    )
}
