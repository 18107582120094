import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import SendFeedbackPage from 'src/Components/SendFeedback/Feedback'
import { BasePage } from 'src/Components/core/BasePage';

export const SendFeedbackView: FunctionComponent = () => {
    return (
        <Grid container direction="column" alignItems="stretch">
            <BasePage>
                <SendFeedbackPage />
            </BasePage>
        </Grid>
    );
}