import { useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useEffect } from 'react';
import { GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from 'src/Components/core/LoadingIndicator';
import { TakeSurveyDetails } from 'src/Components/TakeSurvey/TakeSurveyDetails';
import { SurveyRespondentStatusOptions } from 'src/models';
import { GetRespondentTypeDTO, GetTakeSurveyDataSurveyDTO, GET_RESPONDENT_TYPE_PUBLIC } from 'src/operations';

interface ITakeSurveyDetailsContainerProps {
    onTakeSurveyClicked: () => void;
    surveyDetails: GetTakeSurveyDataSurveyDTO;
    surveyRespondentStatus: SurveyRespondentStatusOptions
    respondentId: string;
}

export const TakeSurveyDetailsContainer: FunctionComponent<ITakeSurveyDetailsContainerProps> = props => {
    const { onTakeSurveyClicked, surveyDetails, surveyRespondentStatus, respondentId } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { data: respondentData, loading: isLoading, error, refetch } = useQuery<GetRespondentTypeDTO>(GET_RESPONDENT_TYPE_PUBLIC, {
        variables: {
            respondentId: respondentId,
        }
    })

    const reloadResults = async () => {
        await refetch({
            respondentId: respondentId,
        });
    }

    useEffect(() => {
        if (error) {
            enqueueSnackbar('An error occurred while trying to retrieve respondent data.', { variant: 'error' });
            console.error(error);
        }
    }, [error, enqueueSnackbar]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='poll details' />;
    }

    return <TakeSurveyDetails onTakeSurveyClicked={onTakeSurveyClicked} surveyDetails={surveyDetails} surveyRespondentStatus={surveyRespondentStatus} respondentId={respondentId} respondentType={respondentData.respondent.respondentType.description} />;
}