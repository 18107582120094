import { gql } from "@apollo/client";
import { SurveyRespondentStatusOptions } from 'src/models';

export const GET_TAKE_SURVEY_DATA_PUBLIC = gql`
query surveyRespondents($surveyRespondentId: String!) {
    surveyForResponse(id: $surveyRespondentId) {
      id
      surveyId
      respondentId
      respondent{
        respondentType{
          description
        }
      }
      status
      responses(orderBy:"createdOn", pageSize:500) {
        id
        surveyFeatureId
        comparedToSurveyFeatureId
        surveyDecisionCriteriaId
        ranking
      }
      survey {
        id
        name
        description
        projectName
        isLocked
        features(pageSize:50) {
          id
          name
          description
          details
        }
        decisionCriteria(pageSize:50) {
          id
          name
          description
          isLocked
        }
      }
    }
  }
`;

export interface GetTakeSurveyDataSurveyDTO {
  id: string;
  name: string;
  description: string;
  projectName: string;
  isLocked?: boolean;
  features: [
      {
          id: string;
          name: string;
          description: string;
          details: string;
      }
  ];
  decisionCriteria: [
      {
          id: string;
          name: string;
          description: string;
          isLocked?: boolean;
      }
  ]
}

export interface GetTakeSurveyDataDTO {
    surveyForResponse: {
        id: string;
        surveyId: string;
        respondentId: string;
        respondent: {
          respondentType: {
            description: string;
          }
        }
        status: SurveyRespondentStatusOptions;
        responses: [
            {
                id: string;
                surveyFeatureId: string;
                comparedToSurveyFeatureId: string;
                surveyDecisionCriteriaId: string;
                ranking: number;
            }
        ];
        survey: GetTakeSurveyDataSurveyDTO;
    };
}
