import React, { FunctionComponent } from "react";
import { Divider, Button, Box } from "@material-ui/core";
import { BasePage } from "src/Components/core/BasePage";
import PageHeaderCard from "src/Components/core/PageHeaderCard";
import AddIcon from "@material-ui/icons/Add";
import SurveysContainer from "../containers/SurveysContainer";
import { Link } from "react-router-dom";

export const SurveyListView: FunctionComponent = () => {
  const addSurveyButton = (
    <Link to={"AddNewSurvey"} style={{ textDecoration: "none" }}>
      <Button
        variant='contained'
        color="primary"
        startIcon={<AddIcon />}
      >
        Add New Poll
      </Button>
    </Link>
  );

  return (
    <BasePage>
      <Box m={2}>
        <PageHeaderCard
          title="Polls"
          titleComponent={addSurveyButton}
          isTitleComponentPushedRight={true}
        />
      </Box>
      <Divider variant="middle" />
      <Box m={2}>
        <SurveysContainer />
      </Box>
    </BasePage>
  );
}