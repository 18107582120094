import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from "react";
import { DecisionCriteriaCard } from 'src/Components';
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import SurveyStepperAddDecisionCriteria from "src/Components/Surveys/SurveyStepperAddDecisionCriteria";
import { DecisionCriteriaDTO } from 'src/models';
import {
    CreateSurveyDecisionCriteriaDTO,
    CREATE_SURVEY_DECISION_CRITERIA,
    DELETE_SURVEY_DECISION_CRITERIA,
    GetSurveyDecisionCriteriaDTO,
    GET_SURVEY_DECISION_CRITERIA,
    UPDATE_SURVEY_DECISION_CRITERIA
} from 'src/operations';
import { AddEditDecisionCriteriaContainerLogic } from './AddEditDecisionCriteriaContainerLogic';

export interface IAddEditDecisionCriteriaContainerProps {
    surveyId: string;
    onDecisionCriteriaUpdated?: (updatedDecisionCriteria: DecisionCriteriaDTO[]) => void;
    isEditDisabled?: boolean;
    isShowDetailView?: boolean;
}

export function AddEditDecisionCriteriaContainer(props: IAddEditDecisionCriteriaContainerProps) {
    const logic = new AddEditDecisionCriteriaContainerLogic();
    const { surveyId, isEditDisabled, onDecisionCriteriaUpdated, isShowDetailView } = props;
    const [surveyDecisionCriteria, setSurveyDecisionCriteria] = useState([] as DecisionCriteriaDTO[]);
    const [numDecisionCriteriaRemaining, setNumDecisionCriteriaRemaining] = useState(0);
    const [createSurveyDecisionCriteria] = useMutation(CREATE_SURVEY_DECISION_CRITERIA);
    const [updateSurveyDecisionCriteria] = useMutation(UPDATE_SURVEY_DECISION_CRITERIA);
    const [deleteSurveyDecisionCriteria] = useMutation(DELETE_SURVEY_DECISION_CRITERIA);
    const {
        data: surveyDCQueryData,
        loading: isSurveyDCQueryLoading,
        error: surveyDCError,
        refetch: refetchSurveyDecisionCriteria
    } = useQuery<GetSurveyDecisionCriteriaDTO>(GET_SURVEY_DECISION_CRITERIA, {
        variables: {
            surveyId: surveyId,
        }
    })
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (surveyDCQueryData) {
            setSurveyDecisionCriteria(surveyDCQueryData.survey.decisionCriteria);
            setNumDecisionCriteriaRemaining(surveyDCQueryData.survey.account.maxDecisionCriteria - surveyDCQueryData.survey.decisionCriteria.length)
        }
    }, [surveyDCQueryData, setSurveyDecisionCriteria, setNumDecisionCriteriaRemaining])

    const showErrorMessage = (message: string) => {
        enqueueSnackbar(message, { variant: 'error' });
    }

    const updateOtherDecisionCriteria = async (decisionCriteria: DecisionCriteriaDTO[], editedDecisionCriteriaId?: string) => {
        const originalDecisionCriteria = surveyDecisionCriteria;
        for (const updatedDecisionCriteria of decisionCriteria) {
            const originalRecord = originalDecisionCriteria.find(odc => odc.id === updatedDecisionCriteria.id)
            if (originalRecord && originalRecord.weight !== updatedDecisionCriteria.weight) {
                try {
                    await logic.updateSurveyDecisionCriteriaInDB(updatedDecisionCriteria, updateSurveyDecisionCriteria);
                } catch (e) {
                    showErrorMessage(`There was an error when attempting to update Desired Outcome ${updatedDecisionCriteria.name}.`);
                    console.error(e);
                }
            }
        }
        const remainingDC = decisionCriteria.map(dc => dc.id);
        const removedDecisionCriteria = originalDecisionCriteria.filter(sdc => !remainingDC.includes(sdc.id) && sdc.id !== editedDecisionCriteriaId);
        for (const doomedDecisionCriteria of removedDecisionCriteria) {
            try {
                await logic.deleteSurveyDecisionCriteriaInDB(doomedDecisionCriteria.id, deleteSurveyDecisionCriteria);
            } catch (e) {
                showErrorMessage(`There was an error when attempting to delete an existing Desired Outcome ${doomedDecisionCriteria.name}`);
                console.error(e);
            }
        }
    }

    const pullUpdatedSurveyDecisionCriteria = async () => {
        await refetchSurveyDecisionCriteria({
            surveyId: surveyId,
        });

        if (onDecisionCriteriaUpdated) {
            onDecisionCriteriaUpdated(surveyDecisionCriteria);
        }
    }

    const handleCreateDecisionCriteria = async (newDecisionCriteria: DecisionCriteriaDTO, otherDecisionCriteria?: DecisionCriteriaDTO[]) => {
        await updateOtherDecisionCriteria(otherDecisionCriteria);

        try {
            newDecisionCriteria.surveyId = surveyId;
            const createData: CreateSurveyDecisionCriteriaDTO = await logic.createSurveyDecisionCriteriaInDB(newDecisionCriteria, createSurveyDecisionCriteria);
            newDecisionCriteria = {
                ...newDecisionCriteria,
                id: createData.createSurveyDecisionCriteria.id,
                createdOn: createData.createSurveyDecisionCriteria.createdOn,
            }
            await pullUpdatedSurveyDecisionCriteria();
        } catch (e) {
            showErrorMessage(`There was an error when attempting to create a new Desired Outcome ${newDecisionCriteria.name}.`);
        }
    }

    const handleUpdateDecisionCriteria = async (editedDecisionCriteria: DecisionCriteriaDTO, otherDecisionCriteria?: DecisionCriteriaDTO[]) => {
        await updateOtherDecisionCriteria(otherDecisionCriteria, editedDecisionCriteria.id);

        try {
            await logic.updateSurveyDecisionCriteriaInDB(editedDecisionCriteria, updateSurveyDecisionCriteria);
            await pullUpdatedSurveyDecisionCriteria();
        } catch (e) {
            showErrorMessage(`There was an error when attempting to update an existing Desired Outcome ${editedDecisionCriteria.name}.`);
        }
    }

    const handleDeleteDecisionCriteria = async (otherDecisionCriteria: DecisionCriteriaDTO[], decisionCriteriaId?: string) => {
        await updateOtherDecisionCriteria(otherDecisionCriteria, decisionCriteriaId);

        if (decisionCriteriaId) {
            try {
                await logic.deleteSurveyDecisionCriteriaInDB(decisionCriteriaId, deleteSurveyDecisionCriteria);
            } catch (e) {
                showErrorMessage(`There was an error when attempting to delete an existing Desired Outcome.`);
            }
        }
        await pullUpdatedSurveyDecisionCriteria();
    }

    if (isSurveyDCQueryLoading) {
        return <LoadingIndicator />;
    }

    if (surveyDCError) {
        return <p>{JSON.stringify(surveyDCError)}</p>;
    }

    if (isShowDetailView) {
        return (
            <DecisionCriteriaCard
                numDecisionCriteriaRemaining={numDecisionCriteriaRemaining}
                decisionCriteria={surveyDecisionCriteria}
                createDecisionCriteria={handleCreateDecisionCriteria}
                updateDecisionCriteria={handleUpdateDecisionCriteria}
                deleteDecisionCriteria={handleDeleteDecisionCriteria}
                isEditDisabled={isEditDisabled}
            />
        )
    } else {
        return (
            <SurveyStepperAddDecisionCriteria
                numDecisionCriteriaRemaining={numDecisionCriteriaRemaining}
                decisionCriteria={surveyDecisionCriteria}
                createDecisionCriteria={handleCreateDecisionCriteria}
                updateDecisionCriteria={handleUpdateDecisionCriteria}
                deleteDecisionCriteria={handleDeleteDecisionCriteria}
            />
        );
    }
}

