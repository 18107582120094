import { Divider, Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent, ReactElement } from 'react';
import theme from 'src/Theme';

export interface IBasicCardProps {
    title: string;
    subtitle?: string;
    bodyContent?: string | ReactElement;
}

export const BasicCard: FunctionComponent<IBasicCardProps> = props => {
    const { title, bodyContent, subtitle } = props;
    return (
        <Grid container direction='column' style={{ marginTop: theme.spacing(2) }}>
            <Grid item style={{ textAlign: 'left' }}>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    { title }
                </Typography>
                <Typography variant="body1">
                    { subtitle }
                </Typography>
            </Grid>
            <Grid item style={{ textAlign: 'left', marginTop: theme.spacing(1) }}>
                <Typography variant="body1">
                    { bodyContent }
                </Typography>
            </Grid>
            <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(1)}} />
        </Grid>
    )
}