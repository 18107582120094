import { Grid, IconButton, SvgIcon, SvgIconProps, Typography } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React, { FunctionComponent, ReactElement } from 'react';
import theme from 'src/Theme';

interface IESDataTableHeaderProps {
    icon: ReactElement<SvgIconProps>,
    title: string;
    onAddClicked: () => void;
}

export const ESDataTableHeader: FunctionComponent<IESDataTableHeaderProps> = props => {
    const {
        icon,
        title,
        onAddClicked
    } = props;

    return (
        <Grid container direction="row" alignItems="center" style={{ minHeight: theme.spacing(6) }} >
            <SvgIcon color="primary" style={{ fontSize: theme.spacing(3), marginRight: theme.spacing(1) }}>
                {icon}
            </SvgIcon>
            <Typography style={{ fontSize: theme.spacing(3), fontWeight: 600 }}>
                { title }
            </Typography>
            <IconButton
                style={{ marginLeft: 'auto', padding: 0, height: theme.spacing(6), width: theme.spacing(6) }}
                color="primary"
                onClick={onAddClicked}
            >
                <span style={{ fontSize: theme.spacing(5) }}>
                    <AddCircle fontSize="inherit" />
                </span>
            </IconButton>
        </Grid>
    )
}