import { gql } from '@apollo/client';

export const CREATE_SURVEY = gql`
    mutation createSurvey($survey: SurveyInput!) {
        createSurvey(input: $survey) {
            id
        }
    }
`

export interface CreateSurveyDTO {
    createSurvey: {
        id: string;
    }
}