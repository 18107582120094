import { ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, Grid, IconButton, CircularProgress, Button, Icon } from '@material-ui/core';
import { CheckCircle, AddCircleOutline, Send, AssignmentTurnedIn } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useState } from 'react';
import { RespondentDTO } from 'src/models';
import { SendSurveyRespondentInvitationDTO } from 'src/operations/mutations/surveyRespondent/sendSurveyRespondentInvitation';
import GeneratedAvatar from '../core/GeneratedAvatar';


export interface ISurveyRespondentListItemProps {
    respondent: Partial<RespondentDTO>;
    isSelected: boolean;
    isCompleted?: boolean;
    addRespondent: (respondent: Partial<RespondentDTO>) => Promise<void>;
    removeRespondent: (respondentId: string) => Promise<void>;
    sendSurveyRespondentInvitation: () => Promise<SendSurveyRespondentInvitationDTO>
    onEdit: (respondentId: string, respondentTypeName?: string) => void;
    showClipboard?: boolean;
    isEditDisabled?: boolean;
    isSendInvitationsDisabled?: boolean;
    isSendInvitationsVisible?: boolean;
    isLoading?: boolean;
}

export const SurveyRespondentListItem: FunctionComponent<ISurveyRespondentListItemProps> = props => {
    const {
        respondent,
        isSelected,
        isCompleted,
        addRespondent,
        removeRespondent,
        onEdit,
        sendSurveyRespondentInvitation,
        showClipboard,
        isEditDisabled,
        isSendInvitationsDisabled,
        isSendInvitationsVisible,
        isLoading: isLoadingExternal,
    } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [isSendingInvites, setIsSendingInvites] = useState(false);

    const getListItemAction = () => {
        const handleAddRespondentClicked = async () => {
            setIsLoading(true);
            await addRespondent(respondent);
            setIsLoading(false)
        }

        const handleRemoveRespondentClicked = async () => {
            setIsLoading(true);
            await removeRespondent(respondent.id);
            setIsLoading(false)
        }

        if (isSelected) {
            if (isCompleted) {
                return (
                    <Icon color="primary" style={{ marginRight: 12 }}>
                        <AssignmentTurnedIn />
                    </Icon>
                )
            } else {
                return (
                    <Grid>
                        <IconButton
                            color="secondary"
                            onClick={handleRemoveRespondentClicked}
                            disabled={isLoading || isLoadingExternal || isEditDisabled}
                        >
                            <CheckCircle />
                        </IconButton>
                    </Grid>
                )
            }
        } else {
            return (
                <IconButton
                    color="primary"
                    onClick={handleAddRespondentClicked}
                    disabled={isLoading || isLoadingExternal || isEditDisabled}
                >
                    <AddCircleOutline />
                </IconButton>
            )
        }
    }

    const getRespondentFullName = () => {
        return `${respondent.firstName} ${respondent.lastName}`;
    }

    const handleEditClicked = () => {
        onEdit(respondent.id, respondent.respondentType?.description);
    }

    const getRespondentSecondaryText = (): React.ReactNode => {
        if (respondent.respondentType?.description === 'Stakeholder' && respondent.department?.name) {
            return `${respondent.respondentType?.description} (${respondent.department?.name})`
        } else {
            return respondent.respondentType?.description;
        }
    }

    const showActionButtons = () => {
        if(showClipboard) {
            return(
                <Grid container direction="row">
                    {isCompleted && (
                        <Icon color="primary">
                            <AssignmentTurnedIn />
                        </Icon>
                    )}
                    {isSendInvitationsVisible && !isCompleted &&
                        <Button
                            startIcon={<Send />}
                            variant="contained"
                            color="primary"
                            onClick={sendInvitation}
                            disabled={isSendingInvites || isSendInvitationsDisabled}
                        >
                            {isSendingInvites ? <div style={{ minWidth: 84 }} ><CircularProgress size={18} /></div>: 'Remind'}
                        </Button>
                    }
                </Grid>
            )
        }
        else {
            return(
                <Grid container direction="row" alignItems="center">
                    {getListItemAction()}
                </Grid>
            )
        }
    }

    const sendInvitation = async () => {
        setIsSendingInvites(true);
        try {
            await sendSurveyRespondentInvitation()
            enqueueSnackbar('Poll Invitation Sent', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('There was an error sending the invitation to the respondent.', { variant: 'error' });
        }
        setIsSendingInvites(false);
    }

    return <ListItem style={{ height: 72 }} button={true} onClick={handleEditClicked}>
        <ListItemAvatar>
            <Avatar>
                <GeneratedAvatar name={getRespondentFullName()} />
            </Avatar>
        </ListItemAvatar>
        <ListItemText
            primary={getRespondentFullName()}
            secondary={getRespondentSecondaryText()} />
        <ListItemSecondaryAction>
            <Grid container direction="row">
                {showActionButtons()}
            </Grid>
        </ListItemSecondaryAction>
    </ListItem>;
}