import { Grid, Card, CardContent } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { DepartmentDecisionCriteriaPrioritizationContainer, DepartmentPrioritizationContainer } from 'src/containers';
import theme from 'src/Theme';

interface ISurveyResultsDetailsDepartmentProps {
    surveyId: string;
}

export const SurveyResultsDetailsDepartment: FunctionComponent<ISurveyResultsDetailsDepartmentProps> = props => {
    const { surveyId } = props;

    return (
        <Grid container direction="row">
            <Grid item xs={12} style={{padding: theme.spacing(1)}}>
                <Card style={{height: '100%'}}>
                    <CardContent>
                        <DepartmentPrioritizationContainer surveyId={surveyId} />
                    </CardContent>
                </Card>
            </Grid>
            <DepartmentDecisionCriteriaPrioritizationContainer surveyId={surveyId} />
        </Grid>
    )
}