import * as React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

class LoadingIndicator extends React.Component {
    public render() {
        return (
            <Grid container justify="center" alignItems="stretch" style={{padding: '20px'}}>
                <Grid item>
                    <CircularProgress id="loading-indicator" />
                </Grid>
            </Grid>
        );
    }

}

export default LoadingIndicator;