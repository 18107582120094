import { gql } from "@apollo/client";

export const GET_ALL_DEPARTMENTS = gql`
query getDepartments {
    departments(orderBy:"name") {
        id
        name
    }
}`;

export interface GetAllDepartmentsDTO {
    departments: [
        {
            id: string;
            name: string;
        }
  ];
}
