import { gql } from '@apollo/client';

export const CREATE_RESPONDENT = gql`
    mutation createRespondent($respondent: RespondentInput!) {
        createRespondent(input: $respondent) {
            id
        }
    }
`

export interface CreateRespondentDTO {
    createRespondent: {
        id: string;
    }
}