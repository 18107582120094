import React from 'react'
import { SurveyFeatureDTO } from 'src/models'
import { Grid, Paper, Typography } from '@material-ui/core';
import theme from 'src/Theme';
import { Edit } from '@material-ui/icons';

export interface IFeatureCardProps {
    feature: SurveyFeatureDTO;
    featureIndex: number;
    editFeature: (featureIndex: number) => void;
}

export default function FeatureCard(props: IFeatureCardProps) {
    const { editFeature, feature, featureIndex } = props;

    const handleEditClicked = () => {
        editFeature(featureIndex)
    }

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            className="feature-card-container"
        >
            <Paper
                style={{
                    minHeight: '156px',
                    padding: theme.spacing(1),
                    borderRadius: '4px',
                    width: '100%',
                }}
                elevation={1}
                className="feature-card"
            >
                <Grid container direction="column" style={{height: '100%'}}>
                    <Grid container direction="row" justify="space-between">
                        <Grid item xs={10}>
                            <Typography variant='h6'>
                                {feature.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Edit onClick={handleEditClicked} style={{cursor: 'pointer'}}/>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item>
                            <Typography variant='body2'>
                                {feature.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}