import { gql } from '@apollo/client';

export const DELETE_SURVEY_RESPONDENT = gql`
    mutation deleteSurveyRespondent($surveyRespondentId: String!) {
        deleteSurveyRespondent(id: $surveyRespondentId) {
            id
        }
    }
`

export interface DeleteSurveyRespondentDTO {
    deleteSurveyRespondent: {
        id: string;
    }
}