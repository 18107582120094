import { useQuery } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import { GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from 'src/Components/core/LoadingIndicator';
import { UserDataTable } from 'src/Components/Users/UserDataTable';
import { GetUsersDTO, GET_USERS } from 'src/operations';

export const UserDataTableContainer: FunctionComponent = () => {
  const {loading: isLoading, data, error, refetch} = useQuery<GetUsersDTO>(GET_USERS);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || data === undefined) {
    return <GraphQLErrorIndicator reloadResults={refetch} componentName='poll list' />;
  }

  return (
        <UserDataTable
          userQueryData={data}
          reloadData={refetch}
        />
  );
}