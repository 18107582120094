import { gql } from "@apollo/client";

export const GET_CUSTOMER_BLENDED_AVERAGE = gql`
query getSurveyCustomerBlendedAverage($surveyId:String!){
  surveyRanking(surveyId:$surveyId) {
    decisionCriteriaRankings(nameFilter:"Customer") {
      decisionCriteria {
        name
      }
      blendedRankings{
        rank
        featureName
      }
    }
  }
}
`;

export interface GetCustomerBlendedAverageDTO {
  surveyRanking: {
    decisionCriteriaRankings: {
      decisionCriteria: {
        name: string;
      }
      blendedRankings: {
        featureName: string;
        rank: number;
      }[]
    }[]
  }
}
