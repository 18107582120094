import React, { useState, ChangeEvent, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, TextField, DialogActions, Button, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import theme from 'src/Theme';
import { DepartmentDTO, RespondentDTO } from 'src/models';
import { DeleteButtonWithConfirmation, FormValidationTooltip } from 'src/Components';
import { validateEmail } from 'src/Utils';

export interface IAddEditRespondentStakeholderModalProps {
    closeModal: () => void;
    createRespondentStakeholder: (newRespondentStakeholder: RespondentDTO) => Promise<void>;
    updateRespondentStakeholder: (editedRespondentStakeholder: RespondentDTO, respondentStakeholderId: string) => Promise<void>;
    deleteRespondentStakeholder: (respondentStakeholderId: string) => Promise<void>;
    existingRespondentStakeholder?: RespondentDTO;
    isOpen: boolean;
    departments: Partial<DepartmentDTO>[];
    isEditDisabled?: boolean;
    isSMSEnabled?: boolean;
}

export function AddEditRespondentStakeholderModal(props: IAddEditRespondentStakeholderModalProps) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [communicationPreference, setCommunicationPreference] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {
        closeModal,
        createRespondentStakeholder,
        updateRespondentStakeholder,
        deleteRespondentStakeholder,
        existingRespondentStakeholder,
        isOpen,
        departments,
        isEditDisabled,
        isSMSEnabled
    } = props;

    const handleNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFirstName(event.target.value)
    }

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLastName(event.target.value)
    }

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmail(event.target.value)
    }

    const handlePhoneChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPhone(event.target.value)
    }

    const handleCommunicationPreferenceChange = (event: ChangeEvent<{value: unknown;}>) => {
        setCommunicationPreference(event.target.value as string)
    }

    const handleDepartmentChange = (event: ChangeEvent<{value: unknown;}>) => {
        setDepartmentId(event.target.value as string)
    }

    const isFormValid = () => {
        const isBasicInfoValid = !!firstName && !!lastName && !!communicationPreference && !!departmentId;
        let isConditionalInfoValid = false;
        switch (communicationPreference) {
            case 'Email':
                isConditionalInfoValid = !!email && validateEmail(email);
                break;
            case 'Email and SMS':
            case 'SMS':
                isConditionalInfoValid = !!email && validateEmail(email) && !!phone;
                break;
        }

        return isBasicInfoValid && isConditionalInfoValid;
    }

    const handleAddRespondentStakeholder = () => {
        const stakeholderRespondentTypeId = '5252a895-4d90-443f-8912-71be51c60999';

        const newRespondentStakeholder: RespondentDTO = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            mobilePhone: phone,
            departmentId: departmentId,
            communicationPreference: communicationPreference,
            respondentTypeId: stakeholderRespondentTypeId,
        };

        setIsLoading(true);
        createRespondentStakeholder(newRespondentStakeholder);
        closeModal();
    }

    const handleEditRespondentStakeholder = () => {
        const editedRespondentStakeholder: RespondentDTO = {
            ...existingRespondentStakeholder,
            firstName: firstName,
            lastName: lastName,
            email: email,
            mobilePhone: phone,
            departmentId: departmentId,
            communicationPreference: communicationPreference,
        }
        delete editedRespondentStakeholder.id;
        delete editedRespondentStakeholder.__typename;

        setIsLoading(true);
        updateRespondentStakeholder(editedRespondentStakeholder, existingRespondentStakeholder.id);
        closeModal();
    }

    const handleDeleteRespondentStakeholder = () => {
        setIsLoading(true);
        deleteRespondentStakeholder(existingRespondentStakeholder.id);
        closeModal();
    }

    useEffect(() => {
        if (existingRespondentStakeholder) {
            setFirstName(existingRespondentStakeholder.firstName);
            setLastName(existingRespondentStakeholder.lastName);
            setEmail(existingRespondentStakeholder.email);
            setPhone(existingRespondentStakeholder.mobilePhone);
            setCommunicationPreference(existingRespondentStakeholder.communicationPreference || 'Email');
            setDepartmentId(existingRespondentStakeholder.departmentId || '')
        } else {
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setCommunicationPreference('Email');
            setDepartmentId('')
        }
    }, [isOpen, existingRespondentStakeholder]);

    return (
        <Dialog open={isOpen} onClose={closeModal}>
            <DialogTitle style={{ paddingBottom: '0', fontWeight: 'bold' }}>
                {existingRespondentStakeholder ? 'Edit' : 'Add'} Stakeholder
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' style={{ minWidth: '400px' }}>
                    <Grid item container direction="row">
                        <Grid item xs={6}>
                            <TextField
                                InputProps={{
                                    readOnly: isEditDisabled,
                                }}
                                label='First Name'
                                value={firstName}
                                style={{ marginBottom: theme.spacing(1) }}
                                onChange={handleNameChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                InputProps={{
                                    readOnly: isEditDisabled,
                                }}
                                label='Last Name'
                                value={lastName}
                                style={{ marginBottom: theme.spacing(1) }}
                                onChange={handleDescriptionChange}
                                required
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        InputProps={{
                            readOnly: isEditDisabled,
                        }}
                        label='Email Address'
                        value={email}
                        style={{ marginBottom: theme.spacing(1) }}
                        onChange={handleEmailChange}
                        required
                        error={!!email && !validateEmail(email)}
                        helperText={!!email && !validateEmail(email) ? 'Must be a valid email address' : ''}
                    />
                    <TextField
                        InputProps={{
                            readOnly: isEditDisabled,
                        }}
                        label='Mobile Phone'
                        value={phone}
                        style={{ marginBottom: theme.spacing(1) }}
                        onChange={handlePhoneChange}
                        required={['SMS', 'Email and SMS'].includes(communicationPreference)}
                    />
                    <FormControl>
                        <InputLabel required id='department-select'>Department</InputLabel>
                        <Select
                            readOnly={isEditDisabled}
                            labelId='department-select'
                            id='department-select'
                            value={departmentId}
                            style={{ marginBottom: theme.spacing(1) }}
                            onChange={handleDepartmentChange}
                            required
                        >
                            {departments && departments.map((department) => {
                                return (
                                    <MenuItem key={department.id} value={department.id}>{department.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id='communication-preference-select'>Communication Preference</InputLabel>
                        <Select
                            readOnly={isEditDisabled}
                            labelId='communication-preference-select'
                            id='communication-preference-select'
                            value={communicationPreference}
                            style={{ marginBottom: theme.spacing(1) }}
                            onChange={handleCommunicationPreferenceChange}
                        >
                            <MenuItem value={'SMS'} disabled={!isSMSEnabled}>SMS</MenuItem>
                            <MenuItem value={'Email'}>Email</MenuItem>
                            <MenuItem value={'Email and SMS'} disabled={!isSMSEnabled}>Email and SMS</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(1) }}>
                {
                    existingRespondentStakeholder && !isEditDisabled &&
                    <DeleteButtonWithConfirmation onDelete={handleDeleteRespondentStakeholder} itemName="stakeholder" />
                }
                {
                    isEditDisabled ?
                        <Button color="primary" variant="outlined" onClick={closeModal}>
                            Close
                        </Button>
                    :
                    <>
                        <Button color="primary" variant="outlined" onClick={closeModal}>
                            Cancel
                        </Button>
                        <FormValidationTooltip isVisible={!isFormValid() && !isLoading} >
                        <Button
                                color="primary"
                                variant="contained"
                                style={{ marginLeft: theme.spacing(2) }}
                                disabled={!isFormValid()}
                                onClick={existingRespondentStakeholder ? handleEditRespondentStakeholder : handleAddRespondentStakeholder}
                            >
                                {existingRespondentStakeholder ? 'Update' : 'Add'}
                            </Button>
                        </FormValidationTooltip>
                    </>
                }
            </DialogActions>
        </Dialog>
    )
}