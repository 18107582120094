import { gql } from '@apollo/client';

export const SEND_SURVEY_RESPONDENT_INVITATION_MULTIPLE = gql`
    mutation sendSurveyRespondentInvitation($surveyRespondentIds:[String!]!) {
        notifyRespondentsById(ids:$surveyRespondentIds)
    }
`

export interface SendSurveyRespondentInvitationMultipleDTO {
    notifyRespondentsById: boolean;
}