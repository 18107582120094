import { gql } from "@apollo/client";

export const GET_STAKEHOLDER_PRIORITIZATION_DATA = gql`
query getSurveyStakeholderPrioritization($surveyId:String!){
  survey(id:$surveyId) {
    stakeholders:groups(filterBy:"name:Stakeholder"){
      surveyRankings {
        blendedRankings{
          rank
          featureName
        }
        respondentRankings {
          respondent{
            id
            firstName
            lastName
          }
          featureRankings{
            featureName
            rank
          }
        }
      }
    }
  }
}
`;

export interface GetStakeholderPrioritizationDataDTO {
  survey: {
    stakeholders: [{
      surveyRankings: {
        blendedRankings: [{
          rank: number;
          featureName: string;
        }]
        respondentRankings: [{
          respondent: {
            id: string;
            firstName: string;
            lastName: string;
          }
          featureRankings: [{
            featureName: string;
            rank: number;
          }]
        }]
      }
    }]
  }
}
