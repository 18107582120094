import { Grid } from '@material-ui/core';
import React, { FunctionComponent, useState } from "react";
import { useParams } from 'react-router-dom';
import { HelpHeroRespondentAuthenticator } from 'src/Components';
import PublicApolloProvider from 'src/PublicApolloProvider';
import theme from 'src/Theme';
import { TakeSurveyContainer } from "../containers";

export const TakeSurveyView: FunctionComponent = () => {
    const [numSteps, setNumSteps] = useState(null as number);
    const [currentStep, setCurrentStep] = useState(1);
    const { surveyRespondentId } = useParams<{ surveyRespondentId: string }>();

    const goToNextStep = () => {
        if (currentStep < numSteps) {
            setCurrentStep(currentStep + 1)
        }
    }

    const handleStepNumberChange = (updatedNumSteps: number) => {
        if (updatedNumSteps < 1 && numSteps !== updatedNumSteps) {
            setNumSteps(1);
            return;
        }
        setNumSteps(updatedNumSteps);
    }

    return (
        <Grid style={{ background: 'white', minHeight: '100vh' }}>
            <PublicApolloProvider>
                <HelpHeroRespondentAuthenticator surveyRespondentId={surveyRespondentId} />
                <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
                    <TakeSurveyContainer
                        goToNextStep={goToNextStep}
                        currentStep={currentStep}
                        numSteps={numSteps}
                        updateNumberOfSteps={handleStepNumberChange}
                    />
                </div>
            </PublicApolloProvider>
        </Grid>
    );
}