import { CardContent, Collapse, createStyles, Divider, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import React, { FunctionComponent, ReactElement } from 'react';
import theme from 'src/Theme';

export interface IExpandableCardProps {
    title: string;
    subtitle?: string;
    expandedContent?: string | ReactElement;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }),
);

export const ExpandableCard: FunctionComponent<IExpandableCardProps> = props => {
    const { title, expandedContent, subtitle } = props;
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Grid container direction='column' style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
            <Grid item container direction="row" alignItems='center'>
                <Grid item sm={11} xs={10} style={{ textAlign: 'left' }}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        { title }
                    </Typography>
                    {subtitle ? <Typography variant="body1" style={{ marginTop: theme.spacing(1) }}>
                        { subtitle }
                    </Typography> : <></>}
                </Grid>
                {expandedContent && (
                    <Grid container item sm={1} xs={2} direction="row-reverse">
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            style={{ marginLeft: 'auto' }}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMore />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    { typeof expandedContent === 'string' ? (
                        <Typography variant='body1'>
                            { expandedContent }
                        </Typography>
                    ) : (
                        expandedContent
                    )}

                </CardContent>
            </Collapse>
            <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(1)}} />
        </Grid>
    )
}