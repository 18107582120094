import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { GetDecisionCriteriaDataDTO } from 'src/operations';

interface IDecisionCriteriaProps {
    chartData: GetDecisionCriteriaDataDTO;
}

export const DecisionCriteria: FunctionComponent<IDecisionCriteriaProps> = props => {
    const {
        chartData,
    } = props;
    const [pieSections, setPieSections] = useState([] as Highcharts.SeriesOptionsType[]);

    useEffect(() => {
        const decisionCriteria = chartData.survey.decisionCriteria;

        const pie: Highcharts.SeriesPieOptions[] = [];
        const pieSection: Highcharts.SeriesPieOptions = {
            type: 'pie',
            data: decisionCriteria.map(br => [br.name, br.weight])
        }
        pie.push(pieSection);

        setPieSections(pie);
    }, [chartData]);

    const options: Highcharts.Options = {
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
            }
        },
        legend: {
            align: 'left',
            verticalAlign: 'top',
            layout: 'vertical',
            floating: true,
            x: -10,
            y: 20,
            itemMarginBottom: 8,
        },
        chart: {
            type: 'pie'
        },
        title: {
            text: 'Desired Outcomes',
            align: 'left'
        },
        series: pieSections,
        tooltip: {
            formatter: function () {
                return `${this.y.toFixed(2)}%`
            }
        },
        credits: {
            enabled: false,
        },
    }

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                {...props}
            />
        </>
    )
}