import { gql } from "@apollo/client";

export const GET_DECISION_CRITERIA_CUSTOMER_RANKING_DATA = gql`
query getDecisionCriteriaCustomerRanking($surveyId:String!, $respondentId:String!){
  overall:surveyRanking(surveyId:$surveyId) {
    customerDecisionCriteriaRanking{
      decisionCriteria{
        id
        name
        survey{
          id
        }
      }
      blendedRankings{
        featureName
        rank
      }
    }
    respondentRanking(id:$respondentId) {
      respondent{
        id
        firstName
        lastName
      }
      featureRankings{
        featureName
        rank
      }
    }
  }
}
`;

export interface GetDecisionCriteriaCustomerRankingDataDTO {
  overall: {
    customerDecisionCriteriaRanking: {
      decisionCriteria: {
        id: string;
        name: string;
        survey: {
          id: string;
        }
      },
      blendedRankings: {
        featureName: string;
        rank: number;
      }[]
    },
    respondentRanking: {
      respondent: {
        id: string;
        firstName: string;
        lastName: string;
      },
      featureRankings: {
        featureName: string;
        rank: number;
      }[],
    }
  }
}
