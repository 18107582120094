import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import LoadingIndicator from 'src/Components/core/LoadingIndicator';
import SupportRequestPage from 'src/Components/SupportRequest';
import { GetUserDTO, GET_USER } from 'src/operations';

export const SupportRequestContainer: FunctionComponent = () => {
    const {user} = useAuth0();
    const [userFullName, setUserFullName] = useState('')
    const { loading: isLoading, data: userQueryData } = useQuery<GetUserDTO>(GET_USER, {
        variables: {
            userId: user.sub
        }
    })

    useEffect(() => {
        if (
            userQueryData &&
            userQueryData.user?.userMetaData?.firstname
        ) {
            const name = userQueryData.user?.userMetaData?.firstname;
            setUserFullName(name)
        }
    }, [userQueryData, setUserFullName])

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <SupportRequestPage
            userFullName={userFullName}
        />
    );
}