import { gql } from "@apollo/client";

export const GET_GLOBAL_DEFAULTS = gql`
  {
    globalDefaults {
      id
      name
      value
    }
  }
`;

export interface GlobalDefaultsDTO {
  globalDefaults: [
    {
      id: string;
      name: string;
      value: number;
    }
  ];
}
