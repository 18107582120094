import { useQuery } from "@apollo/client";
import React, { FunctionComponent } from "react";
import { useParams } from 'react-router-dom';
import { GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import { DecisionCriteria } from 'src/Components/Graphs/DecisionCriteria';
import { GetDecisionCriteriaDataDTO, GET_DECISION_CRITERIA_DATA } from "src/operations";

interface IDecisionCriteriaContainerProps {
    surveyId: string;
}

export const DecisionCriteriaContainer: FunctionComponent<IDecisionCriteriaContainerProps> = () => {
  const { surveyId } = useParams<{ surveyId: string }>();

  const {data, loading: isLoading, error, refetch} = useQuery<GetDecisionCriteriaDataDTO>(GET_DECISION_CRITERIA_DATA, {
    variables: {
      surveyId: surveyId,
    },
  });

  const reloadResults = async () => {
    await refetch({
      surveyId: surveyId,
    })
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || data === undefined) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='desired outcomes graph' />;
  }

  return (
    <DecisionCriteria
      chartData={data}
    />
  );
}
