import {
  Grid,
  Box,
  withStyles,
  Button
} from '@material-ui/core';
import React, { CSSProperties, FunctionComponent } from 'react';
import theme from 'src/Theme';
import { SurveyResultsDetailsDepartment } from './SurveyResultsDetailsDepartment';
import { SurveyResultsDetailsIndividual } from './SurveyResultsDetailsIndividual';

const sharedButtonStyle: CSSProperties | any = {
  height: 52,
  fontWeight: 'bold',
  borderColor: 'rgba(0,0,0,0.05)',
  color: 'rgba(0,0,0,0.4)'
}

const LeftToggleButton = withStyles({
  root: {
    ...sharedButtonStyle,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
})(Button);

const RightToggleButton = withStyles({
  root: {
    ...sharedButtonStyle,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
})(Button);
interface ISurveyResultsSurveyDetailsProps {
  surveyId: string;
}

export const SurveyResultsDetails: FunctionComponent<ISurveyResultsSurveyDetailsProps> = props => {
  const { surveyId } = props ;
  const [selection, setSelection] = React.useState('individuals');

  const handleSelection = (newSelection: string) => {
    setSelection(newSelection);
  };

  const selectedButtonStyles = { backgroundColor: 'rgba(0,0,0,0.05)', color: theme.palette.primary.main }

  return (
    <Grid container direction='row' style={{ paddingTop: theme.spacing(1) }}>
      <Grid item xs={12} style={{ padding: theme.spacing(1) }}>
        <Box mb={2}>
          <LeftToggleButton
            variant='outlined'
            style={selection === 'individuals' ? selectedButtonStyles : null}
            onClick={() => handleSelection('individuals')}
          >
            Individuals
          </LeftToggleButton>
          <RightToggleButton
            variant='outlined'
            style={selection === 'department' ? selectedButtonStyles : null}
            onClick={() => handleSelection('department')}
          >
            Department
          </RightToggleButton>
        </Box>
        {selection === 'individuals' ? <SurveyResultsDetailsIndividual surveyId={surveyId} /> : <SurveyResultsDetailsDepartment surveyId={surveyId} />}
      </Grid>
    </Grid>
  );
};
