import { FunctionComponent, useEffect } from 'react';
import initHelpHero from 'helphero';
import { useQuery } from '@apollo/client';
import { GetUserDTO, GET_USER } from 'src/operations';

const hlp = initHelpHero(process.env.REACT_APP_HELP_HERO_APP_ID);

interface IHelpHeroAuthenticatorProps {
    userId: string;
}

export const HelpHeroAuthenticator: FunctionComponent<IHelpHeroAuthenticatorProps> = props => {
    const { userId } = props;
    const { data: getUserData } = useQuery<GetUserDTO>(GET_USER, {
        variables: {
            userId: userId,
        }
    });

    useEffect(() => {
        if (getUserData) {
            hlp.identify(getUserData.user.id, {
                firstName: getUserData.user?.userMetaData?.firstname,
                lastName: getUserData.user?.userMetaData?.lastname,
            });
        }
    }, [getUserData]);

    return null;
}