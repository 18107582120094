import { FetchResult, useMutation } from '@apollo/client';
import { Button, Grid } from "@material-ui/core";
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PageHeaderCard from 'src/Components/core/PageHeaderCard';
import { IESStepperStep } from 'src/Components/Surveys';
import AddNewSurveyStepper from 'src/Components/Surveys/AddNewSurveyStepper';
import { SurveyDTO } from 'src/models';
import { CreateSurveyDTO, CREATE_SURVEY, DELETE_SURVEY, UpdateSurveyDTO, UPDATE_SURVEY } from 'src/operations';
import { AddNewSurveyStepperContainerLogic } from '..';

export function AddNewSurveyStepperContainer() {
  const logic = new AddNewSurveyStepperContainerLogic();
  const [createSurvey] = useMutation(CREATE_SURVEY);
  const [updateSurvey] = useMutation(UPDATE_SURVEY);
  const [deleteSurvey] = useMutation(DELETE_SURVEY);

  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const defaultSurveyState: SurveyDTO = {
    name: '',
    description: '',
    dateLocked: null,
    features: [],
    decisionCriteria: [],
    respondents: []
  }
  const [survey, setSurvey] = useState(defaultSurveyState);
  const initialStepValues: IESStepperStep[] = [
    { name: "Basic Info", isValid: false },
    { name: "Features", isValid: true },
    { name: "Desired Outcomes", isValid: true },
    { name: "Respondents", isValid: true },
  ]
  const [steps, setSteps] = useState(initialStepValues)

  useEffect(() => {
    logic.validateFormBasicInfo(survey, steps, setSteps);
  }, [survey, steps, logic])

  const saveSurvey = async () => {
    const formattedSurvey = _.cloneDeep(survey);
    delete formattedSurvey.features;
    delete formattedSurvey.decisionCriteria;
    delete formattedSurvey.respondents;
    let result: FetchResult<CreateSurveyDTO | UpdateSurveyDTO | any>

    if (survey.id) {
      delete formattedSurvey.id;
      try {
        result = await logic.updateSurveyInDB(updateSurvey, formattedSurvey, survey.id);
      } catch (error) {
        enqueueSnackbar(`Error while attempting to Update poll.`, { variant: 'error' })
        return false;
      }
    } else {
      try {
        result = await logic.createSurveyInDB(createSurvey, formattedSurvey);
        logic.updateSurveyInState(setSurvey, survey, result.data.createSurvey.id);
      } catch (error) {
        enqueueSnackbar(`Error while attempting to create poll.`, { variant: 'error' })
        return false;
      }
    }

    if (result.data) {
      return true;
    }
  }

  const handleStepperComplete = () => {
    history.push(`/SurveyDetails/${survey.id}`);
  }

  const nextStepActions = async (stepNumber: number) => {
    if (stepNumber === 0) {
        const saveResult = await saveSurvey();
        return saveResult
    } else {
      return true
    }
  }

  const handleCancelSurvey = async () => {
    if (survey.id) {
      await logic.deleteSurveyInDB(deleteSurvey, survey.id);
    }
    history.push('/Surveys');
  }

  const cancelSurveyButton = (
    <Link to={"/Surveys"} style={{ textDecoration: "none" }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleCancelSurvey}
      >
        Cancel Poll
      </Button>
    </Link>
  );

  return (
    <>
      <PageHeaderCard
        title="Add New Poll"
        titleComponent={cancelSurveyButton}
        isTitleComponentPushedRight={true}
      />
      <Grid container direction="column">
          {
            <AddNewSurveyStepper
              steps={steps}
              handleStepperComplete={handleStepperComplete}
              survey={survey}
              updateSurvey={setSurvey}
              nextStepActions={nextStepActions}
            />
          }
      </Grid>
    </>
  );
}

