import { gql } from '@apollo/client';

export const DELETE_RESPONDENT = gql`
    mutation deleteRespondent($respondentId: String!) {
        deleteRespondent(id: $respondentId) {
            id
        }
    }
`

export interface DeleteRespondentDTO {
    deleteRespondent: {
        id: string;
    }
}