import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import theme from 'src/Theme';
import { ExpandableCard, IExpandableCardProps } from './ExpandableCard';

interface IExpandableCardListProps {
    cardDetails: IExpandableCardProps[];
    title: string;
}

export const ExpandableCardList: FunctionComponent<IExpandableCardListProps> = props => {
    const { cardDetails, title } = props;

    return (
        <Grid container direction="column" style={{ marginTop: theme.spacing(2) }}>
            <Grid item>
                <Typography variant="h5">
                    { title }
                </Typography>
            </Grid>
            { cardDetails && cardDetails.map((cardInfo, index) => {
                return <ExpandableCard key={index} {...cardInfo} />
            })}
        </Grid>
    )
}