import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FeaturesCard } from 'src/Components';
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import SurveyStepperAddFeatures from "src/Components/Surveys/SurveyStepperAddFeatures";
import { SurveyFeatureDTO } from "src/models";
import {
  CREATE_SURVEY_FEATURE,
  DELETE_SURVEY_FEATURE,
  UPDATE_SURVEY_FEATURE,
} from "src/operations";
import {
  GetSurveyFeaturesDTO,
  GET_SURVEY_FEATURES,
} from "src/operations/queries";
import { AddEditFeatureContainerLogic } from "./AddEditFeatureContainerLogic";

export interface IAddEditFeatureContainerProps {
  surveyId: string;
  onSurveyFeaturesUpdated?: (updatedSurveyFeatures: SurveyFeatureDTO[]) => void;
  isEditDisabled?: boolean;
  isShowDetailView?: boolean;
}

export function AddEditFeaturesContainer(
  props: IAddEditFeatureContainerProps
) {
  const logic = new AddEditFeatureContainerLogic();
  const { surveyId, isEditDisabled, onSurveyFeaturesUpdated, isShowDetailView } = props;
  const [numFeaturesRemaining, setNumFeaturesRemaining] = useState(0);
  const [surveyFeatures, setSurveyFeatures] = useState([] as SurveyFeatureDTO[]);
  const [createSurveyFeature] = useMutation(CREATE_SURVEY_FEATURE);
  const [updateSurveyFeature] = useMutation(UPDATE_SURVEY_FEATURE);
  const [deleteSurveyFeature] = useMutation(DELETE_SURVEY_FEATURE);
  const {
    data: surveyFeaturesQueryData,
    loading: isSurveyFeaturesQueryLoading,
    error: surveyFeaturesQueryError,
    refetch: refetchSurveyFeatures
  } = useQuery<GetSurveyFeaturesDTO>(GET_SURVEY_FEATURES, {
    variables: {
      surveyId: surveyId,
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (surveyFeaturesQueryData) {
      setSurveyFeatures(surveyFeaturesQueryData.survey.features);
      setNumFeaturesRemaining(
        surveyFeaturesQueryData.survey.account.maxFeatures - surveyFeatures.length
      );
    }
  }, [surveyFeaturesQueryData, surveyFeatures]);

  const pullUpdatedSurveyFeatures = async () => {
    await refetchSurveyFeatures({
        surveyId: surveyId,
    });

    if (onSurveyFeaturesUpdated) {
      onSurveyFeaturesUpdated(surveyFeatures);
    }
}

  const showErrorMessage = () => {
    enqueueSnackbar(`Error while attempting to Create poll feature`, {
      variant: "error",
    });
  };

  const handleCreateFeature = async (newFeature: SurveyFeatureDTO) => {
    try {
      newFeature.surveyId = surveyId;
      await logic.createSurveyFeatureInDB(
        newFeature,
        createSurveyFeature
      );
      await pullUpdatedSurveyFeatures();
    } catch (e) {
      showErrorMessage();
    }
  };

  const handleUpdateFeature = async (editedFeature: SurveyFeatureDTO) => {
    try {
      await logic.updateSurveyFeatureInDB(editedFeature, updateSurveyFeature);
      await pullUpdatedSurveyFeatures();
    } catch (e) {
      showErrorMessage();
    }
  };

  const handleDeleteFeature = async (featureId: string) => {
    try {
      await logic.deleteSurveyFeatureInDB(featureId, deleteSurveyFeature);
      await pullUpdatedSurveyFeatures();
    } catch (e) {
      showErrorMessage();
    }
  };

  if (isSurveyFeaturesQueryLoading) {
    return <LoadingIndicator />;
  }

  if (surveyFeaturesQueryError) {
    return <p>{JSON.stringify(surveyFeaturesQueryError)}</p>;
  }

  if (isShowDetailView) {
    return (
      <FeaturesCard
        numFeaturesRemaining={numFeaturesRemaining}
        features={surveyFeatures}
        createFeature={handleCreateFeature}
        updateFeature={handleUpdateFeature}
        deleteFeature={handleDeleteFeature}
        isEditDisabled={isEditDisabled}
      />
    );
  } else {
    return (
      <SurveyStepperAddFeatures
        numFeaturesRemaining={numFeaturesRemaining}
        features={surveyFeatures}
        createFeature={handleCreateFeature}
        updateFeature={handleUpdateFeature}
        deleteFeature={handleDeleteFeature}
      />
    );
  }
}
