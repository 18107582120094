import { Button, Card, CardContent, CircularProgress, Divider, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { ArrowBackIos, Create, Send } from "@material-ui/icons";
import React, { FunctionComponent, useState } from "react";
import PageHeaderCard from "src/Components/core/PageHeaderCard";
import { AddEditDecisionCriteriaContainer, AddEditFeaturesContainer, CompletedRespondentsCardContainer } from "src/containers";
import { SurveyDTO } from "src/models";
import theme from 'src/Theme';
import { ResultsCard } from ".";
import EditSurveyModal from "../Surveys/EditSurveyModal";
import { useSnackbar } from 'notistack';
import { SendSurveyInvitationsDTO } from 'src/operations';
import { DeleteButtonWithConfirmation } from '..';
import { FormValidationTooltip } from '../core';

interface ISurveyDetailsProps {
  survey: SurveyDTO;
  reloadSurvey: () => Promise<void>;
  respondentsTotal: number;
  responsesTotal: number;
  saveSurvey: (survey: SurveyDTO) => Promise<void>;
  deleteSurvey: () => Promise<void>;
  surveyCompletionRed: number;
  surveyCompletionYellow: number;
  sendSurveyInvitations: () => Promise<SendSurveyInvitationsDTO>
}

export const SurveyDetails: FunctionComponent<ISurveyDetailsProps> = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSendingInvites, setIsSendingInvites] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isContainerLargerThanSM = useMediaQuery(theme.breakpoints.up('md'));
  const {
    survey,
    reloadSurvey,
    respondentsTotal,
    responsesTotal,
    saveSurvey,
    surveyCompletionRed,
    surveyCompletionYellow,
    sendSurveyInvitations,
    deleteSurvey,
  } = props;
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const sendInvitationsToAll = async () => {
    setIsSendingInvites(true);
    try {
      await sendSurveyInvitations();
      enqueueSnackbar('Poll Invitations Sent', { variant: 'success' });
      reloadSurvey();
    } catch (error) {
      enqueueSnackbar('An error occurred while trying to send poll Invitations', { variant: 'error' });
    }
    setIsSendingInvites(false);

  }

  const surveyActionButtons = (
    <Grid item container direction="row" alignItems='center'>
      <Grid item>
        <FormValidationTooltip isVisible={!!survey.dateSent} shouldShowAlternateText={true} alternateText="Cannot delete poll after invitations have been sent.">
          <DeleteButtonWithConfirmation onDelete={deleteSurvey} itemName="poll" isDisabled={!!survey.dateSent} />
        </FormValidationTooltip>
      </Grid>
      <Grid item style={{ marginRight: theme.spacing(1) }}>
        <Button
          startIcon={<Send />}
          variant="contained"
          color="primary"
          onClick={sendInvitationsToAll}
          disabled={isSendingInvites || !!survey.dateSent  || !survey.isValid}
        >
          {isSendingInvites ? <div style={{ minWidth: 84 }} ><CircularProgress size={18} /></div>: 'Send Invites'}
        </Button>
      </Grid>
      <Grid item>
        <Button
          startIcon={<Create />}
          variant="outlined"
          color="primary"
          onClick={openModal}
        >
          Edit Poll
        </Button>
      </Grid>
    </Grid>
  );

  const pullUpdatedSurveyData = () => {
    reloadSurvey();
  }

  return (
    <>
      <Button
        startIcon={<ArrowBackIos style={{ fontSize: "14px" }} />}
        href="/Surveys"
        color="primary"
      >
        Polls
      </Button>
      <PageHeaderCard
        title={survey.name}
        titleComponent={surveyActionButtons}
        isTitleComponentPushedRight={true}
      />
      <Typography>{survey.description}</Typography>
      <Typography variant="body2" style={{ color: theme.palette.primary.main, padding: `${theme.spacing(2)}px 0px`, display: 'none' }} >{survey.projectName}</Typography>
      <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(3) }} />
      <Grid container direction="row">
        <Grid item sm={12} md={6} container  direction="column">
          <Grid item style={isContainerLargerThanSM ? { marginRight: theme.spacing(1), marginBottom: theme.spacing(1) } : { marginBottom: theme.spacing(1) }} >
            <AddEditFeaturesContainer
              surveyId={survey.id}
              onSurveyFeaturesUpdated={pullUpdatedSurveyData}
              isShowDetailView={true}
              isEditDisabled={survey.isLocked || survey.hasResponses}

            />
          </Grid>
          <Grid item style={isContainerLargerThanSM ? { marginRight: theme.spacing(1), marginTop: theme.spacing(1) } : { marginBottom: theme.spacing(1) }}>
            <AddEditDecisionCriteriaContainer
              surveyId={survey.id}
              onDecisionCriteriaUpdated={pullUpdatedSurveyData}
              isShowDetailView={true}
              isEditDisabled={survey.isLocked || survey.hasResponses}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} md={6} container  direction="column">
          <Grid item style={isContainerLargerThanSM ? { marginLeft: theme.spacing(1), marginBottom: theme.spacing(1) } : { marginBottom: theme.spacing(1) }}>
            <ResultsCard
              surveyId={survey.id}
              respondentsTotal={respondentsTotal}
              responsesTotal={responsesTotal}
              dateLocked={survey.dateLocked}
              surveyCompletionRed={surveyCompletionRed}
              surveyCompletionYellow={surveyCompletionYellow}
            />
          </Grid>
          <Grid item style={isContainerLargerThanSM ? { marginLeft: theme.spacing(1), marginTop: theme.spacing(1) } : { marginBottom: theme.spacing(1) }}>
            <Card>
              <CardContent>
                <CompletedRespondentsCardContainer
                surveyId={survey.id}
                isRespondentQuantityVisible={true}
                isIconVisible={true}
                onEditRespondentModalClose={pullUpdatedSurveyData}
                isAddRespondentButtonVisible={true}
                isSendInvitationsVisible={!!survey.dateSent}
                isSendInvitationsDisabled={survey.isLocked || !survey.isValid}
                isEditDisabled={survey.isLocked}
                onRemindAllClicked={sendInvitationsToAll}
              />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <EditSurveyModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        existingSurvey={survey}
        saveSurvey={saveSurvey}
      />
    </>
  );
};
