import { gql } from "@apollo/client";

export const GET_DECISION_CRITERIA_STAKEHOLDER_RANKING_DATA = gql`
query getDecisionCriteriaStakeholderRanking($surveyId:String!, $respondentId:String!){
    overall:surveyRanking(surveyId:$surveyId) {
      blendedRankings{
        featureName
        rank
      }
      respondentRanking(id:$respondentId) {
        respondent{
          id
          firstName
          lastName
        }
        featureRankings{
          featureName
          rank
        }
      }
      customerDecisionCriteriaRanking{
        blendedRankings{
          featureName
          rank
        }
      }
    }
  }
`;

export interface GetDecisionCriteriaStakeholderRankingDataDTO {
    overall: {
        blendedRankings: {
          featureName: string;
          rank: number;
        }[]
        respondentRanking: {
            respondent: {
                id: string;
                firstName: string;
                lastName: string;
            },
            featureRankings: {
                featureName: string;
                rank: number;
            }[],
        }
        customerDecisionCriteriaRanking: {
          blendedRankings: {
            featureName: string;
            rank: number;
          }[]
        }
    }
}
