import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import _ from 'lodash';
import { SurveyFeatureDTO, SurveyRespondentDTO, SurveyRespondentStatusOptions, SurveyResponseDTO } from 'src/models';
import { GetTakeSurveyDataDTO, UpdateSurveyRespondentDTO } from 'src/operations';

export class TakeSurveyContainerLogic {
    public getFeaturePairs(features: Partial<SurveyFeatureDTO>[], existingResponses?: Partial<SurveyResponseDTO>[]) {
        const featureIds = features.map(f => f.id);
        const allPossibleFeaturePairTable: string[][][] = this.generateAllPossibleFeaturePairs(featureIds)
        let validFeaturePairs = this.filterOutDuplicatePairs(allPossibleFeaturePairTable);

        if (existingResponses && existingResponses.length > 0) {
            validFeaturePairs = this.substituteExistingResponses(validFeaturePairs, existingResponses);
        } else {
            validFeaturePairs = this.randomizeFeaturePairs(validFeaturePairs);
        }

        const mappedFeaturePairs = validFeaturePairs.map(featurePair => {
            return featurePair.map(featureId => {
                return features.find(feature => feature.id === featureId);
            })
        })

        return mappedFeaturePairs;
    }

    private generateAllPossibleFeaturePairs(featureIds: string[]): string[][][] {
        return featureIds.map(featureX => {
            const featurePairs: string[][] = [];
            featureIds.forEach(featureY => {
                featurePairs.push([featureX, featureY]);
            });
            return featurePairs;
        });
    }

    private filterOutDuplicatePairs(allPossibleFeaturePairTable: string[][][]) {
        const validFeaturePairs: string[][] = [];
        allPossibleFeaturePairTable.forEach((featurePairColumn, columnNumber) => {
            if (columnNumber === 0) {
                return;
            }
            featurePairColumn.forEach((featurePair, rowNumber) => {
                if (rowNumber < columnNumber) {
                    validFeaturePairs.push(featurePair);
                }
            });
        });
        return validFeaturePairs;
    }

    private substituteExistingResponses(validFeaturePairs: string[][], existingResponses: Partial<SurveyResponseDTO>[]) {
        const existingResponseFeaturePairs: string[][] = [];
        existingResponses.forEach((existingResponse) => {
            const responseFeatures = [existingResponse.surveyFeatureId, existingResponse.comparedToSurveyFeatureId];
            const isFeaturePairAdded = existingResponseFeaturePairs.some((responseFeaturePair) => responseFeatures.includes(responseFeaturePair[0]) && responseFeatures.includes(responseFeaturePair[1]));
            if (!isFeaturePairAdded) {
                existingResponseFeaturePairs.push(responseFeatures);
            }
        });

        const unusedFeaturePairs = validFeaturePairs.filter(featurePair => {
            const isAlreadyUsed = existingResponses.some(response => featurePair.includes(response.surveyFeatureId) && featurePair.includes(response.comparedToSurveyFeatureId));
            return !isAlreadyUsed;
        });

        const randomizedMappedFeaturePairs = this.randomizeFeaturePairs(unusedFeaturePairs);

        validFeaturePairs = [...existingResponseFeaturePairs, ...randomizedMappedFeaturePairs];
        return validFeaturePairs;
    }

    private randomizeFeaturePairs(featurePairs: string[][]) {
        return featurePairs.map((featurePair) => {
            const shouldFlipOrder = _.random(1);
            if (shouldFlipOrder) {
                _.reverse(featurePair);
            }
            return featurePair;
        }).sort(() => Math.random() - 0.5);
    }

    public setSurveyRespondentStatus(data: GetTakeSurveyDataDTO, updateSurveyRespondent: (options?: MutationFunctionOptions<UpdateSurveyRespondentDTO>) => Promise<FetchResult>, newStatus: SurveyRespondentStatusOptions) {
        const currentSurveyRespondent = data?.surveyForResponse;
        const updatedSurveyRespondent: Partial<SurveyRespondentDTO> = {
            surveyId: currentSurveyRespondent.surveyId,
            respondentId: currentSurveyRespondent.respondentId,
            status: newStatus,
        };
        updateSurveyRespondent({
            variables: {
                surveyRespondent: updatedSurveyRespondent,
                surveyRespondentId: currentSurveyRespondent.id,
            }
        });
    }
}