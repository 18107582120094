import {
  Card,
  CardContent,
  Box,
  Typography,
  LinearProgress,
  withStyles,
} from "@material-ui/core";
import * as React from "react";
import PageHeaderCard from "../core/PageHeaderCard";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { TrendingUp } from '@material-ui/icons';
import theme from 'src/Theme';

const RedLinearProgress = withStyles({
  bar: {
    backgroundColor: "red",
  },
})(LinearProgress);

const YellowLinearProgress = withStyles({
  bar: {
    backgroundColor: "yellow",
  },
})(LinearProgress);

const GreenLinearProgress = withStyles({
  bar: {
    backgroundColor: "green",
  },
})(LinearProgress);

interface IResultsCardProps {
  respondentsTotal: number;
  responsesTotal: number;
  dateLocked: Date;
  surveyCompletionRed: number;
  surveyCompletionYellow: number;
  surveyId: string;
}

export class ResultsCard extends React.Component<IResultsCardProps> {
  public render() {
    const {
      respondentsTotal,
      responsesTotal,
      dateLocked,
      surveyCompletionYellow,
      surveyCompletionRed,
      surveyId,
    } = this.props;
    const percentResponded = () => {
      if (respondentsTotal === 0) {
        return 0
      }
      return (responsesTotal / respondentsTotal) * 100;
    }

    const dateLockedObject = new Date(dateLocked);
    const lockedDate = DateTime.fromJSDate(dateLockedObject);
    const dateString = lockedDate.toLocaleString(DateTime.DATE_MED);
    const resultsLock = (
      <Typography variant="body2" style={{ color: "grey" }}>
        {" "}
        Results locked on {dateString}{" "}
      </Typography>
    );

    let linearBar: {};
    if (percentResponded() <= surveyCompletionRed) {
      linearBar = (
        <RedLinearProgress
          variant="determinate"
          value={percentResponded()}
          style={{ height: 10, width: "100%", backgroundColor: "#DFE5FD" }}
        />
      );
    } else if (percentResponded() <= surveyCompletionYellow) {
      linearBar = (
        <YellowLinearProgress
          variant="determinate"
          value={percentResponded()}
          style={{ height: 10, width: "100%", backgroundColor: "#DFE5FD" }}
        />
      );
    } else {
      linearBar = (
        <GreenLinearProgress
          variant="determinate"
          value={percentResponded()}
          style={{ height: 10, width: "100%", backgroundColor: "#DFE5FD" }}
        />
      );
    }

    const cardContent = () => {
      return (
        <Card>
          <CardContent>
            <PageHeaderCard
              icon={<TrendingUp style={{ color: "grey" }} />}
              title={"Results"}
              isTitleComponentPushedRight={true}
              titleComponent={dateLocked ? resultsLock : null}
              titleVariant="h5"
            />
            <Box m={2}>
              <Typography display="inline">
                {responsesTotal}/{respondentsTotal} have responded to the poll.
              </Typography>
              { responsesTotal > 0 &&
                <Typography style={{ color: theme.palette.primary.main }} display="inline">
                  {" "} View Results
                </Typography>
              }

            </Box>
            <Box m={2}>{linearBar}</Box>
          </CardContent>
        </Card>
      )
    }

    return responsesTotal > 0 ? (
        <Link
          to={`/surveyResults/${surveyId}`}
          style={{ textDecoration: "none" }}
        >
          {cardContent()}
        </Link>
      ) : (
        cardContent()
      )}
}
