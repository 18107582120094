import { AppBar, Card, CardContent, Grid, LinearProgress, Toolbar, Typography } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { TakeSurveyDecisionCriteriaContainer } from 'src/containers/TakeSurveyDecisionCriteriaContainer';
import { DecisionCriteriaDTO, SurveyFeatureDTO, SurveyResponseDTO } from 'src/models';
import theme from 'src/Theme';
import LoadingIndicator from '../core/LoadingIndicator';
import { TakeSurveyActions } from './TakeSurveyActions';
import { TakeSurveyTitle } from './TakeSurveyTitle';

interface ITakeSurveyProps {
    goToNextStep: () => void;
    cancelSurvey: () => void;
    currentStep: number;
    numSteps: number;
    featurePairs: Partial<SurveyFeatureDTO>[][];
    decisionCriteria: Partial<DecisionCriteriaDTO>[];
    responses: Partial<SurveyResponseDTO>[];
    surveyId: string;
    respondentId: string;
    respondentType: string;
    surveyRespondentId: string;
}

export const TakeSurvey: FunctionComponent<ITakeSurveyProps> = props => {
    const {
        goToNextStep,
        cancelSurvey,
        currentStep,
        featurePairs,
        decisionCriteria,
        responses,
        surveyId,
        respondentId,
        numSteps,
        surveyRespondentId,
        respondentType
    } = props;
    const [progressPercentage, setProgressPercentage] = useState(0);

    useEffect(() => {
        if (numSteps === null || numSteps === 0) {
            setProgressPercentage(0);
            return;
        }
        const percentage = (currentStep / numSteps) * 100;
        setProgressPercentage(percentage);
    }, [numSteps, currentStep, setProgressPercentage])


    const mapDecisionCriteriaToDecisionCriteriaSlider = (dc: Partial<DecisionCriteriaDTO>) => {
        const featureIds = featurePairs[currentStep - 1].map(f => f.id);
        const existingResponse = responses.find(r => featureIds.includes(r.surveyFeatureId) && featureIds.includes(r.comparedToSurveyFeatureId) && r.surveyDecisionCriteriaId === dc.id);
        return (
            <Card key={dc.id} style={{ margin: `${theme.spacing(1)}px 0` }} elevation={3}>
                <CardContent style={{ paddingBottom: 0 }}>
                    {respondentType === 'Customer' ? (
                            <Grid item style={{ textAlign: 'center'}}>
                                <Typography variant='body2' style={{ color: 'grey' }}>
                                    Slide towards the most important feature
                                </Typography>
                            </Grid>
                        ) : (
                            <>
                                <Grid item style={{ textAlign: 'center'}}>
                                    <Typography variant='body2' style={{ color: 'grey' }}>
                                        Slide towards the feature that will...
                                    </Typography>
                                </Grid>
                                <Grid item style={{ textAlign: 'center'}}>
                                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                        {dc.name}
                                    </Typography>
                                </Grid>
                            </>
                        )
                    }

                    <TakeSurveyTitle featurePair={featurePairs[currentStep - 1]} />
                    <div style={{ height: theme.spacing(2) }} ></div>
                    <TakeSurveyDecisionCriteriaContainer
                        decisionCriteria={dc}
                        existingResponse={existingResponse}
                        surveyId={surveyId}
                        respondentId={respondentId}
                        surveyRespondentId={surveyRespondentId}
                        featureIds={featureIds}
                        currentStep={currentStep} />
                </CardContent>
            </Card>
        );
    };

    const filterDecisionCriteriaByRespondentType = (dc: Partial<DecisionCriteriaDTO>): boolean => {
        if (respondentType === 'Customer') {
            return dc.isLocked;
        } else {
            return !dc.isLocked;
        }
    };

    return (
        <Grid container direction="column">
            <AppBar position="static" elevation={0} style={{ borderBottom: 'none' }}>
                <Toolbar variant="dense">
                    <Grid container direction="row">
                        <Grid item xs={2} md={1} />
                        <Grid item xs={8} md={10} style={{ textAlign: 'center' }}>
                            <Typography style={{ fontWeight: 'bold' }}>{numSteps !== null ? `${currentStep}/${numSteps}` : 'Loading...'}</Typography>
                            <LinearProgress value={progressPercentage} variant={numSteps !== null ? 'determinate' : 'indeterminate'} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={2} md={1} />
                    </Grid>
                </Toolbar>
            </AppBar>
            {decisionCriteria && featurePairs[currentStep - 1]
                ?
                    decisionCriteria.filter(filterDecisionCriteriaByRespondentType)
                        .map(mapDecisionCriteriaToDecisionCriteriaSlider)
                :
                    <LoadingIndicator />
            }
            <div style={{ height: theme.spacing(1) }}></div>
            <TakeSurveyActions
                onNextClick={goToNextStep}
                onCancelClick={cancelSurvey}
                isFinalStep={currentStep === numSteps}
            />
        </Grid>
    )
}