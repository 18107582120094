import { DeleteSurveyRespondentDTO, CreateSurveyRespondentDTO } from 'src/operations';
import { MutationFunctionOptions, FetchResult } from '@apollo/client';
import { SurveyRespondentDTO } from 'src/models/SurveyRespondentDTO';
import _ from 'lodash';

export class RespondentsCardContainerLogic {
    public deleteSurveyRespondentInState(surveyRespondents: Partial<SurveyRespondentDTO>[], surveyRespondentId: string, updateSurveyRespondents: (updatedSurveyRespondents: Partial<SurveyRespondentDTO>[]) => void) {
        const updatedSurveyRespondents = _.cloneDeep(surveyRespondents);
        const selectedSurveyRespondentIndex = updatedSurveyRespondents.findIndex(sr => sr.id === surveyRespondentId);
        updatedSurveyRespondents.splice(selectedSurveyRespondentIndex, 1);
        updateSurveyRespondents(updatedSurveyRespondents);
    }

    public async deleteSurveyRespondentInDB(deleteSurveyRespondent: (options?: MutationFunctionOptions) => Promise<FetchResult<DeleteSurveyRespondentDTO>>, surveyRespondentId: string) {
        await deleteSurveyRespondent({
            variables: {
                surveyRespondentId: surveyRespondentId
            }
        });
    }

    public createSurveyRespondentInState(surveyRespondentId: string, newSurveyRespondent: SurveyRespondentDTO, surveyRespondents: Partial<SurveyRespondentDTO>[], updateSurveyRespondents: (updatedSurveyRespondents: Partial<SurveyRespondentDTO>[]) => void) {
        newSurveyRespondent.id = surveyRespondentId;
        const updatedSurveyRespondents = _.cloneDeep(surveyRespondents);
        updatedSurveyRespondents.push(newSurveyRespondent);
        updateSurveyRespondents(updatedSurveyRespondents);
    }

    public async createSurveyRespondentInDB(createSurveyRespondent: (options?: MutationFunctionOptions) => Promise<FetchResult<CreateSurveyRespondentDTO>>, newSurveyRespondent: SurveyRespondentDTO) {
        return await createSurveyRespondent({
            variables: {
                surveyRespondent: newSurveyRespondent,
            }
        });
    }
}