import { useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useEffect } from 'react';
import { GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from 'src/Components/core/LoadingIndicator';
import { TakeSurveyResults } from 'src/Components/TakeSurvey/TakeSurveyResults';
import { GetRespondentTypeDTO, GET_RESPONDENT_TYPE_PUBLIC } from 'src/operations';

interface ITakeSurveyResultsContainerProps {
    surveyId: string;
    respondentId: string;
}

export const TakeSurveyResultsContainer: FunctionComponent<ITakeSurveyResultsContainerProps> = props => {
    const { surveyId, respondentId } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { data: respondentData, loading: isLoading, error, refetch } = useQuery<GetRespondentTypeDTO>(GET_RESPONDENT_TYPE_PUBLIC, {
        variables: {
            respondentId: respondentId,
        }
    })

    const reloadResults = async () => {
        await refetch({
            respondentId: respondentId,
        });
    }

    useEffect(() => {
        if (error) {
            enqueueSnackbar('An error occurred while trying to retrieve respondent data.', { variant: 'error' });
            console.error(error);
        }
    }, [error, enqueueSnackbar]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='poll results' />;
    }

    return <TakeSurveyResults
        surveyId={surveyId}
        respondentId={respondentId}
        respondentType={respondentData?.respondent?.respondentType?.description}
    />
}