import { FunctionComponent, useEffect } from 'react';
import initHelpHero from 'helphero';
import { useQuery } from '@apollo/client';
import { GetSurveyRespondentUnauthenticatedDTO, GET_SURVEY_RESPONDENT_UNAUTHENTICATED } from 'src/operations';

const hlp = initHelpHero(process.env.REACT_APP_HELP_HERO_APP_ID);

interface IHelpHeroRespondentAuthenticatorProps {
    surveyRespondentId: string;
}

export const HelpHeroRespondentAuthenticator: FunctionComponent<IHelpHeroRespondentAuthenticatorProps> = props => {
    const { surveyRespondentId } = props;
    const { data: getSurveyRespondentData } = useQuery<GetSurveyRespondentUnauthenticatedDTO>(GET_SURVEY_RESPONDENT_UNAUTHENTICATED, {
        variables: {
            surveyRespondentId: surveyRespondentId,
        }
    });

    useEffect(() => {
        if (getSurveyRespondentData) {
            const respondent = getSurveyRespondentData.getSurveyRespondentForResponse.respondent;
            hlp.identify(respondent.id, {
                firstName: respondent.firstName,
                lastName: respondent.lastName,
                respondentType: respondent.respondentType.description,
            });
        }
    }, [getSurveyRespondentData]);

    return null;
}