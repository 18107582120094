import * as React from 'react';
import routes from '../../../Routes';
import Router from '../../../routing/Router';
import { IContainerProps, IShellProps } from './types'
import { createBrowserHistory } from 'history';

const container: React.SFC<IContainerProps> = (props) => (
    <div
        className={props.className}
        style={{
            height: '100%',
            marginLeft: `${props.isLoggedIn ? props.drawerWidth : 0}px`,
        }}
    >
        {props.children}
    </div>
);

class Shell extends React.Component<IShellProps> {

    public render() {
        const {
            drawerWidth,
            isLoggedIn,
        } = this.props;

        const history = createBrowserHistory();

        return (
            <Router
                container={container}
                containerProps={{ drawerWidth, isLoggedIn }}
                routes={routes}
                history={history}
            ></Router>
        );
    }
}

export default Shell;
