import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import { GET_SURVEY, GetSurveyDTO } from "src/operations/queries/getSurvey";
import { useHistory, useParams } from "react-router-dom";
import { SurveyRespondentStatusOptions } from "src/models";
import { DELETE_SURVEY, SendSurveyInvitationsDTO, SEND_SURVEY_INVITATIONS, UPDATE_SURVEY } from "src/operations";
import { SurveyDTO } from "src/models";
import { SurveyDetailsLogic } from "./SurveyDetailsLogic";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { GraphQLErrorIndicator, SurveyDetails } from "src/Components";
import {
  GET_GLOBAL_DEFAULTS,
  GlobalDefaultsDTO,
} from "src/operations/queries/getGlobalDefaults";

export default function SurveyDetailsContainer() {
  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const {data, loading: isSurveyLoading, error, refetch} = useQuery<GetSurveyDTO>(GET_SURVEY, {
    variables: {
      surveyId: surveyId,
    },
    fetchPolicy: 'network-only'
  });
  const getGlobalDefaultsQuery = useQuery(GET_GLOBAL_DEFAULTS);
  const { enqueueSnackbar } = useSnackbar();
  const logic = new SurveyDetailsLogic();
  const isGlobalDefaultsLoading = getGlobalDefaultsQuery.loading;
  const globalDefault: GlobalDefaultsDTO = getGlobalDefaultsQuery.data;
  const [updateSurvey] = useMutation(UPDATE_SURVEY);
  const [deleteSurvey] = useMutation(DELETE_SURVEY);
  const [sendSurveyInvitations] = useMutation<SendSurveyInvitationsDTO>(SEND_SURVEY_INVITATIONS);
  const [isReloading, setIsReloading] = useState(false);

  const reloadResults = async () => {
    setIsReloading(true);
    await refetch({
      surveyId: surveyId
    });
    setIsReloading(false);
  }

  if (isSurveyLoading || isGlobalDefaultsLoading || isReloading) {
    return <LoadingIndicator />;
  }

  if (error || data === undefined) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='poll details' />;
  }

  const respondentsTotal = data.survey.respondents.length;
  let responsesTotal = 0;
  data.survey.respondents.forEach(function (surveyRespondent) {
    if (surveyRespondent.status === SurveyRespondentStatusOptions.completed) {
      responsesTotal++;
    }
  });

  let surveyCompletionRed;
  let surveyCompletionYellow;

  for (let i = 0; i < globalDefault.globalDefaults.length; i++) {
    if (globalDefault.globalDefaults[i].name === "SurveyCompletionRed") {
      surveyCompletionRed = globalDefault.globalDefaults[i].value;
    }
    if (globalDefault.globalDefaults[i].name === "SurveyCompletionYellow") {
      surveyCompletionYellow = globalDefault.globalDefaults[i].value;
    }
  }

  const saveSurvey = async (newSurvey: SurveyDTO) => {
    const formattedSurvey = _.cloneDeep(newSurvey);
    delete formattedSurvey.features;
    delete formattedSurvey.decisionCriteria;
    delete formattedSurvey.respondents;
    delete formattedSurvey.responses;
    delete formattedSurvey.__typename;
    delete formattedSurvey.id;
    delete formattedSurvey.isLocked;
    delete formattedSurvey.hasResponses;
    delete formattedSurvey.projectName;
    delete formattedSurvey.isValid;

    try {
      await logic.updateSurveyInDB(updateSurvey, formattedSurvey, newSurvey.id);
      reloadResults();
    } catch {
      enqueueSnackbar(`Error while attempting to Update poll.`, {
        variant: "error",
      });
    }
  };

  const handleSendSurveyInvitations = async () => {
    const result = await sendSurveyInvitations({
      variables: {
        surveyId: surveyId
      }
    })
    return result.data
  }

  const handleDeleteSurvey = async () => {
    await deleteSurvey({
      variables: {
        surveyId: surveyId
      }
    })
    history.push("/Surveys")
  }

  return (
    <SurveyDetails
      survey={data.survey}
      respondentsTotal={respondentsTotal}
      responsesTotal={responsesTotal}
      reloadSurvey={reloadResults}
      saveSurvey={saveSurvey}
      deleteSurvey={handleDeleteSurvey}
      surveyCompletionRed={surveyCompletionRed}
      surveyCompletionYellow={surveyCompletionYellow}
      sendSurveyInvitations={handleSendSurveyInvitations}
    />
  );
}
