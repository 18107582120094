import { gql } from "@apollo/client";

export const GET_STAKEHOLDER_DECISION_CRITERIA_PRIORITIZATION_DATA = gql`
query getSurveyCustomerPrioritization($surveyId:String!){
  survey(id:$surveyId) {
    stakeholders:groups(filterBy:"name:Stakeholder"){
      surveyRankings {
        decisionCriteriaRankings{
          decisionCriteria{
            id
            name
          }
          blendedRankings{
            rank
            featureName
          }
          respondentRankings{
            respondent{
              id
              firstName
              lastName
            }
            featureRankings{
              featureName
              rank
            }
          }
        }
      }
    }
  }
}
`;

export interface GetStakeholderDecisionCriteriaPrioritizationDataDTO {
  survey: {
    stakeholders: [{
      surveyRankings: {
        decisionCriteriaRankings: DecisionCriteriaRankingDTO[];
      }
    }]
  }
}

export interface DecisionCriteriaRankingDTO {
  decisionCriteria: {
    id: string;
    name: string;
  }
  blendedRankings: [{
    featureName: string;
    rank: number;
  }]
  respondentRankings: [{
    respondent: {
      id: string;
      firstName: string;
      lastName: string;
    }
    featureRankings: [{
      featureName: string;
      rank: number;
    }]
  }]
}
