import { gql } from "@apollo/client";

export const GET_ACCOUNT_MAX_DECISION_CRITERIA = gql`
    query accountMaxDecisionCriteria($accountId:String!){
        account(id:$accountId){
            maxDecisionCriteria
        }
    }`;

export interface GetAccountMaxDecisionCriteriaDTO {
    account: {
        maxDecisionCriteria: number;
    }
}