import { useQuery } from "@apollo/client";
import React, { FunctionComponent } from "react";
import { useParams } from 'react-router-dom';
import { GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import { StakeholderPrioritization } from 'src/Components/Graphs';
import { GetStakeholderPrioritizationDataDTO, GET_STAKEHOLDER_PRIORITIZATION_DATA } from "src/operations";

interface IStakeholderPrioritizationContainerProps {
    surveyId: string;
}

export const StakeholderPrioritizationContainer: FunctionComponent<IStakeholderPrioritizationContainerProps> = () => {
  const { surveyId } = useParams<{ surveyId: string }>();

  const {data, loading: isLoading, error, refetch} = useQuery<GetStakeholderPrioritizationDataDTO>(GET_STAKEHOLDER_PRIORITIZATION_DATA, {
    variables: {
      surveyId: surveyId
    },
  });

  const reloadResults = async () => {
    await refetch({
      surveyId: surveyId
    });
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || data === undefined) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='stakeholder blended average graph' />;
  }

  return (
    <StakeholderPrioritization
      chartData={data}
    />
  );
}
