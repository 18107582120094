import { Avatar } from "@material-ui/core";
import React from "react";

export interface IGeneratedAvatarProps {
  name?: string;
}

function GeneratedAvatar(props: IGeneratedAvatarProps) {
  const { name } = props;

  const getRandomRGBColor = (seed?: number) => {
    if (seed === undefined) {
      seed = Math.round(Math.random() * 255);
    } else {
      seed = (Math.abs(seed) * 100) % 256;
    }
    const r = seed;
    const g = (seed * 2) % 256;
    const b = (seed * 3) % 256;

    return `rgba(${r}, ${g}, ${b}, 1)`;
  };

  return (
    <Avatar
      style={{
        background: `conic-gradient(from 180deg, ${getRandomRGBColor(
          name.length * 4
        )}, ${getRandomRGBColor(name.length)})`,
        height: "56px",
        width: "56px",
      }}
    >
      <div></div>
    </Avatar>
  );
}

export default GeneratedAvatar;
