import { FetchResult, useMutation, useQuery } from "@apollo/client";
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from "react";
import { GraphQLErrorIndicator } from 'src/Components';
import { RespondentsCard } from 'src/Components/Surveys';
import { RespondentDTO } from 'src/models';
import { SurveyRespondentDTO } from 'src/models/SurveyRespondentDTO';
import { CreateSurveyRespondentDTO, CREATE_SURVEY_RESPONDENT, DELETE_SURVEY_RESPONDENT } from 'src/operations';
import { GetAllRespondentsDTO, GET_ALL_RESPONDENTS } from 'src/operations/queries';
import { RespondentsCardContainerLogic } from './RespondentsCardContainerLogic';

export interface IRespondentCardContainerProps {
    surveyId: string;
    surveyRespondents?: Partial<SurveyRespondentDTO>[];
    updateSurveyRespondents?: (updatedSurveyRespondents?: Partial<SurveyRespondentDTO>[]) => void;
    isRespondentQuantityVisible?: boolean;
    isIconVisible?: boolean;
    onDataChange?: () => void;
    isEditDisabled?: boolean;
    isSendInvitationsDisabled?: boolean;
    isSendInvitationsVisible?: boolean;
    titlePrefix?: string;
    onSurveyRespondentAdd?: (surveyRespondentId: string) => void;
    onSurveyRespondentRemove?: (surveyRespondentId: string) => void;
}

export function RespondentsCardContainer(props: IRespondentCardContainerProps) {
    const logic = new RespondentsCardContainerLogic();
    const {
        surveyId,
        surveyRespondents,
        updateSurveyRespondents,
        isRespondentQuantityVisible,
        isIconVisible,
        onDataChange,
        isEditDisabled,
        isSendInvitationsDisabled,
        isSendInvitationsVisible,
        titlePrefix,
        onSurveyRespondentAdd,
        onSurveyRespondentRemove,
    } = props;

    const [searchFilter, setSearchFilter] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [sortedRespondents, setSortedRespondents] = useState([] as Partial<RespondentDTO>[]);
    const [numRespondents, setNumRespondents] = useState(0);
    const [createSurveyRespondent] = useMutation(CREATE_SURVEY_RESPONDENT);
    const [deleteSurveyRespondent] = useMutation(DELETE_SURVEY_RESPONDENT);
    const { enqueueSnackbar } = useSnackbar();
    const {data, error, refetch} = useQuery<GetAllRespondentsDTO>(GET_ALL_RESPONDENTS, {
        variables: {
            surveyId: surveyId,
            filter: `firstName:*${searchFilter}* OR lastName:*${searchFilter}*`
        }
    });

    const handleCreateSurveyRespondent = async (respondent: Partial<RespondentDTO>) => {
        setIsLoading(true)
        const newSurveyRespondent: SurveyRespondentDTO = {
            surveyId: surveyId,
            respondentId: respondent.id,
        }
        try {
            const result: FetchResult<CreateSurveyRespondentDTO> = await logic.createSurveyRespondentInDB(createSurveyRespondent, newSurveyRespondent);
            const surveyRespondentId = result.data.createSurveyRespondent.id;
            logic.createSurveyRespondentInState(surveyRespondentId, newSurveyRespondent, surveyRespondents, updateSurveyRespondents);
            if (onSurveyRespondentAdd) {
                onSurveyRespondentAdd(surveyRespondentId)
            }
        } catch (e) {
            enqueueSnackbar(`Error while attempting to Create poll respondent.`, { variant: 'error' })
        }
        setIsLoading(false)
    }

    const handleDeleteSurveyRespondent = async (respondentId: string) => {
        setIsLoading(true)
        const doomedSurveyRespondent = surveyRespondents.find(sr => sr.respondentId === respondentId || sr.respondent?.id === respondentId);
        try {
            await logic.deleteSurveyRespondentInDB(deleteSurveyRespondent, doomedSurveyRespondent.id);
            logic.deleteSurveyRespondentInState(surveyRespondents, doomedSurveyRespondent.id, updateSurveyRespondents);
            if (onSurveyRespondentRemove) {
                onSurveyRespondentRemove(doomedSurveyRespondent.id)
            }
        } catch (e) {
            enqueueSnackbar(`Error while attempting to Delete poll respondent.`, { variant: 'error' })
            console.error(e);
        }
        setIsLoading(false)
    }

    const reloadData = async () => {
        await refetch({
            surveyId: surveyId,
            filter: `firstName:*${searchFilter}* OR lastName:*${searchFilter}*`
        })
        if (onDataChange) {
            onDataChange()
        }
    }

    useEffect(() => {
        const respondents = data?.survey?.possibleRespondents;
        if (respondents) {
           const sortedRespondentList = _.cloneDeep(respondents).sort((r1, r2) => {
                const leftHandSide = r1.firstName.toLowerCase();
                const rightHandSide = r2.firstName.toLowerCase()
                const sorted = leftHandSide.localeCompare(rightHandSide);
                return sorted;
            });
            setSortedRespondents(sortedRespondentList);
        }
        const respondentCount = data?.survey?.surveyRespondentCount;
        if (respondentCount) {
            setNumRespondents(respondentCount)
        }
    }, [data, setSortedRespondents])

    if (error) {
        return <GraphQLErrorIndicator reloadResults={reloadData} componentName='respondent card' />;
    }

    return <RespondentsCard
        surveyId={surveyId}
        createSurveyRespondent={handleCreateSurveyRespondent}
        deleteSurveyRespondent={handleDeleteSurveyRespondent}
        respondentList={sortedRespondents}
        surveyRespondents={surveyRespondents}
        setSearchFilter={setSearchFilter}
        searchFilter={searchFilter}
        isRespondentQuantityVisible={isRespondentQuantityVisible}
        surveyRespondentCount={numRespondents}
        isIconVisible={isIconVisible}
        reloadRespondents={reloadData}
        isEditDisabled={isEditDisabled}
        isSendInvitationsDisabled={isSendInvitationsDisabled}
        isSendInvitationsVisible={isSendInvitationsVisible}
        isEditRespondentButtonVisible={false}
        isAddNewRespondentVisible={true}
        titlePrefix={titlePrefix}
        isLoading={isLoading}
    />;
}

