import { Button, Dialog, DialogActions, DialogContent, Grid, Menu, MenuItem, Tab, Tabs, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { EmojiPeople, Send } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useState } from 'react';
import { SurveyRespondentsList } from 'src/Components';
import { ConfirmationModal } from 'src/Components/core';
import { AddEditRespondentCustomerModalContainer, RespondentsCardContainer } from 'src/containers';
import { AddEditRespondentStakeholderModalContainer } from 'src/containers/AddEditRespondentStakeholderModalContainer';
import { RespondentDTO } from 'src/models';
import { SurveyRespondentDTO } from 'src/models/SurveyRespondentDTO';
import theme from 'src/Theme';
import PageHeaderCard from '../../core/PageHeaderCard';

export interface IRespondentsCardProps {
    surveyId: string;
    createSurveyRespondent?: (respondent: Partial<RespondentDTO>) => Promise<void>;
    deleteSurveyRespondent?: (respondentId: string) => Promise<void>;
    respondentList: Partial<RespondentDTO>[];
    surveyRespondents: Partial<SurveyRespondentDTO>[];
    setSearchFilter: React.Dispatch<React.SetStateAction<string>>;
    searchFilter: string;
    isIconVisible?: boolean;
    isRespondentQuantityVisible?: boolean;
    surveyRespondentCount?: number;
    isEditRespondentButtonVisible?: boolean;
    reloadRespondents?: () => void;
    showCompletedRespondents?: boolean;
    isEditDisabled?: boolean;
    isSendInvitationsDisabled?: boolean;
    isSendInvitationsVisible?: boolean;
    isAddNewRespondentVisible?: boolean;
    titlePrefix?: string;
    isLoading?: boolean;
    sendInvitationsToNewRespondents?: (surveyRespondentIds: string[]) => Promise<void>;
    onRemindAllClicked?: () => Promise<void>;
    onEditRespondentModalClose?: () => void;
}

export function RespondentsCard(props: IRespondentsCardProps) {
    const {
        surveyId,
        createSurveyRespondent,
        deleteSurveyRespondent,
        respondentList,
        surveyRespondents,
        setSearchFilter,
        searchFilter,
        isIconVisible,
        isRespondentQuantityVisible,
        isAddNewRespondentVisible,
        isEditRespondentButtonVisible,
        showCompletedRespondents,
        reloadRespondents,
        isEditDisabled,
        isSendInvitationsDisabled,
        isSendInvitationsVisible,
        titlePrefix,
        isLoading,
        sendInvitationsToNewRespondents,
        onRemindAllClicked,
        surveyRespondentCount,
        onEditRespondentModalClose
    }  = props;

    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [isAddEditCustomerModalOpen, setIsAddEditCustomerModalOpen] = useState(false);
    const [isSendInvitationConfirmationModalOpen, setIsSendInvitationConfirmationModalOpen] = useState(false);
    const [isAddEditStakeholderModalOpen, setIsAddEditStakeholderModalOpen] = useState(false);
    const [isEditRespondentModalOpen, setIsEditRespondentModalOpen] = useState(false);
    const [newlyAddedSurveyRespondentIds, setNewlyAddedSurveyRespondentIds] = useState([] as string[]);
    const [selectedRespondentId, setSelectedRespondentId] = useState(null as string);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { enqueueSnackbar } = useSnackbar();
    const isSmallerThanXSBreakpoint = useMediaQuery(theme.breakpoints.down('xs'));

    const showErrorMessage = (message: string) => {
        enqueueSnackbar(message, { variant: 'error' });
    }

    const handleEditRespondentsClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAddRespondentMenuClose = () => {
        setAnchorEl(null);
    };

    const openAddEditRespondentCustomerModal = (respondentId?: string) => {
        if (respondentId) {
            setSelectedRespondentId(respondentId)
        }
        setIsAddEditCustomerModalOpen(true);
        handleAddRespondentMenuClose()
    }

    const openAddEditRespondentStakeholderModal = (respondentId?: string) => {
        if (respondentId) {
            setSelectedRespondentId(respondentId)
        }
        setIsAddEditStakeholderModalOpen(true);
        handleAddRespondentMenuClose()
    }

    const closeAddEditRespondentCustomerModal = () => {
        setIsAddEditCustomerModalOpen(false);
        setSelectedRespondentId(null);
    }

    const closeAddEditRespondentStakeholderModal = () => {
        setIsAddEditStakeholderModalOpen(false);
        setSelectedRespondentId(null);
    }

    const handleTabChange = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
        setCurrentTabIndex(newTabIndex);
    }

    const handleAddCustomerClick = () => {
        openAddEditRespondentCustomerModal();
    }

    const handleAddStakeholderClick = () => {
        openAddEditRespondentStakeholderModal();
    }

    const handleRespondentEditClicked = (respondentId: string, respondentTypeName?: string) => {
        if (!respondentTypeName) {
            showErrorMessage('Unable to edit respondent because they have not been assigned a type');
        }
        if (respondentTypeName === 'Customer') {
            openAddEditRespondentCustomerModal(respondentId);
        } else if (respondentTypeName === 'Stakeholder') {
            openAddEditRespondentStakeholderModal(respondentId);
        } else {
            showErrorMessage('Unable to edit respondent because they have an unsupported type');
        }

    }

    const stakeholderRespondents = respondentList.filter(r => r.respondentType?.description === 'Stakeholder');
    const customerRespondents = respondentList.filter(r => r.respondentType?.description === 'Customer');

    const openEditRespondentModal = () => {
        setIsEditRespondentModalOpen(true)
    }

    const closeEditRespondentModal = (isConfirmed?: boolean) => {
        if (newlyAddedSurveyRespondentIds.length > 0 && isSendInvitationsVisible && !isConfirmed) {
            setIsSendInvitationConfirmationModalOpen(true);
            return;
        }
        setIsSendInvitationConfirmationModalOpen(false);
        setIsEditRespondentModalOpen(false);
        setNewlyAddedSurveyRespondentIds([]);
        if (onEditRespondentModalClose) {
            onEditRespondentModalClose()
         }
    }

    const handleRemindAllClicked = () => {
        onRemindAllClicked()
    }

    const respondentCardHeaderActions = () => {
        if(isEditRespondentButtonVisible){
            return(
                <Grid>
                    {isSendInvitationsVisible && (
                        <Button
                            color="primary"
                            startIcon={<Send />}
                            variant="contained"
                            onClick={handleRemindAllClicked}
                            disabled={isSendInvitationsDisabled}
                            style={{ marginRight: theme.spacing(1) }}
                        >
                            Remind All
                        </Button>
                    )}
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={openEditRespondentModal}
                        disabled={isEditDisabled}
                    >
                        Edit Respondents
                    </Button>
                </Grid>
            )
        }

    };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchFilter(event.target.value)
    }

    const handleSurveyRespondentAdd = (surveyRespondentId: string) => {
        setNewlyAddedSurveyRespondentIds([...newlyAddedSurveyRespondentIds, surveyRespondentId])
    }

    const handleSurveyRespondentRemove = (surveyRespondentId: string) => {
        setNewlyAddedSurveyRespondentIds(newlyAddedSurveyRespondentIds.filter(id => id !== surveyRespondentId))
    }

    const sendInvitationsToNewlyAddedRespondents = async () => {
        try {
            await sendInvitationsToNewRespondents(newlyAddedSurveyRespondentIds)
        } catch (error) {
            showErrorMessage('An error occurred while sending invitations to newly added respondents');
            console.error(error)
        }
    }

    return (
        <>
            <PageHeaderCard
                title={`${titlePrefix ? titlePrefix + ' ' : ''}Respondents ${isRespondentQuantityVisible ? `(${surveyRespondentCount})` : ''}`}
                icon={isIconVisible? <EmojiPeople style={{ color: "grey" }} /> : null}
                titleComponent={respondentCardHeaderActions()}
                isTitleComponentPushedRight={true}
                titleVariant="h5"
            />
            <TextField
                value={searchFilter}
                onChange={handleSearchChange}
                style={{ margin: `${theme.spacing(2)}px 0px` }}
                fullWidth
                placeholder="Search"
            />
            { isAddNewRespondentVisible &&
                <Grid container direction='row' alignItems='center'>
                    <Typography variant="body2">
                        Not listed below?
                    </Typography>
                    <Button
                        color="primary"
                        variant="text"
                        onClick={handleEditRespondentsClicked}
                        disabled={isEditDisabled}
                    >
                        Add a new respondent
                    </Button>
                </Grid>
            }
            <Tabs
                value={currentTabIndex}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
            >
                <Tab label="All" style={{textTransform: 'none'}} />
                <Tab label='Stakeholders' style={{textTransform: 'none'}} />
                <Tab label='Customers' style={{textTransform: 'none'}} />
            </Tabs>
            <Grid container>
                <Grid item xs={12} style={{maxHeight: 360, overflowX: 'auto'}}
                    hidden={currentTabIndex !== 0}
                >
                    <SurveyRespondentsList
                        respondentList={respondentList}
                        surveyRespondents={surveyRespondents}
                        addRespondent={createSurveyRespondent}
                        removeRespondent={deleteSurveyRespondent}
                        onEdit={handleRespondentEditClicked}
                        showCompletedList={showCompletedRespondents}
                        isEditDisabled={isEditDisabled}
                        isSendInvitationsDisabled={isSendInvitationsDisabled}
                        isSendInvitationsVisible={isSendInvitationsVisible}
                        isLoading={isLoading}
                    />
                </Grid>
                <Grid item xs={12} style={{maxHeight: 360, overflowX: 'auto'}}
                    hidden={currentTabIndex !== 1}
                >
                    <SurveyRespondentsList
                        respondentList={stakeholderRespondents}
                        surveyRespondents={surveyRespondents}
                        addRespondent={createSurveyRespondent}
                        removeRespondent={deleteSurveyRespondent}
                        onEdit={handleRespondentEditClicked}
                        showCompletedList={showCompletedRespondents}
                        isEditDisabled={isEditDisabled}
                        isSendInvitationsDisabled={isSendInvitationsDisabled}
                        isSendInvitationsVisible={isSendInvitationsVisible}
                        isLoading={isLoading}
                    />
                </Grid>
                <Grid item xs={12} style={{maxHeight: 360, overflowX: 'auto'}}
                    hidden={currentTabIndex !== 2}
                >
                    <SurveyRespondentsList
                        respondentList={customerRespondents}
                        surveyRespondents={surveyRespondents}
                        addRespondent={createSurveyRespondent}
                        removeRespondent={deleteSurveyRespondent}
                        onEdit={handleRespondentEditClicked}
                        showCompletedList={showCompletedRespondents}
                        isEditDisabled={isEditDisabled}
                        isSendInvitationsDisabled={isSendInvitationsDisabled}
                        isSendInvitationsVisible={isSendInvitationsVisible}
                        isLoading={isLoading}
                    />
                </Grid>
            </Grid>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleAddRespondentMenuClose}
                style={{ left: isSmallerThanXSBreakpoint ? '0px' : '170px', top: isSmallerThanXSBreakpoint ? '48px' : theme.spacing(2)}}
            >
                <MenuItem
                    onClick={handleAddCustomerClick}
                    style={{ color: theme.palette.primary.main }}
                >
                    Add Customer
                </MenuItem>
                <MenuItem
                    onClick={handleAddStakeholderClick}
                    style={{ color: theme.palette.primary.main }}
                >
                    Add Stakeholder
                </MenuItem>
            </Menu>
            <AddEditRespondentCustomerModalContainer
                isOpen={isAddEditCustomerModalOpen}
                respondentId={selectedRespondentId}
                closeModal={closeAddEditRespondentCustomerModal}
                reloadRespondents={reloadRespondents}
                isEditDisabled={isEditDisabled}
            />
            <AddEditRespondentStakeholderModalContainer
                isOpen={isAddEditStakeholderModalOpen}
                respondentId={selectedRespondentId}
                closeModal={closeAddEditRespondentStakeholderModal}
                reloadRespondents={reloadRespondents}
                isEditDisabled={isEditDisabled}
            />
            <Dialog open={isEditRespondentModalOpen} onClose={closeEditRespondentModal}>
                <DialogContent>
                    <RespondentsCardContainer
                        surveyId={surveyId}
                        surveyRespondents={surveyRespondents}
                        updateSurveyRespondents={reloadRespondents}
                        onDataChange={reloadRespondents}
                        titlePrefix="Edit"
                        onSurveyRespondentAdd={handleSurveyRespondentAdd}
                        onSurveyRespondentRemove={handleSurveyRespondentRemove}
                        isSendInvitationsVisible={isSendInvitationsVisible}
                    />
                </DialogContent>
                <DialogActions
                    style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(1) }}
                >
                    <Button color="primary" variant="outlined" onClick={() => {closeEditRespondentModal(false)}}>
                        {newlyAddedSurveyRespondentIds.length > 0 ? `Add (${newlyAddedSurveyRespondentIds.length}) Respondent${newlyAddedSurveyRespondentIds.length > 1 ? 's': ''}` : 'Close'}
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmationModal
                onActionConfirmed={sendInvitationsToNewlyAddedRespondents}
                isOpen={isSendInvitationConfirmationModalOpen}
                onClose={() => {closeEditRespondentModal(true)}}
                message={`Would you like to send invites now to the (${newlyAddedSurveyRespondentIds.length}) new Respondent${newlyAddedSurveyRespondentIds.length > 1 ? 's': ''}?`}
                alternateNoButtonMessage="Not Now"
            />
        </>
    )
}