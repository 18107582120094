import { gql } from '@apollo/client';

export const SEND_SURVEY_INVITATIONS = gql`
    mutation sendSurveyInvitations($surveyId:String!) {
        notifyRespondents(id:$surveyId)
    }
`

export interface SendSurveyInvitationsDTO {
    notifyRespondents: boolean;
}