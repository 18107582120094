import { Button, Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import theme from 'src/Theme';

interface IGraphQLErrorIndicatorProps {
    reloadResults: () => Promise<any>;
    componentName?: string;
}

export const GraphQLErrorIndicator: FunctionComponent<IGraphQLErrorIndicatorProps> = props => {
    const {
        reloadResults,
        componentName,
    } = props;
    const [isLoading, setIsLoading] = useState(false);

    const onReloadClicked = async () => {
        setIsLoading(true);
        try {
            await reloadResults();
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    return (
        <Grid container direction='column' alignItems='center'>
            <Grid item style={{ margin: theme.spacing(1) }}>
                <Typography>
                    Sorry, unable to load {componentName ? componentName : 'this item'}.
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    onClick={onReloadClicked}
                    variant='contained'
                    color='primary'
                    disabled={isLoading}
                >
                    Reload
                </Button>
            </Grid>
        </Grid>
    )
}