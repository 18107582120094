import { gql } from '@apollo/client';

export const UPDATE_SURVEY = gql`
    mutation updateSurvey($survey: SurveyInput!, $surveyId: String!) {
            updateSurvey(input: $survey, id: $surveyId) {
            id
        }
    }
`

export interface UpdateSurveyDTO {
    updateSurvey: {
        id: string;
    }
}