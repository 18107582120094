import { gql } from "@apollo/client";

export const GET_ACCOUNT_TRIAL_EXPIRATION_DATE = gql`
    query accountTrialExpirationDate($accountId:String!){
        account(id:$accountId){
            trialExpirationDate
        }
    }`;

export interface GetAccountTrialExpirationDateDTO {
    account: {
        trialExpirationDate: Date;
    }
}