import { MutationFunctionOptions, FetchResult } from '@apollo/client';
import { UpdateSurveyDTO, CreateSurveyDTO, DeleteSurveyDTO } from 'src/operations';
import { IESStepperStep } from 'src/Components/Surveys';
import { SurveyDTO } from 'src/models';
import _ from 'lodash';
import { Dispatch, SetStateAction } from 'react';

export class AddNewSurveyStepperContainerLogic {
    public async updateSurveyInDB(updateSurvey: (options?: MutationFunctionOptions) => Promise<FetchResult<UpdateSurveyDTO>>, formattedSurvey: SurveyDTO, surveyId: string) {
        return await updateSurvey({
            variables: {
                survey: _.cloneDeep(formattedSurvey),
                surveyId: surveyId
            },
        });
    }

    public updateSurveyInState(setSurvey: Dispatch<SetStateAction<SurveyDTO>>, survey: SurveyDTO, surveyId: string) {
        setSurvey({
            ...survey,
            id: surveyId,
        });
    }

    public async createSurveyInDB(createSurvey: (options?: MutationFunctionOptions) => Promise<FetchResult<CreateSurveyDTO>>, formattedSurvey: SurveyDTO) {
        return await createSurvey({
            variables: {
                survey: formattedSurvey,
            },
        });
    }

    public validateFormBasicInfo(survey: SurveyDTO, steps: IESStepperStep[], setSteps: Dispatch<SetStateAction<IESStepperStep[]>>) {
        const updatedSteps = _.cloneDeep(steps);
        if (!!survey.name && updatedSteps[0].isValid === false) {
            updatedSteps[0].isValid = true;
            setSteps(updatedSteps);
        } else if ((!survey.name) && updatedSteps[0].isValid === true) {
            updatedSteps[0].isValid = false;
            setSteps(updatedSteps);
        }
    }

    public async deleteSurveyInDB(deleteSurvey: (options?: MutationFunctionOptions) => Promise<FetchResult<DeleteSurveyDTO>>, surveyId: string) {
        await deleteSurvey({
            variables: {
                surveyId: surveyId
            }
        });
    }
}