import { withStyles, Box, Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import theme from 'src/Theme';

interface ISliderDotsProps {
    sliderValue?: number;
}

export const SliderDots: FunctionComponent<ISliderDotsProps> = props => {
    const { sliderValue } = props;
    const BigDot = withStyles({
        root: {
            height: 10,
            width: 10,
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%'
        }
    })(Box);

    const LittleDot = withStyles({
        root: {
            height: 6,
            width: 6,
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%'
        }
    })(Box);

    const numDots = 17;
    const offset = Math.ceil(numDots / 2)
    const dots = [];
    for (let i = 0; i < numDots; i++) {
        if (i % 4 === 0) {
            dots.push(<BigDot key={i} style={sliderValue === undefined ? {backgroundColor: '#d0d0d0'} : { backgroundColor: sliderValue > (i - offset) ? theme.palette.primary.main : '#d0d0d0' }} />)
        } else {
            dots.push(<LittleDot key={i} style={sliderValue === undefined ? {backgroundColor: '#d0d0d0'} : { backgroundColor: sliderValue > (i - offset) ? theme.palette.primary.main : '#d0d0d0' }} />)
        }
    }

    const upperLimitMobileDeviceWidth = 1224
    const dotHeightComputer = -23
    const dotHeightMobile = -30
    return (
        <Grid
            container
            item
            direction="row"
            justify='space-between'
            style={{
                position: 'relative',
                top: (window.innerWidth <= upperLimitMobileDeviceWidth) ? dotHeightMobile : dotHeightComputer,
                left: '-5px',
                width: 'calc(100% + 10px)',
                zIndex: 2,
            }}
            alignItems='center'
        >
            {dots.map(dot => dot)}
        </Grid>
    )
}