import { gql } from '@apollo/client';

export const CREATE_SURVEY_DECISION_CRITERIA = gql`
    mutation createSurveyDecisionCriteria($surveyDecisionCriteria: SurveyDecisionCriteriaInput!) {
        createSurveyDecisionCriteria(input: $surveyDecisionCriteria) {
            id,
            createdOn
        }
    }
`

export interface CreateSurveyDecisionCriteriaDTO {
    createSurveyDecisionCriteria: {
        id: string;
        createdOn: Date;
    }
}