import { useQuery } from "@apollo/client";
import React, { FunctionComponent } from "react";
import { DecisionCriteriaStakeholderRanking, GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import { GetDecisionCriteriaStakeholderRankingDataDTO, GET_DECISION_CRITERIA_STAKEHOLDER_RANKING_DATA } from "src/operations";

interface IDecisionCriteriaStakeholderRankingContainerProps {
    surveyId: string;
    respondentId: string;
}

export const DecisionCriteriaStakeholderRankingContainer: FunctionComponent<IDecisionCriteriaStakeholderRankingContainerProps> = props => {
  const { surveyId, respondentId } = props;

  const {data, loading: isLoading, error, refetch} = useQuery<GetDecisionCriteriaStakeholderRankingDataDTO>(GET_DECISION_CRITERIA_STAKEHOLDER_RANKING_DATA, {
    variables: {
      surveyId: surveyId,
      respondentId: respondentId,
    },
    nextFetchPolicy: 'network-only'
  });

  const reloadResults = async () => {
    await refetch({
      surveyId: surveyId,
      respondentId: respondentId,
    })
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='stakeholder desired outcomes graph' />;
  }

  if (data && !data?.overall?.respondentRanking) {
    setTimeout(
      () => {
        refetch({
          surveyId: surveyId,
          respondentId: respondentId,
        })
      }, 1000);

    return <LoadingIndicator />;
  }

  return (
    <DecisionCriteriaStakeholderRanking
      chartData={data}
    />
  );
}
