import { IconButton, makeStyles } from '@material-ui/core';
import { Image } from '@material-ui/icons';
import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { useSlate } from 'slate-react';
import { insertImage } from './utils';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
}));

export const WYSIWYGAddImageButton: FunctionComponent = () => {
    const editor = useSlate();
    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState('');

    const handleInsertImage = (image: any) => {
        insertImage(editor, image);
    }

    const handleImageURLChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(event?.target?.value)
        const image = event?.target?.files[0];
        if (image) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                const url = reader.result;
                handleInsertImage(url);
            })

            reader.readAsDataURL(image);
        }
        setSelectedFile('');
    }

    return (
        <>
            <input
                    accept="image/*"
                    id="contained-button-file"
                    className={classes.input}
                    type="file"
                    onChange={handleImageURLChange}
                    value={selectedFile}
                />
            <label htmlFor="contained-button-file">
                <IconButton
                    size='small'
                    component="span"
                >
                    <Image />
                </IconButton>
            </label>

        </>
    )
}