import React, { FunctionComponent, useEffect, useState } from 'react';
import { CellParams, ColDef, DataGrid, PageChangeParams, RowsProp } from '@material-ui/data-grid';
import { Grid, withStyles } from '@material-ui/core';
import MenuButton from '../MenuButton';
import theme from 'src/Theme';

interface IESDataTableProps {
    columnHeaders: ColDef[];
    data: RowsProp;
    onEdit: (id: string) => void;
    isLoading?: boolean;
}

const StyledDataGrid = withStyles({
    "@global": {
        '.MuiDataGrid-row:nth-child(even)': {
            backgroundColor: '#eeeeee'
        },
        '.MuiDataGrid-columnSeparator': {
            display: 'none !important'
        },
        '.MuiDataGrid-footer': {
            flexDirection: 'row-reverse',
            backgroundColor: '#ffffff'
        },
        '.MuiDataGrid-cell:focus': {
            outline: 'none !important'
        },
        '.Mui-selected': {
            backgroundColor: '#dddddd !important'
        }
    }
  })(DataGrid);

export const ESDataTable: FunctionComponent<IESDataTableProps> = props => {
    const {
        columnHeaders,
        data,
        onEdit,
        isLoading,
    } = props;
    const [pageSize, setPageSize] = useState(25);

    const renderMenu = (params: CellParams) => {
        const handleEditClicked = () => {
            onEdit(params.row.id.toString());
        }

        return (
            <Grid >
                <MenuButton
                    menuOptions={
                        [
                            { name: 'Edit', action: handleEditClicked },
                        ]
                    }
                    dense={true}
                />
            </Grid>
        )
    }

    const tripleDotColumn = {
        field: '',
        renderCell: renderMenu,
        width: 48,
    }

    useEffect(() => {
        const isTripleDotColumnAdded = columnHeaders.some((columnHeader) => columnHeader.field === tripleDotColumn.field)

        if (!isTripleDotColumnAdded) {
            columnHeaders.push(tripleDotColumn)
        }
    }, [columnHeaders, tripleDotColumn])

    const handlePageSizeChange = (param: PageChangeParams) => {
        setPageSize(param.pageSize);
    }

    return (
        <>
            <StyledDataGrid
                rows={data}
                columns={columnHeaders}
                hideFooterRowCount
                hideFooterSelectedRowCount
                disableSelectionOnClick
                rowsPerPageOptions={[25, 50, 100]}
                pageSize={pageSize}
                autoHeight={true}
                onPageSizeChange={handlePageSizeChange}
                rowHeight={theme.spacing(5)}
                headerHeight={theme.spacing(5)}
                loading={isLoading}
            />

        </>
    )
}