import { gql } from '@apollo/client';

export const UPDATE_SURVEY_DECISION_CRITERIA = gql`
    mutation updateSurveyDecisionCriteria($surveyDecisionCriteria: SurveyDecisionCriteriaInput!, $surveyDecisionCriteriaId: String!) {
        updateSurveyDecisionCriteria(input: $surveyDecisionCriteria, id: $surveyDecisionCriteriaId) {
            id
        }
    }
`

export interface UpdateSurveyDecisionCriteriaDTO {
    updateSurveyDecisionCriteria: {
        id: string;
    }
}