import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { MoreVert } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

export interface IMenuItem {
  name: string;
  action?: () => void;
  isDisabled?: boolean;
}

export interface IMenuButtonProps {
  menuOptions: IMenuItem[];
  dense?: boolean;
}

export default function MenuButton(props: IMenuButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { menuOptions, dense } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size={dense ? 'small' : 'medium'}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        { menuOptions && menuOptions.map((menuOption, index) => {
          return <MenuItem
            key={index}
            component='button'
            onClick={menuOption.action}
            disabled={menuOption.isDisabled}
            style={{ width: '100%' }}
          >
            {menuOption.name}
          </MenuItem>
        })}
      </Menu>
    </div>
  );
}
