import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import _ from 'lodash';
import { DecisionCriteriaDTO } from 'src/models';
import { CreateSurveyDecisionCriteriaDTO, DeleteSurveyDTO, UpdateSurveyDecisionCriteriaDTO } from 'src/operations';

export class AddEditDecisionCriteriaContainerLogic {
    public async createSurveyDecisionCriteriaInDB(newDecisionCriteria: DecisionCriteriaDTO, createSurveyDecisionCriteria:(options?: MutationFunctionOptions) => Promise<FetchResult<CreateSurveyDecisionCriteriaDTO>>) {
        const result: FetchResult<CreateSurveyDecisionCriteriaDTO> = await createSurveyDecisionCriteria({
            variables: {
                surveyDecisionCriteria: newDecisionCriteria,
            }
        });
        const surveyDecisionCriteriaFields = result.data;
        return surveyDecisionCriteriaFields;
    }

    public async deleteSurveyDecisionCriteriaInDB(decisionCriteriaId: string, deleteSurveyDecisionCriteria: (options?: MutationFunctionOptions) => Promise<FetchResult<DeleteSurveyDTO>>) {
        await deleteSurveyDecisionCriteria({
            variables: {
                surveyDecisionCriteriaId: decisionCriteriaId
            }
        });
    }

    public async updateSurveyDecisionCriteriaInDB(editedDecisionCriteria: DecisionCriteriaDTO, updateSurveyDecisionCriteria: (options?: MutationFunctionOptions) => Promise<FetchResult<UpdateSurveyDecisionCriteriaDTO>>) {
        const formattedEditedDecisionCriteria = _.cloneDeep(editedDecisionCriteria);
        delete formattedEditedDecisionCriteria.id;
        delete formattedEditedDecisionCriteria.createdOn;
        delete formattedEditedDecisionCriteria.__typename;

        await updateSurveyDecisionCriteria({
            variables: {
                surveyDecisionCriteria: formattedEditedDecisionCriteria,
                surveyDecisionCriteriaId: editedDecisionCriteria.id
            }
        });
    }
}