import { gql } from '@apollo/client';

export const UPDATE_SURVEY_RESPONSE_PUBLIC = gql`
mutation updateResponseForSurvey($surveyResponse: SurveyResponseInput!, $surveyResponseId: String!) {
    updateResponseForSurvey(id:$surveyResponseId,input: $surveyResponse){
        id
    }
}
`

export interface UpdateSurveyResponseDTO {
    updateResponseForSurvey: {
        id: string;
    }
}