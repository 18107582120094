import { gql } from '@apollo/client';

export const SEND_FEEDBACK_EMAIL = gql`
    mutation sendFeedbackEmail($feedback:FeedbackInput!) {
        sendFeedback(input:$feedback)
    }
`

export interface SendFeedbackEmailDTO {
    sendFeedback: boolean;
}