import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import LoadingIndicator from 'src/Components/core/LoadingIndicator';
import { AddEditRespondentCustomerModal } from 'src/Components/Surveys';
import { RespondentDTO } from 'src/models';
import { CREATE_RESPONDENT, DELETE_RESPONDENT, GetRespondentDTO, GET_RESPONDENT, UPDATE_RESPONDENT } from 'src/operations';
import { GET_GLOBAL_DEFAULTS, GlobalDefaultsDTO } from 'src/operations/queries/getGlobalDefaults';

export interface IAddEditRespondentCustomerModalContainerProps {
    isOpen: boolean;
    respondentId?: string;
    closeModal: () => void;
    reloadRespondents: () => void;
    isEditDisabled?: boolean;
}

export function AddEditRespondentCustomerModalContainer(props: IAddEditRespondentCustomerModalContainerProps) {
    const {
        closeModal,
        isOpen,
        respondentId,
        reloadRespondents,
        isEditDisabled
    } = props;
    const [createRespondent] = useMutation(CREATE_RESPONDENT);
    const [updateRespondent] = useMutation(UPDATE_RESPONDENT);
    const [deleteRespondent] = useMutation(DELETE_RESPONDENT);
    const [getRespondent, { data: respondentData }] = useLazyQuery(GET_RESPONDENT, {fetchPolicy: 'network-only'});
    const {
        data: globalDefaultsQueryData,
        loading: isGlobalDefaultsQueryLoading,
    } = useQuery<GlobalDefaultsDTO>(GET_GLOBAL_DEFAULTS, {nextFetchPolicy: 'network-only'});
    const [isSMSEnabled, setIsSMSEnabled] = useState(false);
    const [selectedRespondent, setSelectedRespondent] = useState(null as RespondentDTO)

    useEffect(() => {
        if (globalDefaultsQueryData) {
            const isSMSEnabledGlobalDefault = globalDefaultsQueryData.globalDefaults.find(globDef => globDef.name === 'isSMSEnabled');
            if (isSMSEnabledGlobalDefault?.value === 1) {
                setIsSMSEnabled(true);
            } else {
                setIsSMSEnabled(false);
            }
        }
    }, [globalDefaultsQueryData]);

    useEffect(() => {
        if (isOpen && respondentId) {
            getRespondent({
                variables: {
                    respondentId: respondentId,
                },
            })
        }
    }, [isOpen, respondentId, getRespondent])

    useEffect(() => {
        const data: GetRespondentDTO = respondentData
        setSelectedRespondent(data?.respondent)
    }, [respondentData])

    const handleModalClose = () => {
        setSelectedRespondent(null)
        closeModal()
    }

    const handleCreateRespondent = async (newRespondentCustomer: RespondentDTO) => {
        await createRespondent({
            variables: {
                respondent: newRespondentCustomer,
            }
        });
        reloadRespondents();
    }

    const handleUpdateRespondent = async (editedRespondentCustomer: RespondentDTO, respondentCustomerId: string) => {
        await updateRespondent({
            variables: {
                respondent: editedRespondentCustomer,
                respondentId: respondentCustomerId
            }
        });
        reloadRespondents();
    }

    const handleDeleteRespondent = async (respondentCustomerId: string) => {
        await deleteRespondent({
            variables: {
                respondentId: respondentCustomerId,
            }
        });
        reloadRespondents();
    }

    if (isGlobalDefaultsQueryLoading) {
        return <LoadingIndicator />;
    }

    return (
        <AddEditRespondentCustomerModal
            closeModal={handleModalClose}
            createRespondentCustomer={handleCreateRespondent}
            updateRespondentCustomer={handleUpdateRespondent}
            deleteRespondentCustomer={handleDeleteRespondent}
            existingRespondentCustomer={selectedRespondent}
            isOpen={isOpen}
            isEditDisabled={isEditDisabled}
            isSMSEnabled={isSMSEnabled}
        />
    )
}