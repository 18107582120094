import { useQuery } from "@apollo/client";
import React, { FunctionComponent } from "react";
import { DecisionCriteriaCustomerRanking, GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import { GetDecisionCriteriaCustomerRankingDataDTO, GET_DECISION_CRITERIA_CUSTOMER_RANKING_DATA } from "src/operations";

interface IDecisionCriteriaCustomerRankingContainerProps {
    surveyId: string;
    respondentId: string;
}

export const DecisionCriteriaCustomerRankingContainer: FunctionComponent<IDecisionCriteriaCustomerRankingContainerProps> = props => {
  const { surveyId, respondentId } = props;

  const {data, loading: isLoading, error, refetch} = useQuery<GetDecisionCriteriaCustomerRankingDataDTO>(GET_DECISION_CRITERIA_CUSTOMER_RANKING_DATA, {
    variables: {
      surveyId: surveyId,
      respondentId: respondentId,
    },
    fetchPolicy: 'network-only',
  });

  const reloadResults = async () => {
    await refetch({
      surveyId: surveyId,
      respondentId: respondentId,
    })
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='desired outcomes graphs' />;
  }

  if (data && !data?.overall?.respondentRanking) {
    setTimeout(
      () => {
        refetch({
          surveyId: surveyId,
          respondentId: respondentId,
        })
      }, 1000);

    return <LoadingIndicator />;
  }

  return (
    <DecisionCriteriaCustomerRanking
      chartData={data}
    />
  );
}
