import { useQuery } from "@apollo/client";
import React, { FunctionComponent } from "react";
import { useParams } from 'react-router-dom';
import { GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import { ResponseGauge } from 'src/Components/Graphs/ResponseGauge';
import { GetResponseGaugeDataDTO, GET_RESPONSE_GAUGE_DATA } from "src/operations";

interface IResponseGaugeContainerProps {
    surveyId: string;
}

export const ResponseGaugeContainer: FunctionComponent<IResponseGaugeContainerProps> = () => {
  const { surveyId } = useParams<{ surveyId: string }>();

  const {data, loading: isLoading, error, refetch} = useQuery<GetResponseGaugeDataDTO>(GET_RESPONSE_GAUGE_DATA, {
    variables: {
      filter: `surveyId:${surveyId}`,
    },
  });

  const reloadResults = async () => {
    await refetch({
      filter: `surveyId:${surveyId}`,
    });
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || data === undefined) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='response percentage graph' />;
  }

  return (
    <ResponseGauge
      chartData={data}
    />
  );
}
