import {
  List
} from "@material-ui/core";
import * as React from "react";
import { SurveyRespondentListItemContainer } from 'src/containers';
import { RespondentDTO, SurveyRespondentDTO } from 'src/models';

export interface ISurveyRespondentsListProps {
  respondentList: Partial<RespondentDTO>[];
  surveyRespondents: Partial<SurveyRespondentDTO>[];
  addRespondent: (respondent: Partial<RespondentDTO>) => Promise<void>;
  removeRespondent: (respondentId: string) => Promise<void>;
  isLoading?: boolean;
  onEdit: (respondentId: string, respondentTypeName?: string) => void
  showCompletedList?: boolean;
  isEditDisabled?: boolean;
  isSendInvitationsDisabled?: boolean;
  isSendInvitationsVisible?: boolean;
}

export const SurveyRespondentsList: React.FunctionComponent<ISurveyRespondentsListProps> = props => {
  const {
    respondentList,
    surveyRespondents,
    addRespondent,
    removeRespondent,
    onEdit,
    showCompletedList,
    isEditDisabled,
    isSendInvitationsDisabled,
    isSendInvitationsVisible,
    isLoading,
  } = props;

  return (
    <List>
      {!!respondentList &&
        respondentList.map((respondent, index) => {
          return (
            <SurveyRespondentListItemContainer
              key={index}
              respondent={respondent}
              surveyRespondents={surveyRespondents}
              addRespondent={addRespondent}
              removeRespondent={removeRespondent}
              onEdit={onEdit}
              showClipboard={showCompletedList}
              isEditDisabled={isEditDisabled}
              isSendInvitationsDisabled={isSendInvitationsDisabled}
              isSendInvitationsVisible={isSendInvitationsVisible}
              isLoading={isLoading}
            />
          );
        })}
    </List>
  );
}


