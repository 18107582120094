import { gql } from '@apollo/client';

export const DELETE_ACCOUNT = gql`
mutation deleteAccount($accountId:String!){
    deleteAccount(id:$accountId) {
        id
    }
}
`

export interface DeleteAccountDTO {
    deleteAccount: {
        id: string;
    };
}