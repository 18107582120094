import { gql } from '@apollo/client';

export const DELETE_SURVEY_FEATURE = gql`
    mutation deleteSurveyFeature($surveyFeatureId: String!) {
        deleteSurveyFeature(id: $surveyFeatureId) {
            id
        }
    }
`

export interface DeleteSurveyFeatureDTO {
    deleteSurveyFeature: {
        id: string;
    }
}