import * as React from "react";
import { Grid, TextField, Box, InputAdornment } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { SurveyDTO } from "src/models/SurveyDTO";
import { ChangeEvent } from "react";
import { DateTime } from "luxon";
import { Event } from '@material-ui/icons';

interface IBasicInfoProps {
  survey: SurveyDTO;
  updateSurvey: (updatedSurvey: SurveyDTO) => void;
}

function BasicInfo(props: IBasicInfoProps) {
  const { survey, updateSurvey } = props;

  const handleNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const updatedSurvey = {
      ...survey,
      name: event.target.value,
    };
    updateSurvey(updatedSurvey);
  };

  const handleDescriptionChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const updatedSurvey = {
      ...survey,
      description: event.target.value,
    };
    updateSurvey(updatedSurvey);
  };

  const handleDateLockedChange = (newDate?: DateTime) => {
    const updatedSurvey = {
      ...survey,
      dateLocked: newDate ? newDate.toJSDate() : null,
    };
    updateSurvey(updatedSurvey);
  };

  const TextFieldComponent = (props: any) => {
    return <TextField {...props}  InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <Event />
        </InputAdornment>
      ),
    }}/>
  }

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <form id="surveyBasicInfoForm">
        <Grid item>
          <TextField
            style={{ width: "500px" }}
            value={survey.name}
            id="name"
            label="Name"
            required
            onChange={handleNameChange}
          />
          <Box paddingTop={2}>
            <TextField
              style={{ width: "500px" }}
              value={survey.description}
              id="description"
              label="Description"
              variant="outlined"
              multiline
              rows={5}
              onChange={handleDescriptionChange}
            />
          </Box>
          <Box paddingTop={4}>
            <DatePicker
              label="Results Lock On"
              clearable
              value={survey.dateLocked}
              onChange={handleDateLockedChange}
              minDate={new Date()}
              format="MM/dd/yyyy"
              TextFieldComponent={TextFieldComponent}
            />
          </Box>
        </Grid>
      </form>
    </MuiPickersUtilsProvider>
  );
}

export default BasicInfo;
