import { useAuth0 } from '@auth0/auth0-react';
import { Button, createStyles, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { UserDTO } from 'src/models';
import theme from 'src/Theme';
import { ConfirmationModal } from '../core';

interface IAccountFormProps {
    existingUser: UserDTO;
    onUpdateUser: (updatedUser: UserDTO) => Promise<void>;
    onChangePassword: () => void;
    onCancelAccount: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formField: {
            padding: theme.spacing(1)
        }
    }),
);

export const AccountForm: FunctionComponent<IAccountFormProps> = props => {
    const { existingUser, onUpdateUser, onChangePassword, onCancelAccount } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isSaveConfirmationModalOpen, setIsSaveConfirmationModalOpen] = useState(false);
    const classes = useStyles();
    const { loginWithPopup } = useAuth0()

    useEffect(() => {
        let isDirty = false;
        if (
            firstName !== existingUser?.userMetaData?.firstname
            || lastName !== existingUser?.userMetaData?.lastname
            || email !== existingUser?.email
            || phone !== existingUser?.userMetaData?.phone
        ) {
            isDirty = true;
        }
        if (isFormDirty !== isDirty) {
            setIsFormDirty(isDirty);
        }
    }, [existingUser, firstName, lastName, email, phone, setIsFormDirty, isFormDirty]);

    const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFirstName(event.target.value);
    }

    const handleLastNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLastName(event.target.value);
    }

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmail(event.target.value);
    }

    const handlePhoneChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPhone(event.target.value);
    }

    const handleSaveClicked = async () => {
        if (email !== existingUser.email) {
            openSaveConfirmationModal();
        } else {
            initiateUserUpdate();
        }
    }

    const openSaveConfirmationModal = () => {
        setIsSaveConfirmationModalOpen(true);
    }

    const closeSaveConfirmationModal = () => {
        setIsSaveConfirmationModalOpen(false);
    }

    const initiateUserUpdate = async (shouldSendToLoginPage?: boolean) => {
        setIsLoading(true);
        const updatedUser: UserDTO = {
            email: email,
            userMetaData: {
                firstname: firstName,
                lastname: lastName,
                phone: phone,
            }
        }
        try {
            await onUpdateUser(updatedUser);
            setIsFormDirty(false);
            if (shouldSendToLoginPage) {
                loginWithPopup();
            }
        } catch (error) {
            enqueueSnackbar('An error occurred while updating account information', { variant: 'error' });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setFirstName(existingUser?.userMetaData?.firstname || '')
        setLastName(existingUser?.userMetaData?.lastname || '')
        setPhone(existingUser?.userMetaData?.phone || '')
        setEmail(existingUser?.email || '')
    }, [existingUser]);

    return (
        <>
            <Grid container direction='column' alignItems='center'>
                <Grid container item direction='row' style={{ maxWidth: 800 }}>
                    <Grid item xs={6} className={classes.formField}>
                        <TextField
                            label='First Name'
                            fullWidth
                            required
                            value={firstName}
                            onChange={handleFirstNameChange}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.formField}>
                        <TextField
                            label='Last Name'
                            fullWidth
                            required
                            value={lastName}
                            onChange={handleLastNameChange}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.formField}>
                        <TextField
                            label='Email'
                            fullWidth
                            required
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.formField}>
                        <TextField
                            label='Phone'
                            fullWidth
                            required
                            value={phone}
                            onChange={handlePhoneChange}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.formField} style={{ marginTop: theme.spacing(2) }}>
                        <Button
                            color='primary'
                            style={{ marginLeft: -8 }}
                            onClick={onChangePassword}
                            disabled={isFormDirty || isLoading}
                        >
                            Change Password
                        </Button>
                        <Button
                            color='primary'
                            onClick={onCancelAccount}
                            disabled={isFormDirty || isLoading}
                        >
                            Cancel Account
                        </Button>
                    </Grid>
                    <Grid item xs={6} className={classes.formField} style={{ marginTop: theme.spacing(2), display: 'flex', flexDirection: 'row-reverse' }}>
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={!isFormDirty || isLoading}
                            onClick={handleSaveClicked}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <ConfirmationModal
                isSingleOption={true}
                onActionConfirmed={() => { initiateUserUpdate(true) }}
                isOpen={isSaveConfirmationModalOpen}
                onClose={closeSaveConfirmationModal}
                message={`Changing your email address requires you to log back into the application.`}
            />
        </>
    )
}