import { useQuery } from "@apollo/client";
import React, { FunctionComponent } from "react";
import { useParams } from 'react-router-dom';
import { GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import { OverallPrioritization } from 'src/Components/Graphs/OverallPrioritization';
import { GetOverallPrioritizationDataDTO, GET_OVERALL_PRIORITIZATION_DATA } from "src/operations";

interface IOverallPrioritizationContainerProps {
    surveyId: string;
}

export const OverallPrioritizationContainer: FunctionComponent<IOverallPrioritizationContainerProps> = () => {
  const { surveyId } = useParams<{ surveyId: string }>();

  const {data, loading: isLoading, error, refetch} = useQuery<GetOverallPrioritizationDataDTO>(GET_OVERALL_PRIORITIZATION_DATA, {
    variables: {
      surveyId: surveyId
    },
  });

  const reloadResults = async () => {
    await refetch({
      surveyId: surveyId,
    });
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || data === undefined) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='blended average graph' />;
  }

  return (
    <OverallPrioritization
      chartData={data}
    />
  );
}
