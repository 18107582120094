import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { DepartmentDecisionCriteriaDepartmentRankingDTO, DepartmentDecisionCriteriaStakeholderRankingDTO } from 'src/containers';
import theme from 'src/Theme';

interface IDepartmentDecisionCriteriaPrioritizationProps {
    decisionCriteriaName: string;
    stakeholderRanking: DepartmentDecisionCriteriaStakeholderRankingDTO[];
    departmentRankings: DepartmentDecisionCriteriaDepartmentRankingDTO[];
}

export const DepartmentDecisionCriteriaPrioritization: FunctionComponent<IDepartmentDecisionCriteriaPrioritizationProps> = props => {
    const {
        decisionCriteriaName,
        stakeholderRanking,
        departmentRankings,
    } = props;
    const [featureNames, setFeatureNames] = useState([] as string[]);
    const [chartLines, setChartLines] = useState([] as Highcharts.SeriesOptionsType[]);

    useEffect(() => {
        const featureList = stakeholderRanking?.map(x => x.featureName)
        setFeatureNames(featureList)

        const defaultLineProperties: Highcharts.SeriesOptionsType = {
            type: 'line',
        }
        const lines: Highcharts.SeriesOptionsType[] = []

        const stakeholderAverageLine = {
            ...defaultLineProperties,
            name: 'Average',
            data: stakeholderRanking?.map(x => x.rank),
        }
        lines.push(stakeholderAverageLine);

        departmentRankings.forEach(dr => {
            const departmentLine = {
                ...defaultLineProperties,
                name: dr.departmentName,
                data: dr.rankings?.map(x => x.rank),
            }
            lines.push(departmentLine);
        })

        setChartLines(lines)
    }, [stakeholderRanking, departmentRankings]);

    const options: Highcharts.Options = {
        title: {
            text: `${decisionCriteriaName} - Stakeholders`,
            align: 'left'
        },
        legend: {
            align: 'left',
            verticalAlign: 'top',
            margin: theme.spacing(5),
        },
        yAxis: {
            title: {
                text: null,
            }
        },
        xAxis: {
            categories: featureNames,
        },
        credits: {
            enabled: false,
        },
        series: chartLines,
        tooltip: {
            formatter: function () {
                return `${this.y.toFixed(2)}`
            }
        },
    }

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                {...props}
            />
        </>
    )
}