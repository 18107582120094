import { BaseDTO } from '.';
import { RespondentDTO } from './RespondentDTO';
import { SurveyDTO } from './SurveyDTO';

export enum SurveyRespondentStatusOptions {
    unopened = 'unopened',
    inProgress = 'inProgress',
    completed = 'completed',
    canceled = 'canceled',
}

export interface SurveyRespondentDTO extends BaseDTO {
    surveyId?: string;
    Survey?: Partial<SurveyDTO>;
    respondentId: string;
    respondent?: Partial<RespondentDTO>;
    status?: SurveyRespondentStatusOptions;
}