import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { GetOverallPrioritizationDataDTO } from 'src/operations';
import theme from 'src/Theme';

interface IOverallPrioritizationProps {
    chartData: GetOverallPrioritizationDataDTO;
}

export const OverallPrioritization: FunctionComponent<IOverallPrioritizationProps> = props => {
    const {
        chartData,
    } = props;
    const [featureNames, setFeatureNames] = useState([] as string[]);
    const [chartLines, setChartLines] = useState([] as Highcharts.SeriesOptionsType[]);

    useEffect(() => {
        const overallRankings = chartData.overall;
        const featureList = overallRankings.blendedRankings.map(x => x.featureName)
        setFeatureNames(featureList)

        const defaultLineProperties: Highcharts.SeriesOptionsType = {
            type: 'line',
        }
        const lines: Highcharts.SeriesOptionsType[] = []

        const blendedRankingLine = {
            ...defaultLineProperties,
            name: 'Blended Average',
            data: overallRankings.blendedRankings.map(x => x.rank),
        }
        lines.push(blendedRankingLine);

        overallRankings.decisionCriteriaRankings.forEach(dcr => {
            if (dcr.blendedRankings.length > 0) {
                const decisionCriteriaLine = {
                    ...defaultLineProperties,
                    name: dcr.decisionCriteria.name,
                    data: dcr.blendedRankings.map(x => x.rank),
                }
                lines.push(decisionCriteriaLine);
            }
        })

        setChartLines(lines)
    }, [chartData]);

    const options: Highcharts.Options = {
        title: {
            text: 'Results by Desired Outcome',
            align: 'left',
        },
        legend: {
            align: 'left',
            verticalAlign: 'top',
            margin: theme.spacing(5),
        },
        yAxis: {
            title: {
                text: null,
            }
        },
        xAxis: {
            categories: featureNames,
        },
        credits: {
            enabled: false,
        },
        series: chartLines,
        tooltip: {
            formatter: function () {
                return `${this.y.toFixed(2)}`
            }
        },
    }

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                {...props}
            />
        </>
    )
}