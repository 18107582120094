import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Grid, IconButton, Toolbar } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import React, { FunctionComponent, useState } from 'react';
import AuthorizedApolloProvider from 'src/AuthorizedApolloProvider';
import NavigationDrawerContainer from 'src/containers/NavigationDrawerContainer';
import theme from 'src/Theme';
import { HelpHeroAuthenticator } from '../HelpHero';
import LoadingIndicator from '../LoadingIndicator';
import img from './ProductLightSmall.png';
import { TrialAccountNotification } from './TrialAccountNotification';

export const BasePage: FunctionComponent = props => {
    let { user, isAuthenticated, loginWithRedirect } = useAuth0();
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen)
    }

    if (!isAuthenticated) {
        loginWithRedirect();
        return <LoadingIndicator />;
    }

    return (
        <AuthorizedApolloProvider>
            <TrialAccountNotification userId={user.sub} children={
                <>
                    <HelpHeroAuthenticator userId={user.sub} />
                    <NavigationDrawerContainer isOpen={isDrawerOpen} />
                    <Grid style={isDrawerOpen ? { marginLeft: '300px', transition: 'margin .2s', transitionTimingFunction: 'ease-out' } : { marginLeft: 0, transition: 'margin .2s', transitionTimingFunction: 'ease-in' }}>
                        <AppBar position="static" elevation={0}>
                            <Toolbar variant="dense">
                                <IconButton
                                    edge="start"
                                    onClick={toggleDrawer}
                                >
                                    <Menu />
                                </IconButton>
                                <img src={img} alt="Product Light" style={{ padding: theme.spacing(1) }} />
                            </Toolbar>
                        </AppBar>
                        <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
                            {props.children}
                        </div>
                    </Grid>
                </>
            }/>
        </AuthorizedApolloProvider>
    )
}