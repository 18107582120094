import {
  Avatar,
  Button,
  createStyles,
  Divider,
  Drawer,
  Grid,
  Typography,
  withStyles
} from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import * as React from "react";
import { withRouter } from "react-router-dom";
import theme from "src/Theme";
import GeneratedAvatar from '../GeneratedAvatar';
import { INavigationDrawerProps, NavigationDrawerRouteDTO } from "./types";

const styles = () => {
  return createStyles({
    drawerContentContainer: {
      width: "300px",
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
    userName: {
      fontSize: "16px",
      fontWeight: "bold",
      overflowWrap: "break-word",
    },
    rotatingItem: {
      transitionProperty: "transform",
    },
    navigationButton: {
      padding: theme.spacing(2),
      justifyContent: "flex-start",
      fontWeight: "bold",
      color: "#666666",
      "&:hover": {
        background: "#DFE5FD",
        color: "#355FF6",
      },
    },
    myAccountMenu: {
      position: "absolute",
      top: "32px",
      zIndex: 2,
      overflow: "hidden",
      transitionProperty: "all",
    },
    settingsMenuBackButton: {
      padding: theme.spacing(2),
      justifyContent: "flex-start",
      fontWeight: "bold",
      color: "#666666",
      "&:hover": {
        background: "#DFE5FD",
        color: "#355FF6",
      },
      marginBottom: 5,
      marginTop: 1,

    },
  });
};


const NavigationDrawer: React.FunctionComponent<INavigationDrawerProps> = props => {
  const {
    user,
    classes,
    handleLogout,
    isOpen,
    routes,
    settingsRoutes
  } = props;

  const renderAvatar = () => {
    if (user.picture) {
      return (
        <Avatar
          src={user.picture}
          style={{
            height: "56px",
            width: "56px",
          }}
        >
          <div></div>
        </Avatar>
      );
    } else {
      return (
        <GeneratedAvatar name={user.name} />
      );
    }
  }

  const renderNavigationButtons = (routesToRender: NavigationDrawerRouteDTO[]) => {
    const visibleRoutes = routesToRender.filter(r => !r.isHidden);
    return visibleRoutes.map((routeToRender, index) => {
      return createNavButton(routeToRender, index);
    });
  };

  const createNavButton = (route: NavigationDrawerRouteDTO, key: number) => {
    const NavButton = withRouter(({ history }) => (
      <Button
        onClick={() => { history.push(route.route); }}
        startIcon={route.icon}
        className={classes.navigationButton}
      >
        {route.name}
      </Button>
    ));

    return <NavButton key={key} />;
  }

  return (
    <>
      <Drawer variant="persistent" anchor="left" open={isOpen}>
        <Grid
          item
          className={classes.drawerContentContainer}
        >
          <Grid item>{renderAvatar()}</Grid>
          <Grid item style={{ paddingTop: theme.spacing(1) }}>
            <Typography className={classes.userName}>{user.name}</Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              style={{ position: "relative", left: '-4px' }}
            >
              <Button
                onClick={handleLogout}
                startIcon={<ExitToApp />}
              >
                Logoff
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider variant="fullWidth" />
        <Grid item className={classes.drawerContentContainer}>
          <Grid container direction="column" alignItems="stretch">
            {renderNavigationButtons(routes)}
            <Divider variant="fullWidth" />
            <Typography
              style={{
                fontSize: '14px',
                fontWeight: 500,
                letterSpacing: '0.75px',
                lineHeight: '16px',
                padding: theme.spacing(2),
                paddingBottom: '4px'
              }}
            >
              Settings
            </Typography>
            {renderNavigationButtons(settingsRoutes)}
          </Grid>
        </Grid>
      </Drawer>
    </>
  )
}

export default withStyles(styles)(NavigationDrawer);
