import React from "react";
import { useQuery } from "@apollo/client";
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import { GET_SURVEYS, GetSurveysDTO } from "src/operations/queries/getSurveys";
import SurveyCardList from "src/Components/Surveys/SurveyCardList";
import { GraphQLErrorIndicator } from 'src/Components';

export default function Main() {
  const { data, loading: isLoading, error, refetch } = useQuery<GetSurveysDTO>(GET_SURVEYS, {
    fetchPolicy: 'network-only',
  });

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <GraphQLErrorIndicator reloadResults={refetch} componentName='poll list' />;
  }

  return <SurveyCardList queryData={data} />;
}
