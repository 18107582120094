import { Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { UserDataTableContainer } from 'src/containers/UserDataTableContainer';
import theme from 'src/Theme';

export const Users: FunctionComponent = () => {
    return (
        <Grid style={{ height: '100vh', paddingTop: theme.spacing(3) }}>
           <UserDataTableContainer />
        </Grid>
    )
}