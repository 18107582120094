import { Grid, Slider, TextField, Typography } from '@material-ui/core';
import React, { ChangeEvent, useState, useEffect } from 'react';
import theme from 'src/Theme';
import { DeleteButtonWithConfirmation } from '.';

export interface ISliderWithLabelProps {
    label: string;
    value: number | number[];
    identifier: number;
    onChange: (identifier: number, value: number) => void;
    onDelete: (identifier: number) => void;
    isEditDisabled?: boolean;
}

export function SliderWithLabel(props: ISliderWithLabelProps) {
    const {
        label,
        value,
        identifier,
        onChange,
        onDelete,
        isEditDisabled
    } = props;
    const [sliderValue, setSliderValue] = useState(value);
    const [textFieldErrorMessage, setTextFieldErrorMessage] = useState('');

    const handleSliderChangeCommitted = (event: ChangeEvent<{}>, newValue: number | number[]) => {
        if (isEditDisabled) {
            return
        }
        onChange(identifier, newValue as number);
    }

    const handleSliderChange = (event: ChangeEvent<{}>, newValue: number | number[]) => {
        if (isEditDisabled) {
            return
        }
        setSliderValue(newValue)
    }

    const handleFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = Number(event.target.value);
        onChange(identifier, newValue);
    }

    const handleDeleteClicked = () => {
        onDelete(identifier);
    }

    useEffect(() => {
        setSliderValue(value);
        if ((value < 1 || value > 100) && !isEditDisabled) {
            setTextFieldErrorMessage('Out of range');
        } else {
            setTextFieldErrorMessage('');
        }
    }, [value, isEditDisabled])

    return (
        <Grid container direction="row" alignItems="center">
            {!isEditDisabled &&
                <Grid item style={{color: '#B91313'}} xs={1}>
                    <DeleteButtonWithConfirmation onDelete={handleDeleteClicked} itemName="desired outcome" />
                </Grid>
            }
            <Grid item xs={isEditDisabled ? 4 : 3}>
                <Typography>{label}</Typography>
            </Grid>
            <Grid item xs={7} style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(2) }}>
                <Slider
                    style={{marginTop: theme.spacing(4), marginBottom: theme.spacing(4)}}
                    valueLabelDisplay="on"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    onChangeCommitted={handleSliderChangeCommitted}
                    min={isEditDisabled ? null : 1}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    InputProps={{
                        readOnly: isEditDisabled,
                    }}
                    value={sliderValue}
                    type='number'
                    onChange={handleFieldChange}
                    error={!!textFieldErrorMessage}
                    helperText={textFieldErrorMessage}
                />
            </Grid>
        </Grid>
    )
}