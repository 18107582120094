import { Grid, Tooltip } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

interface IFormValidationTooltipProps {
    isVisible: boolean;
    shouldShowAlternateText?: boolean;
    alternateText?: string;
}

export const FormValidationTooltip: FunctionComponent<IFormValidationTooltipProps> = props => {
    const {
        children,
        isVisible,
        shouldShowAlternateText,
        alternateText
    } = props;

    return (
        <Tooltip
            title={shouldShowAlternateText ? alternateText : `There are more fields required before you can save this record.`}
            disableHoverListener={!isVisible}
            disableFocusListener={!isVisible}
            disableTouchListener={!isVisible}
            placement="top"
        >
            <Grid>
                {children}
            </Grid>
        </Tooltip>
    )

}