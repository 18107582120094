import { gql } from "@apollo/client";

export const GET_SURVEY_NAME = gql`
  query getSurveyName($surveyId: String!) {
    survey(id: $surveyId) {
      name
    }
  }
`;

export interface GetSurveyNameDTO {
  survey: {
    name: string;
  };
}
