import { gql } from "@apollo/client";
import { SurveyRespondentStatusOptions } from 'src/models';

export const GET_SURVEY = gql`
  query getSurvey($surveyId: String!, $respondentFilter:String) {
    survey(id: $surveyId) {
      id
      projectId
      name
      description
      dateLocked
      hasResponses
      dateSent
      isLocked
      projectName
      isValid
      respondents (filterBy:$respondentFilter) {
        id
        status
        respondent {
          id
          firstName
          lastName
          respondentType {
            description
          }
          department {
            name
          }
        }
      }
      decisionCriteria(orderBy:"name") {
        id
        name
        description
        weight
        surveyId
      }
      features(orderBy:"name") {
        id
        name
        description
        details
      }
    }
  }
`;

export interface GetSurveyDTO {
  survey: {
    id: string;
    accountId: string;
    customerId: string;
    name: string;
    description: string;
    dateLocked?: Date;
    hasResponses: boolean;
    dateSent?: Date;
    isLocked: boolean;
    projectName: string;
    isValid: boolean;
    respondents: {
      id: string;
      status: SurveyRespondentStatusOptions;
      respondent: {
        id: string;
        firstName: string;
        lastName: string;
        department: {
          name: string;
        };
        respondentType: {
          description: string;
        };
      };
    }[];
    decisionCriteria: {
      id: string;
      name: string;
      description: string;
      weight: number;
      surveyId: string;
    }[];
    features: {
      id: string;
      name: string;
      description?: string;
      details?: string;
    }[];
  };
}
