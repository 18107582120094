import { useQuery } from "@apollo/client";
import { Grid, Card, CardContent } from '@material-ui/core';
import React, { FunctionComponent } from "react";
import { useParams } from 'react-router-dom';
import { GraphQLErrorIndicator } from 'src/Components';
import LoadingIndicator from "src/Components/core/LoadingIndicator";
import { CustomerPrioritization } from 'src/Components/Graphs/CustomerPrioritization';
import { GetCustomerPrioritizationDataDTO, GET_CUSTOMER_PRIORITIZATION_DATA } from "src/operations";
import theme from 'src/Theme';

interface ICustomerPrioritizationContainerProps {
  surveyId: string;
}

export const CustomerPrioritizationContainer: FunctionComponent<ICustomerPrioritizationContainerProps> = () => {
  const { surveyId } = useParams<{ surveyId: string }>();

  const {data, loading: isLoading, error, refetch} = useQuery<GetCustomerPrioritizationDataDTO>(GET_CUSTOMER_PRIORITIZATION_DATA, {
    variables: {
      surveyId: surveyId
    },
  });

  const reloadResults = async () => {
    await refetch({
      surveyId: surveyId,
    })
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || data === undefined) {
    return <GraphQLErrorIndicator reloadResults={reloadResults} componentName='customer graph' />;
  }

  if (data.survey?.customers?.every(customer => customer.surveyRankings?.respondentRankings.length === 0)) {
    return null
  }

  return (
    <Grid item xs={12} style={{ padding: theme.spacing(1) }}>
      <Card style={{ height: '100%' }}>
        <CardContent>
          <CustomerPrioritization
            chartData={data}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
