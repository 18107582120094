import { gql } from "@apollo/client";

export const GET_DEPARTMENT_DECISION_CRITERIA_PRIORITIZATION_DATA = gql`
query getSurveyDepartmentDecisionCriteriaPrioritization($surveyId:String!){
  survey(id:$surveyId) {
    stakeholders:groups(filterBy:"name:Stakeholder"){
      surveyRankings {
        decisionCriteriaRankings{
          decisionCriteria{
            id
            name
          }
          blendedRankings{
            rank
            featureName
          }
        }
      }
    }
    departments:groups(filterBy:"isDepartment:true"){
      name
      surveyRankings {
        decisionCriteriaRankings{
          decisionCriteria{
            id
            name
          }
          blendedRankings{
            rank
            featureName
          }
        }
      }
    }
  }
}
`;

export interface GetDepartmentDecisionCriteriaPrioritizationDataDTO {
  survey: {
    stakeholders: [{
      surveyRankings: {
        decisionCriteriaRankings: [{
          decisionCriteria: {
            id: string;
            name: string;
          }
          blendedRankings: [{
            featureName: string;
            rank: number;
          }]
        }]
      }
    }]
    departments: [{
      name: string;
      surveyRankings: {
        decisionCriteriaRankings: [{
          decisionCriteria: {
            id: string;
            name: string;
          }
          blendedRankings: [{
            featureName: string;
            rank: number;
          }]
        }]
      }
    }]
  }
}
