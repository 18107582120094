import * as React from "react";
import { FunctionComponent } from 'react';
import { BasePage } from "src/Components/core/BasePage";
import SurveyDetailsContainer from "../containers/SurveyDetailsContainer/SurveyDetailsContainer";

export const SurveyDetailsView: FunctionComponent = () => {
  return (
    <BasePage>
      <SurveyDetailsContainer />
    </BasePage>
  );
}