import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import React from 'react';

const PublicApolloProvider = (props: any) => {

    const httpLink = createHttpLink({
        uri: process.env.REACT_APP_BASE_URL,
    });

    const apolloClient = new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache(),
    });

    return (
        <ApolloProvider client={apolloClient}>
            {props.children}
        </ApolloProvider>
    );
};

export default PublicApolloProvider;