import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import 'fontsource-roboto';
import React from 'react';
import Shell from './Components/core/Shell';
import theme from './Theme';
import { SnackbarProvider } from 'notistack';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingIndicator from './Components/core/LoadingIndicator';

function App() {
    let { isLoading, error } = useAuth0();

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <p>{error.message}</p>;
    }

    return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline>
                    <SnackbarProvider maxSnack={3}>
                        <Shell />
                    </SnackbarProvider>
                </CssBaseline>
            </MuiThemeProvider>
    );
}

export default App;
