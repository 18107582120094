import { gql } from '@apollo/client';

export const SEND_SURVEY_RESPONDENT_INVITATION = gql`
    mutation sendSurveyRespondentInvitation($surveyRespondentId:String!) {
        notifyRespondent(id:$surveyRespondentId)
    }
`

export interface SendSurveyRespondentInvitationDTO {
    notifyRespondent: boolean;
}