import { gql } from "@apollo/client";
import { SurveyRespondentStatusOptions } from 'src/models';

export const GET_SURVEY_RESPONDENTS = gql`
    query surveyRespondents($surveyId:String!, $filter:String){
        survey(id:$surveyId){
            surveyRespondentCount
            respondents (filterBy:$filter, pageSize:500) {
                id
                status
                respondent {
                    id
                    firstName
                    lastName
                    respondentType {
                        description
                    }
                    department {
                        name
                    }
                }
            }
        }
    }`

export interface GetSurveyRespondentsDTO {
    survey: {
        surveyRespondentCount?: number;
        respondents: {
            id: string;
            status: SurveyRespondentStatusOptions;
            respondent: {
                id: string;
                firstName: string;
                lastName: string;
                department: {
                    name: string;
                }
                respondentType: {
                    description: string
                }
            }
        }[]
    }
}