import { gql } from '@apollo/client';

export const CREATE_SURVEY_FEATURE = gql`
    mutation createSurveyFeature($surveyFeature: SurveyFeatureInput!) {
        createSurveyFeature(input: $surveyFeature) {
            id
        }
    }
`

export interface CreateSurveyFeatureDTO {
    createSurveyFeature: {
        id: string;
    }
}