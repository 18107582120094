import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import theme from 'src/Theme';
import { BasicCard, IBasicCardProps } from './BasicCard';

interface IBasicCardListProps {
    cardDetails: IBasicCardProps[];
    title: string;
}

export const BasicCardList: FunctionComponent<IBasicCardListProps> = props => {
    const { cardDetails, title } = props;

    return (
        <Grid container direction="column" style={{ marginTop: theme.spacing(2) }}>
            <Grid item>
                <Typography variant="h5">
                    { title }
                </Typography>
            </Grid>
            { cardDetails && cardDetails.map((cardInfo, index) => {
                return <BasicCard key={index} {...cardInfo} />
            })}
        </Grid>
    )
}