import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { FunctionComponent, useState } from 'react';
import theme from 'src/Theme';
import { ConfirmationModal } from './ConfirmationModal';

interface IDeleteButtonWithConfirmation {
    onDelete: () => void;
    itemName: string;
    isDisabled?: boolean;
}

export const DeleteButtonWithConfirmation: FunctionComponent<IDeleteButtonWithConfirmation> = props => {
    const { onDelete, itemName, isDisabled } = props;
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const openConfirmModal = () => {
        setIsConfirmModalOpen(true);
    }

    const closeConfirmModal = () => {
        setIsConfirmModalOpen(false);
    }

    return (
        <>
            <IconButton
                onClick={openConfirmModal}
                style={{ marginRight: 'auto', marginLeft: theme.spacing(1) }}
                disabled={isDisabled}
            >
                <Delete
                    htmlColor="#656565"
                />
            </IconButton>
            <ConfirmationModal
                onActionConfirmed={onDelete}
                isOpen={isConfirmModalOpen}
                onClose={closeConfirmModal}
                message={`Are you sure you want to delete this ${itemName}?`}
            />
        </>
    )
}