import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { RespondentsCard } from 'src/Components/Surveys';
import { GetSurveyRespondentsDTO, GET_SURVEY_RESPONDENTS } from 'src/operations/queries';
import _ from 'lodash';
import { SendSurveyRespondentInvitationMultipleDTO, SEND_SURVEY_RESPONDENT_INVITATION_MULTIPLE } from 'src/operations';
import { RespondentDTO, SurveyRespondentDTO } from 'src/models';
import { useSnackbar } from 'notistack';
import { GraphQLErrorIndicator } from 'src/Components';

export interface ICompletedRespondentCardContainerProps {
    surveyId: string;
    isRespondentQuantityVisible?: boolean;
    isIconVisible?: boolean;
    onDataChange?: () => void;
    isAddRespondentButtonVisible?: boolean;
    isSendInvitationsVisible?: boolean;
    isSendInvitationsDisabled?: boolean;
    isEditDisabled?: boolean;
    isEditRespondentButtonVisible?: boolean;
    onRemindAllClicked?: () => Promise<void>;
    onEditRespondentModalClose?: () => void;
}

export function CompletedRespondentsCardContainer(props: ICompletedRespondentCardContainerProps) {
    const {
        surveyId,
        isRespondentQuantityVisible,
        isIconVisible,
        onDataChange,
        isAddRespondentButtonVisible,
        isSendInvitationsVisible,
        isEditDisabled,
        onRemindAllClicked,
        isSendInvitationsDisabled,
        onEditRespondentModalClose
    } = props;
    const [searchFilter, setSearchFilter] = useState('');
    const [surveyRespondentList, setSurveyRespondentList] = useState([] as Partial<SurveyRespondentDTO>[]);
    const [respondentList, setRespondentList] = useState([] as Partial<RespondentDTO>[]);
    const [numRespondents, setNumRespondents] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [sendSurveyRespondentInvitationMultiple] = useMutation<SendSurveyRespondentInvitationMultipleDTO>(SEND_SURVEY_RESPONDENT_INVITATION_MULTIPLE);
    const {data, error, refetch} = useQuery<GetSurveyRespondentsDTO>(GET_SURVEY_RESPONDENTS, {
        variables: {
            surveyId: surveyId,
            filter: `$respondent.firstName$:*${searchFilter}* OR $respondent.lastName$:*${searchFilter}*`
        }
    });

    const reloadData = async () => {
        await refetch({
            surveyId: surveyId,
            filter: `$respondent.firstName$:*${searchFilter}* OR $respondent.lastName$:*${searchFilter}*`
        })
        if (onDataChange) {
            onDataChange()
        }
    }

    const sendInvitationsToNewRespondents = async (surveyRespondentIds: string[]) => {
        if (isSendInvitationsDisabled) {
            enqueueSnackbar('Email notifications were not sent to new respondents because the survey is either locked or invalid.', { variant: 'error' });
            return;
        }
        await sendSurveyRespondentInvitationMultiple({
            variables: {
                surveyRespondentIds: surveyRespondentIds
            }
        });
        enqueueSnackbar('All poll invitations sent to newly added respondents', { variant: 'success' });
    }

    useEffect(() => {
        const respondents = data?.survey?.respondents;
        if (respondents) {
           setSurveyRespondentList(respondents)
           const sortedRespondents = _.cloneDeep(respondents).sort((r1, r2) => {
                const leftHandSide = r1.respondent.firstName.toLowerCase();
                const rightHandSide = r2.respondent.firstName.toLowerCase();
                const sorted = leftHandSide.localeCompare(rightHandSide);
                return sorted;
            }).map((x) => x.respondent);
           setRespondentList(sortedRespondents);
        }
        const respondentCount = data?.survey?.surveyRespondentCount;
        if (respondentCount) {
            setNumRespondents(respondentCount)
        }
    }, [data, setSurveyRespondentList])

    if (error) {
        return <GraphQLErrorIndicator reloadResults={reloadData} componentName='completed respondents card' />;
    }

    return <RespondentsCard
        surveyId={surveyId}
        respondentList={respondentList}
        surveyRespondents={surveyRespondentList}
        setSearchFilter={setSearchFilter}
        searchFilter={searchFilter}
        isRespondentQuantityVisible={isRespondentQuantityVisible}
        surveyRespondentCount={numRespondents}
        isIconVisible={isIconVisible}
        reloadRespondents={reloadData}
        isEditRespondentButtonVisible={isAddRespondentButtonVisible}
        showCompletedRespondents={true}
        isEditDisabled={isEditDisabled}
        isSendInvitationsVisible={isSendInvitationsVisible}
        sendInvitationsToNewRespondents={sendInvitationsToNewRespondents}
        isSendInvitationsDisabled={isSendInvitationsDisabled}
        onRemindAllClicked={onRemindAllClicked}
        onEditRespondentModalClose={onEditRespondentModalClose}
    />;
}

