import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import LoadingIndicator from 'src/Components/core/LoadingIndicator';
import { AddEditRespondentStakeholderModal } from 'src/Components/Surveys';
import { DepartmentDTO, RespondentDTO } from 'src/models';
import { CREATE_RESPONDENT, DELETE_RESPONDENT, GetAllDepartmentsDTO, GetRespondentDTO, GET_ALL_DEPARTMENTS, GET_RESPONDENT, UPDATE_RESPONDENT } from 'src/operations';
import { GET_GLOBAL_DEFAULTS, GlobalDefaultsDTO } from 'src/operations/queries/getGlobalDefaults';

export interface IAddEditRespondentStakeholderModalContainerProps {
    isOpen: boolean;
    respondentId?: string;
    closeModal: () => void;
    reloadRespondents: () => void;
    isEditDisabled?: boolean;
}

export function AddEditRespondentStakeholderModalContainer(props: IAddEditRespondentStakeholderModalContainerProps) {
    const {
        closeModal,
        isOpen,
        respondentId,
        reloadRespondents,
        isEditDisabled
    } = props;
    const [createRespondent] = useMutation(CREATE_RESPONDENT);
    const [updateRespondent] = useMutation(UPDATE_RESPONDENT);
    const [deleteRespondent] = useMutation(DELETE_RESPONDENT);
    const [getRespondent, { data: respondentData }] = useLazyQuery(GET_RESPONDENT, {fetchPolicy: 'network-only'});
    const [departments, setDepartments] = useState([] as Partial<DepartmentDTO>[]);
    useQuery(GET_ALL_DEPARTMENTS, {
        onCompleted: (departmentData: GetAllDepartmentsDTO) => {setDepartments(departmentData.departments)}
    });
    const {
        data: globalDefaultsQueryData,
        loading: isGlobalDefaultsQueryLoading,
    } = useQuery<GlobalDefaultsDTO>(GET_GLOBAL_DEFAULTS, {nextFetchPolicy: 'network-only'});
    const [isSMSEnabled, setIsSMSEnabled] = useState(false);
    const [selectedRespondent, setSelectedRespondent] = useState(null as RespondentDTO)

    useEffect(() => {
        if (globalDefaultsQueryData) {
            const isSMSEnabledGlobalDefault = globalDefaultsQueryData.globalDefaults.find(globDef => globDef.name === 'isSMSEnabled');
            if (isSMSEnabledGlobalDefault?.value === 1) {
                setIsSMSEnabled(true);
            } else {
                setIsSMSEnabled(false);
            }
        }
    }, [globalDefaultsQueryData]);

    useEffect(() => {
        if (isOpen && respondentId) {
            getRespondent({
                variables: {
                    respondentId: respondentId,
                },
            })
        }
    }, [isOpen, respondentId, getRespondent])

    useEffect(() => {
        const data: GetRespondentDTO = respondentData
        setSelectedRespondent(data?.respondent)
    }, [respondentData])

    const handleModalClose = () => {
        setSelectedRespondent(null)
        closeModal()
    }

    const handleCreateRespondent = async (newRespondentStakeholder: RespondentDTO) => {
        await createRespondent({
            variables: {
                respondent: newRespondentStakeholder,
            }
        });
        reloadRespondents();
    }

    const handleUpdateRespondent = async (editedRespondentStakeholder: RespondentDTO, respondentStakeholderId: string) => {
        await updateRespondent({
            variables: {
                respondent: editedRespondentStakeholder,
                respondentId: respondentStakeholderId
            }
        });
        reloadRespondents();
    }

    const handleDeleteRespondent = async (respondentStakeholderId: string) => {
        await deleteRespondent({
            variables: {
                respondentId: respondentStakeholderId,
            }
        });
        reloadRespondents();
    }

    if (isGlobalDefaultsQueryLoading) {
        return <LoadingIndicator />;
    }

    return (
        <AddEditRespondentStakeholderModal
            closeModal={handleModalClose}
            createRespondentStakeholder={handleCreateRespondent}
            updateRespondentStakeholder={handleUpdateRespondent}
            deleteRespondentStakeholder={handleDeleteRespondent}
            existingRespondentStakeholder={selectedRespondent}
            isOpen={isOpen}
            departments={departments}
            isEditDisabled={isEditDisabled}
            isSMSEnabled={isSMSEnabled}
        />
    )
}