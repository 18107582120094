import { Grid, Button } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import theme from 'src/Theme';

interface ITakeSurveyActionsProps {
    onNextClick: () => void;
    onCancelClick: () => void;
    isFinalStep: boolean;
}

export const TakeSurveyActions: FunctionComponent<ITakeSurveyActionsProps> = props => {
    const {
        onNextClick,
        onCancelClick,
        isFinalStep,
    } = props;

    return (
        <Grid container item direction="column">
            <Grid container item direction="row" justify='center'>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={onNextClick}
                    fullWidth
                    style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) }}
                >
                    { isFinalStep ? 'Finish' : 'Next' }
                </Button>
            </Grid>
            <Grid container item direction="row" justify='center' style={{ color: 'grey' }}>
                <Button
                    color='inherit'
                    onClick={onCancelClick}
                    style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) }}
                >
                    Cancel My Poll
                </Button>
            </Grid>
        </Grid>
    )
}