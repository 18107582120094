import * as React from 'react';
import { Route, Router } from 'react-router-dom';
import { IRoute, IRouterProps } from '../types';

const renderRoute = (route: IRoute, index: number) => {
    return (
        <Route key={index} path={route.path} component={route.component} exact={route.exact} />
    );
};

const RouterWrapper: React.SFC<IRouterProps> = (props) => (
    <Router history={props.history}>
        <>
            {props.children}
            <props.container {...props.containerProps}>
                {props.routes.map(renderRoute)}
            </props.container>
        </>
    </Router>
);

export { RouterWrapper };
export default RouterWrapper;
