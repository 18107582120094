import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { GetDepartmentPrioritizationDataDTO } from 'src/operations';
import theme from 'src/Theme';

interface IDepartmentPrioritizationProps {
    chartData: GetDepartmentPrioritizationDataDTO;
}

export const DepartmentPrioritization: FunctionComponent<IDepartmentPrioritizationProps> = props => {
    const {
        chartData,
    } = props;
    const [featureNames, setFeatureNames] = useState([] as string[]);
    const [chartLines, setChartLines] = useState([] as Highcharts.SeriesOptionsType[]);

    useEffect(() => {
        const allDepartments = chartData.survey.stakeholders && chartData.survey.stakeholders.length > 0 ? chartData.survey.stakeholders[0].surveyRankings : null;
        const individualDepartmentRankings = chartData.survey.departments && chartData.survey.departments.length > 0 ? chartData.survey.departments : null;
        if (!allDepartments) {
            return;
        }

        const featureList = allDepartments.blendedRankings.map(x => x.featureName)
        setFeatureNames(featureList)

        const defaultLineProperties: Highcharts.SeriesOptionsType = {
            type: 'line',
        }
        const lines: Highcharts.SeriesOptionsType[] = []

        const blendedRankingLine = {
            ...defaultLineProperties,
            name: 'Average',
            data: allDepartments.blendedRankings.map(x => x.rank),
        }
        lines.push(blendedRankingLine);

        individualDepartmentRankings.forEach(dr => {
            const departmentLine = {
                ...defaultLineProperties,
                name: dr.name,
                data: dr.surveyRankings.blendedRankings.map(x => x.rank),
            }
            lines.push(departmentLine);
        })

        setChartLines(lines)
    }, [chartData]);

    const options: Highcharts.Options = {
        title: {
            text: 'Overall Average - Stakeholders',
            align: 'left'
        },
        legend: {
            align: 'left',
            verticalAlign: 'top',
            margin: theme.spacing(5),
        },
        yAxis: {
            title: {
                text: null,
            }
        },
        xAxis: {
            categories: featureNames,
        },
        credits: {
            enabled: false,
        },
        series: chartLines,
        tooltip: {
            formatter: function () {
                return `${this.y.toFixed(2)}`
            }
        },
    }

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                {...props}
            />
        </>
    )
}